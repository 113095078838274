import FacetSorting from './facetSorting'
import sortOptions from '@/store/config/sortOptions'

export const defaultResultsPerPage = 10

export const defaultFacetsVisible = 5
export const defaultFacetSortType = FacetSorting.FACET_SORT_TYPE_HITS
export const defaultFacetSortOrder = FacetSorting.FACET_SORT_ORDER_DESCENDING
export const defaultFacetGroupOrderSettings = () => ({
  yearofpublication: {
    sortType: FacetSorting.FACET_SORT_TYPE_VALUES
  }
})

export const defaultSortField = sortOptions.sortFieldOptions[0].value
export const defaultSortOrder = sortOptions.sortOrderOptions[0].value

export const defaultSearchContext = 0

export const localStorageLanguageKey = 'hl-language'

export const defaultClientLanguage = 'nl'
