<template>
  <div class="toc-list-container" ref="toc-list">
    <xposi-toclistitem
      v-for="tocItem in toc"
      :title="tocItem.title"
      :file="tocItem.link"
      :children="tocItem.children"
      :flatindex="tocItem.index.flat"
      :key="tocItem.title + tocItem.link"
      ref="toc-item-top"
      ></xposi-toclistitem>
  </div>
</template>

<script>
import TocListItem from '@/components/reader/TocListItem.vue'
import { Events } from '@/events'

export default {
  name: 'toc-list',
  computed: {
    firstHighlightIndex () {
      return this.$store.getters['reader/getTOCHighlights'][0]
    },
    toc () {
      return this.$store.getters['reader/getTocTree']
    }
  },
  methods: {
    scrollCurrentIndexIntoView () {
      if (this.firstHighlightIndex === -1) return

      const rootEl = this.$refs['toc-list']
      const itemEl = rootEl.querySelector(`[data-index="${this.firstHighlightIndex}"]`)

      if (!itemEl) return

      const scrollMargin = 10

      const rootElTop = rootEl.getBoundingClientRect().top
      const itemTop = itemEl.getBoundingClientRect().top - rootElTop + rootEl.scrollTop

      rootEl.scrollTop = itemTop - scrollMargin
    },
    handleReaderNavigated () {
      setTimeout(this.scrollCurrentIndexIntoView, 100)
    }
  },
  mounted () {
    Events.$on('readerNavigated', this.handleReaderNavigated)
  },
  beforeUnmount () {
    Events.$off('readerNavigated', this.handleReaderNavigated)
  },
  components: {
    'xposi-toclistitem': TocListItem
  }
}
</script>
