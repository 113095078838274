import FACET_SORTING from '@/constants/facetSorting'

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

const sort = (list, sorting) => {
  const field = sorting.sortType === FACET_SORTING.FACET_SORT_TYPE_VALUES ? 'key' : 'value'

  if (sorting.sortOrder === FACET_SORTING.FACET_SORT_ORDER_ASCENDING) {
    sortAscending(list, field)
  } else {
    sortDescending(list, field)
  }
}

const sortAscending = (list, field) => {
  list.sort((a, b) => {
    const aval = a[field]
    const bval = b[field]

    const rank = collator.compare(aval, bval)

    return rank === 0 ? 0 : rank > 0 ? 1 : -1
  })
}

const sortDescending = (list, field) => {
  sortAscending(list, field)
  list.reverse()
}

export default (facets, activeFacets, sortSettings) => {
  const localFacetsCopy = [...facets]
  localFacetsCopy.push(...activeFacets
    .filter(activeFacet => !facets.find(f => f.key === activeFacet))
    .map(activeFacet => ({ key: activeFacet, value: 0 }))
  )

  sort(localFacetsCopy, sortSettings)

  return localFacetsCopy
}
