import Roles from '@/constants/roles'

// Roles from lowest to highest
const roleLevels = [Roles.NONE, Roles.ANONYMOUS, Roles.USER, Roles.ADMIN, Roles.SUPERADMIN]

// Check if a specific role includes the rights of a different role (role to check is equal or higher then target role)
const roleIncludes = (roleToCheck, targetRole) => {
  const checkRoleIndex = roleLevels.indexOf(roleToCheck)
  const targetRoleIndex = roleLevels.indexOf(targetRole)
  if (checkRoleIndex < 0 || targetRoleIndex < 0) throw Error(`${checkRoleIndex} or ${targetRole} not in level list`)
  return checkRoleIndex >= targetRoleIndex
}

export {
  roleIncludes
}
