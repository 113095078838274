import axiosAPI from '@/axiosAPI'

function flattenTOC (tocdata = []) {
  if (!tocdata) tocdata = []

  const toc = {
    tree: tocdata,
    flat: [],
    top: []
  }

  for (let i = 0; i < toc.tree.length; i++) {
    flattenTOCNode(toc.tree[i], toc)
  }

  return toc
}

function flattenTOCNode (node = {}, toc, topparentindex = null, flatparentindex = null) {
  node.parents = {
    top: topparentindex,
    flat: flatparentindex
  }

  node.index = {
    top: null,
    flat: toc.flat.length
  }

  if (!node.parents.top) {
    node.index.top = toc.top.length
    const topNode = { ...node }
    delete topNode.children
    toc.top.push(topNode)
  }

  toc.flat.push(node)

  if (!node.children || !node.children.length) return

  topparentindex = toc.top.length - 1
  flatparentindex = toc.flat.length - 1

  for (let i = 0; i < node.children.length; i++) {
    flattenTOCNode(node.children[i], toc, topparentindex, flatparentindex)
  }
}

export default () => ({
  namespaced: true,
  state: {
    docKey: '',
    toc: {
      tree: [],
      toplevel: [],
      flat: []
    },
    metadata: {},
    currentPage: 1,
    currentTOCIndex: -1,
    highlightTOCIndex: [],
    title: '',
    license: null,
    resultFiles: [],
    searchMode: window.localStorage.getItem('readerSearchMode') || 'words'
  },
  mutations: {
    storeToc (state, toc = []) {
      state.toc = flattenTOC(toc)
    },
    storeDocKey (state, docKey = '') {
      state.docKey = docKey
    },
    storeCurrentPage (state, page = state.currentPage) {
      state.currentPage = page
    },
    storeMetadata (state, metadata) {
      state.metadata = metadata
    },
    storeLicense (state, license) {
      state.license = license
    },
    storeResultFiles (state, resultFiles = []) {
      state.resultFiles = resultFiles
    },
    storeCurrentTOCIndex (state, index) {
      if (Array.isArray(index)) {
        state.currentTOCIndex = index[index.length - 1]
        state.highlightTOCIndex = index
      } else {
        state.currentTOCIndex = index
        state.highlightTOCIndex = [index]
      }
    },
    storeSearchMode (state, searchMode) {
      window.localStorage.setItem('readerSearchMode', searchMode)
      state.searchMode = searchMode
    }
  },
  getters: {
    getToc (state) {
      return state.toc
    },
    getTocTree (state) {
      return state.toc.tree
    },
    getDocKey (state) {
      return state.docKey
    },
    getPubKey (state) {
      if (!state.metadata) return null
      return state.metadata.pubKey
    },
    getAppearanceTitle (state) {
      if (!state.metadata) return null
      return state.metadata.title
    },
    getCurrentPage (state) {
      return state.currentPage
    },
    getAppearance (state) {
      if (!state.metadata.appearance) return null

      return state.metadata.appearance
    },
    getMimetype (state) {
      if (!state.metadata.appearance || !state.metadata.appearance.mimeType) return null

      let mimetype = state.metadata.appearance.mimeType
      mimetype = mimetype.replace('application/', '')
      return mimetype
    },
    getMetadata (state) {
      if (!state.metadata) return {}

      return state.metadata
    },
    getLicense (state) {
      return state.license
    },
    getLicenseKey (state) {
      return state.license ? state.license.licenseKey : null
    },
    getResultFiles (state) {
      return state.resultFiles
    },
    getCurrentTOCIndex (state) {
      return state.currentTOCIndex
    },
    getTOCHighlights (state) {
      return state.highlightTOCIndex
    },
    getSearchMode (state) {
      return state.searchMode
    }
  },
  actions: {
    APIGetMetadata ({ commit }, docKey) {
      return new Promise((resolve, reject) => {
        const uri = `/appearances/${docKey}`
        axiosAPI.get(uri)
          .then(({ data }) => {
            commit('storeMetadata', data)
            resolve()
          })
          .catch(error => { reject(error) })
      })
    },
    APIGetToc (context, docKey) {
      return new Promise((resolve, reject) => {
        const uri = `/appearances/${docKey}/toc`
        axiosAPI.get(uri, {
          params: {
            licenseKey: context.state.license.licenseKey
          }
        })
          .then(({ data }) => {
            context.commit('storeToc', data[0].entries)
            resolve()
          })
          .catch(error => { reject(error) })
      })
    },
    APILicenseRequest ({ commit }, pubKey) {
      return new Promise((resolve, reject) => {
        const uri = `/publications/${pubKey}/license/acquire`
        axiosAPI.get(uri)
          .then(({ data }) => {
            commit('storeLicense', data)
            resolve()
          })
          .catch((error) => { reject(error) })
      })
    },
    APILicenseExpiry ({ commit }, licenseKey) {
      return new Promise((resolve, reject) => {
        const uri = `/licenses/${licenseKey}/getExpiryTime`
        axiosAPI.get(uri)
          .then(({ data }) => {
            if (Array.isArray(data)) {
              data = null
            }
            commit('storeLicense', data)
            resolve(data !== null)
          })
          .catch((error) => { reject(error) })
      })
    },
    APILicenseExtend ({ commit }, licenseKey) {
      return new Promise((resolve, reject) => {
        const uri = `/licenses/${licenseKey}/extend`
        axiosAPI.get(uri)
          .then(({ data }) => {
            if (Array.isArray(data)) {
              data = null
            }
            commit('storeLicense', data)
            resolve(data !== null)
          })
          .catch((error) => { reject(error) })
      })
    },
    APILicenseRelease ({ commit }, licenseKey) {
      return new Promise((resolve, reject) => {
        const uri = `/licenses/${licenseKey}/release`
        axiosAPI.get(uri).then(() => {
          commit('storeLicense', null)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
})
