<template>
  <div class="moduleactions">
    <router-link
      v-if="hasReadAction && publication.licenseavailable"
      :to="{ name: 'publicationloader', params: { pubKey: publication.pubkey } }"
      class="input-button input-button-outlined"
    >{{ capitalize($t('moduleAction.read')) }}</router-link>
    <button
      v-else-if="hasReadAction"
      class="input-button input-button-outlined"
      disabled
    >{{ capitalize($t('moduleAction.inUse')) }}</button>
    <button
      v-if="hasShopAction && !isBookAlreadyInShoppingBasket"
      class="input-button input-button-outlined"
      @click.stop="ADD_BOOK_TO_BASKET(publication)"
    >{{ capitalize($t('moduleAction.addToBasket')) }}</button>
    <button
      v-else-if="hasShopAction"
      class="input-button input-button-outlined"
      disabled
    >{{ capitalize($t('moduleAction.alreadyInBasket')) }}</button>
    <button
      v-if="hasRequestAction"
      class="input-button input-button-outlined"
      @click.stop="openModalRequest"
    >{{ capitalize($t('moduleAction.request')) }}</button>
    <button
      v-if="hasOpenInBibisAction"
      class="input-button input-button-outlined moduleactions-icon-button"
      @click.stop="openInBibis"
    >
      <img src="/img/bibis_logo.png" />
      <span>{{ capitalize($t('moduleAction.openInBibis')) }}</span>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Events } from '@/events'
import axiosAPI from '@/axiosAPI'

export default {
  name: 'module-result-actions',
  props: {
    publication: {
      type: Object,
      required: true
    },
    connectionId: {
      type: String,
      required: true
    },
    hasReadAction: {
      type: Boolean,
      required: true,
      default: false
    },
    hasRequestAction: {
      type: Boolean,
      required: true,
      default: false
    },
    hasShopAction: {
      type: Boolean,
      required: true,
      default: false
    },
    hasOpenInBibisAction: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    ...mapState({
      booksInBasket: state => state.shop.basket.books
    }),
    isBookAlreadyInShoppingBasket () {
      return !!(this.booksInBasket.find(b => b.pubkey === this.publication.pubkey))
    }
  },
  methods: {
    ...mapActions('shop/basket', [
      'ADD_BOOK_TO_BASKET'
    ]),
    openModalRequest () {
      Events.$emit('openModalRequest', { publication: this.publication.id, connection: this.connectionId })
    },
    async openInBibis () {
      try {
        const response = await axiosAPI.post(
          '/module/openinbibisactiontoken',
          { publicationId: this.publication.id, connectionId: this.connectionId },
          { responseType: 'json' }
        )

        if (response.status !== 200) {
          throw Error(response.data?.message)
        }

        if (!response.data.token?.trim() || !response.data.url) {
          throw Error('Missing actiontoken in response')
        }

        const url = response.data.url

        window.open(url, '_blank').focus()
      } catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .moduleactions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    &-icon-button {
      display: flex;
      align-items: center;

      & > img {
        flex-shrink: 0;
        flex-grow: 0;
        height: 1em;
        margin-right: 8px;
      }
    }
  }
</style>
