<template>
  <div class="bookshelfitem">
    <div
      v-if="state === STATES.LOADING"
      class="bookshelfitem-loading"
      >
        <spinner :size="60" :width="6"></spinner>
    </div>
    <div
      v-else-if="state === STATES.ERROR"
      class="bookshelfitem-error"
      >
        ERROR
    </div>
    <a
      v-else-if="state === STATES.READY && item.type === ITEM_TYPES.QUERY"
      class="bookshelfitem-content query"
      :href="createQueryUrl(content)"
      @click.prevent="selectQuery"
      >
        <img src="/img/icons/reader_search.png" width="20" height="20"> <div class="item-title">{{ content.query }}</div>
    </a>
    <div
      v-else-if="state === STATES.READY && item.type === ITEM_TYPES.PUBLICATION"
      class="bookshelfitem-content"
      :class="{ 'item-selected': selected }"
      @click="selectPublication"
      >
        <module-result
          :publication="content"
          :connectionId="'' + item.data.connectionId"
          :mode="'list'"
          />
    </div>
    <div v-if="author" class="item-actions">
      <button class="input-button" @click.prevent="deleteItem" :title="capitalize($t('actions.delete'))">
        <img src="/img/icons/delete.png" class="input-button-image" :alt="capitalize($t('actions.delete'))"/>
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/utilities/Spinner'
import ModuleResult from '@/components/module-results/ModuleResult'
import ITEM_TYPES from '@/constants/bookshelfItemType'
import STATES from '@/constants/states'

export default {
  name: 'bookshelf-item',
  props: ['item', 'selected', 'author'],
  emits: ['selectQuery', 'selectPublication', 'deleteItem'],
  data: () => ({
    ITEM_TYPES,
    STATES,
    state: STATES.INITIAL,
    content: null
  }),
  methods: {
    selectQuery () {
      this.$emit('selectQuery', this.content)
    },
    selectPublication () {
      const data = {
        connectionId: this.item.data.connectionId,
        publicationId: this.item.data.publicationId,
        itemId: this.item.id
      }

      this.$emit('selectPublication', data)
    },
    deleteItem () {
      this.$emit('deleteItem', this.item.id)
    },
    createQueryUrl (queryData) {
      const route = this.$router.resolve({
        name: 'results',
        query: {
          query: JSON.stringify(queryData)
        }
      })
      const fullUrl = window.location.origin + route.fullPath
      return fullUrl
    }
  },
  async mounted () {
    if (this.item.type === ITEM_TYPES.QUERY) {
      this.content = this.item.data
      this.state = STATES.READY
    } else if (this.item.type === ITEM_TYPES.PUBLICATION) {
      this.state = STATES.LOADING
      try {
        const { connectionId, publicationId } = this.item.data
        const metadata = await this.$store.dispatch(`searchStore/searchModules/${connectionId}/searchDetail`, publicationId)

        if (!metadata) {
          this.state = STATES.ERROR
          this.content = null
          return
        }

        this.content = metadata
        this.state = STATES.READY
      } catch {
        this.state = STATES.ERROR
      }
    }
  },
  components: {
    spinner: Spinner,
    'module-result': ModuleResult
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .bookshelfitem {
    &-loading {
      height: 200px;
      padding: 20px;
    }

    &-error {
      color: red;
    }

    &-content {
      border-left: 4px solid transparent;
      cursor: pointer;

      &.item-selected {
        border-left: 4px solid $main_light;
      }

      &:not(.item-selected):hover {
        border-left: 4px solid $grey_dark;
      }
    }

    .item-actions {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 4px;
      background-color: white;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    &:hover {
      .item-actions {
        display: block;
      }
    }
  }

  .query {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    color: black;
    text-decoration: none;

    .item-title {
      font-weight: bold;
      font-size: ($font_size + 4);
    }
  }
</style>
