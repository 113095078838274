<template>
  <div style="text-align: center; margin-top: 10px;">
    <div v-if="documentKey">
      <p>{{ $t('You will automatically be redirected for authentication.') }}</p>
      <p><a href="#" @click.prevent="NavigateToSSO">{{ $t('Click here') }}</a> {{ $t('to navigate to the authentication page if automatically redirecting is taking a long time.') }}</p>
    </div>
    <div v-else>
      <p>{{ $t('A problem occurred while authenticating') }}: {{ $t('Token missing') }}</p>
      <p>{{ $t('Please try again later or contact an administrator if the problem keeps occurring.') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    documentKey () {
      const fullRoute = this.$route.fullPath
      const routeQueryStart = fullRoute.indexOf('?')

      if (routeQueryStart === -1 || routeQueryStart === fullRoute.length - 1) return null

      return fullRoute.substring(routeQueryStart + 1)
    }
  },
  methods: {
    NavigateToSSO () {
      if (!this.documentKey) {
        console.error('Could not navigate to SSO: No key provided')
        return
      }

      let SSO_URL = window.globals.VUE_APP_SSOURL
      SSO_URL += `?appName=${window.globals.VUE_APP_SSOAPPNAME}`
      SSO_URL += `&client=${window.globals.VUE_APP_SSOCLIENT}`
      SSO_URL += `&secret=${window.globals.VUE_APP_SSOSECRET}`
      SSO_URL += `&k=${this.documentKey}`

      window.location = SSO_URL
    }
  },
  mounted () {
    this.NavigateToSSO()
  }
}
</script>
