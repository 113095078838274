<template>
  <div class="facets">
    <module-facet-filters
      :appliedFacets="appliedFacets['filters']"
      @toggle="(facetState) => $emit('toggle', facetState)"
    ></module-facet-filters>
    <module-facet-group
      v-for="facetCategory of facetCategories"
      :key="facetCategory"
      :facets="facets[facetCategory]"
      :fieldName="facetCategory"
      :facetGroupOrderSettings="facetGroupOrderSettings[facetCategory]"
      :activeFacets="activeFacets[facetCategory]"
      :appliedFacets="appliedFacets[facetCategory]"
      @toggle="(facetState) => $emit('toggle', facetState)"
    ></module-facet-group>
  </div>
</template>

<script>
import ModuleFacetGroup from './ModuleFacetGroup'
import ModuleFacetFilters from './ModuleFacetFilters'
import { mapGetters } from 'vuex'

export default {
  name: 'module-facets',
  emits: ['toggle'],
  props: {
    facets: {
      type: Object,
      default: () => ({})
    },
    activeFacets: {
      type: Object,
      default: () => ({})
    },
    appliedFacets: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    facetCategories () {
      const facetCategories = Object.keys(this.facets).filter(cat => this.facets[cat].length)
      const activeFacetCategories = Object
        .keys(this.activeFacets)
        .filter(cat => this.activeFacets[cat].length && !facetCategories.includes(cat) && cat !== 'filters')
      return [...facetCategories, ...activeFacetCategories]
    },
    ...mapGetters('searchStore', {
      facetGroupOrderSettings: 'getFacetGroupOrderSettings'
    })
  },
  components: {
    'module-facet-group': ModuleFacetGroup,
    'module-facet-filters': ModuleFacetFilters
  }
}
</script>
