export default {
  methods: {
    isInViewPort () {
      const rect = this.$el.getBoundingClientRect()
      const windowWidth = window.innerWidth || document.documentElement.clientWidth
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      return (
        ((rect.top >= 0 && rect.top <= windowHeight) || (rect.bottom >= 0 && rect.bottom <= windowHeight)) &&
        ((rect.left >= 0 && rect.left <= windowWidth) || (rect.right >= 0 && rect.right <= windowWidth))
      )
    }
  }
}
