<template>
  <div class="reader-print-container">
    <div class="reader-print-header">
      {{ $t('Print from the current page') }}
    </div>
    <div>
      {{ $t('Amount of pages') }}
      <select ref="amount" class="input-text">
        <option v-for="amount in PRINT_LIMIT" :value="amount" :key="amount">
          {{ amount}}
        </option>
      </select>
    </div>
    <div class="reader-print-actions">
      <button class="input-button input-button-primary" @click="print">{{ $t('Print') }}</button>
    </div>
  </div>
</template>

<script>
import { Events } from '@/events'

export default {
  name: 'reader-print',
  data () {
    return {
      PRINT_LIMIT: 10
    }
  },
  methods: {
    print () {
      const amount = this.$refs.amount.value

      Events.$emit('readerPrint', amount)
    }
  }
}
</script>

<style>

</style>
