<template>
  <div id="appcontainer">
    <xposi-header></xposi-header>
    <router-view/>
    <xposi-modal></xposi-modal>
    <div v-if="!$store.state.initialised" class="init-blanket">
      <spinner :size="60" :width="6"></spinner>
    </div>
    <modal-request
      v-if="showModalRequest"
      @close="closeModalRequest"
      ref="modalRequest"
      ></modal-request>
    <modal-bookshelf
      v-if="showModalBookshelf"
      @close="closeModalBookshelf"
      ref="modalBookshelf"
      ></modal-bookshelf>
    <modal-bookshelf-delete
      v-if="showModalBookshelfDelete"
      @close="closeModalBookshelfDelete"
      ref="modalBookshelfDelete"
      ></modal-bookshelf-delete>
    <modal-bookshelf-item-add
      v-if="showModalBookshelfItemAdd"
      @close="closeModalBookshelfItemAdd"
      ref="modalBookshelfItemAdd"
      ></modal-bookshelf-item-add>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Modal from '@/components/Modal'
import Spinner from '@/components/utilities/Spinner'
import ModalRequest from '@/components/modals/ModalRequest'
import ModalBookshelf from '@/components/modals/ModalBookshelf'
import ModalBookshelfDelete from '@/components/modals/ModalBookshelfDelete'
import ModalBookshelfItemAdd from '@/components/modals/ModalBookshelfItemAdd'
import { Events } from '@/events'

export default {
  data: () => ({
    showModalRequest: false,
    showModalBookshelf: false,
    showModalBookshelfDelete: false,
    showModalBookshelfItemAdd: false
  }),
  methods: {
    async openModalRequest (publicationInfo) {
      this.showModalRequest = true
      await this.$nextTick()
      if (this.$refs.modalRequest?.load) this.$refs.modalRequest.load(publicationInfo)
    },
    closeModalRequest () {
      this.showModalRequest = false
    },
    async openModalBookshelf (editData) {
      this.showModalBookshelf = true
      await this.$nextTick()
      if (this.$refs.modalBookshelf?.load) this.$refs.modalBookshelf.load(editData)
    },
    closeModalBookshelf () {
      this.showModalBookshelf = false
    },
    async openModalBookshelfDelete (bookshelfData) {
      this.showModalBookshelfDelete = true
      await this.$nextTick()
      if (this.$refs.modalBookshelfDelete?.load) this.$refs.modalBookshelfDelete.load(bookshelfData)
    },
    closeModalBookshelfDelete () {
      this.showModalBookshelfDelete = false
    },
    async openModalBookshelfItemAdd (addData) {
      this.showModalBookshelfItemAdd = true
      await this.$nextTick()
      if (this.$refs.modalBookshelfItemAdd?.load) this.$refs.modalBookshelfItemAdd.load(addData)
    },
    closeModalBookshelfItemAdd () {
      this.showModalBookshelfItemAdd = false
    }
  },
  components: {
    'xposi-header': Header,
    'xposi-modal': Modal,
    spinner: Spinner,
    'modal-request': ModalRequest,
    'modal-bookshelf': ModalBookshelf,
    'modal-bookshelf-delete': ModalBookshelfDelete,
    'modal-bookshelf-item-add': ModalBookshelfItemAdd
  },
  mounted () {
    Events.$on('openModalRequest', this.openModalRequest)
    Events.$on('openModalBookshelf', this.openModalBookshelf)
    Events.$on('openModalBookshelfDelete', this.openModalBookshelfDelete)
    Events.$on('openModalBookshelfItemAdd', this.openModalBookshelfItemAdd)
  }
}
</script>

<style lang="scss">
  @import "./assets/css/stylesheet.scss";

  .init-blanket {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 100;
  }
</style>
