<template>
  <div class="page-container">

    <div class="page-content" @scroll="handleScrollEvent">

      <div class="page-content-container">
        <router-link
        v-if="booksInBasket.length"
        to="/search"
        class="input-button input-button-outlined"
      >{{ $t('Continue shopping') }}</router-link>
        <h1>{{ $t('Shopping basket') }}</h1>
        <div v-if="!booksInBasket.length">
          <p>
            {{ $t('There are no items in your shopping basket.') }} <router-link class="input-button" to="/search">{{ $t('Back to shop') }}</router-link>
          </p>
        </div>
        <div>
          <ShopBasketItem ref="basketItems"
              v-for="book in booksInBasket" :key="book.isbn"
              :book="book">
            <template v-slot:actions>
              <button class="input-button input-button-outlined" @click="REMOVE_BOOK_FROM_BASKET(book)">{{ $t('Remove') }}</button>
            </template>
          </ShopBasketItem>
        </div>

        <ShopBasketTotal :books="booksInBasket" v-if="booksInBasket.length" class="mb-3" />

        <div>
          <router-link v-if="booksInBasket.length" class="input-button input-button-primary" to="/shop/checkout">{{ $t('Checkout') }}</router-link>
        </div>
        </div>
        <div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ShopBasketItem from '@/components/shop-basket/ShopBasketItem.vue'
import ShopBasketTotal from '@/components/shop-basket/ShopBasketTotal.vue'
import debounceMixin from '@/mixins/debounce'

export default {
  computed: {
    ...mapState({
      booksInBasket: state => state.shop.basket.books
    }),
    basketItemElements () {
      return this.$refs.basketItems || []
    }
  },
  methods: {
    ...mapActions('shop/basket', [
      'REMOVE_BOOK_FROM_BASKET'
    ]),
    handleScrollEvent () {
      this.debounce(() => this.loadImagesForBooksInViewPort())
    },
    loadImagesForBooksInViewPort () {
      for (const basketItemElement of this.basketItemElements) {
        if (basketItemElement.isInViewPort()) {
          basketItemElement.loadBookCover()
        }
      }
    }
  },
  mounted () {
    this.loadImagesForBooksInViewPort()
  },
  components: {
    ShopBasketItem,
    ShopBasketTotal
  },
  mixins: [
    debounceMixin
  ]
}
</script>

<style>
</style>
