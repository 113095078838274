<template>
  <div class="reader-navigation-container">
    <div v-if="mimeType === 'pdf'" id="reader-navigation-firstpage" class="reader-navigation-button" @click="firstPage" :title="$t('Navigate to the first page')">
      <img src="/img/icons/first_page.png"/>
    </div>
    <div id="reader-navigation-previouschapter" class="reader-navigation-button" :class="{'reader-navigation-button-disabled': !previousChapterAvailable}" @click="previousChapter" :title="$t('Navigate to the previous chapter')">
      <img src="/img/icons/prev_chapter.png"/>
    </div>
    <div id="reader-navigation-previouspage" class="reader-navigation-button" :class="{'reader-navigation-button-disabled': !previousPageAvailable}" @click="previousPage" :title="$t('Navigate to the previous page')">
      <img src="/img/icons/prev_page.png"/>
    </div>
    <span v-show="mimeType === 'pdf'" id="reader-navigation-pagecontainer">
      <span>
        <input ref="pageselect" id="reader-navigation-pageselect" class="input-text" type="text" :value="currentPage" @focus="showPageSubmitButton" @keypress.enter="selectPage"/>
        <button v-if="showPageSubmit" ref="pageselectsubmit" id="reader-navigation-submitpage" class="input-button input-button-primary" @click="selectPage" @keypress.enter="selectPage">
          <img src="/img/icons/go_white.png"/>
        </button>
      </span>
       / {{ pages }}
    </span>
    <div id="reader-navigation-nextpage" class="reader-navigation-button" :class="{'reader-navigation-button-disabled': !nextPageAvailable}" @click="nextPage" :title="$t('Navigate to the next page')">
      <img src="/img/icons/next_page.png"/>
    </div>
    <div id="reader-navigation-nextchapter" class="reader-navigation-button" :class="{'reader-navigation-button-disabled': !nextChapterAvailable}" @click="nextChapter" :title="$t('Navigate to the next chapter')">
      <img src="/img/icons/next_chapter.png"/>
    </div>
    <div v-if="mimeType === 'pdf'" id="reader-navigation-lastpage" class="reader-navigation-button" @click="lastPage" :title="$t('Navigate to the last page')">
      <img src="/img/icons/last_page.png"/>
    </div>
  </div>
</template>

<script>
import { Events } from '@/events'

export default {
  data () {
    return {
      nextPageAvailable: true,
      previousPageAvailable: true,
      nextChapterAvailable: true,
      previousChapterAvailable: true,
      showPageSubmit: false
    }
  },
  computed: {
    pages () {
      const appearance = this.$store.getters['reader/getAppearance']
      if (!appearance) {
        return 0
      }

      return appearance.numPages
    },
    currentPage () {
      return this.$store.getters['reader/getCurrentPage']
    },
    mimeType () {
      return this.$store.getters['reader/getMimetype']
    }
  },
  methods: {
    previousPage () {
      if (!this.previousPageAvailable) return
      Events.$emit('readerNavigationPreviousPage')
    },
    nextPage () {
      if (!this.nextPageAvailable) return
      Events.$emit('readerNavigationNextPage')
    },
    previousChapter () {
      if (!this.previousChapterAvailable) return
      Events.$emit('readerNavigateToPreviousChapter')
    },
    nextChapter () {
      if (!this.nextChapterAvailable) return
      Events.$emit('readerNavigateToNextChapter')
    },
    showPageSubmitButton () {
      const _this = this

      if (!this.showPageSubmit) {
        this.showPageSubmit = true
        document.addEventListener('click', _this.checkPageSubmitBlur)
      }
    },
    hidePageSubmitButton () {
      const _this = this

      document.removeEventListener('click', _this.checkPageSubmitBlur)
      this.showPageSubmit = false
    },
    checkPageSubmitBlur (e) {
      const target = e.target
      if (target === this.$refs.pageselect || target === this.$refs.pageselectsubmit) {
        return
      }

      this.$refs.pageselect.value = this.currentPage
      this.hidePageSubmitButton()
    },
    selectPage () {
      const pageselect = this.$refs.pageselect
      let page = parseInt(pageselect.value)
      if (isNaN(page)) {
        pageselect.value = this.currentPage
        this.hidePageSubmitButton()
        return
      }

      if (page < 1) page = 1
      if (page > this.pages) page = this.pages

      pageselect.value = page

      Events.$emit('readerNavigateToPage', page)
      this.hidePageSubmitButton()
    },
    firstPage () {
      Events.$emit('readerNavigateToPage', 1)
    },
    lastPage () {
      Events.$emit('readerNavigateToPage', this.pages)
    },
    setNextPageAvailability (availability) {
      availability = !!availability
      this.nextPageAvailable = availability
    },
    setPreviousPageAvailability (availability) {
      availability = !!availability
      this.previousPageAvailable = availability
    },
    setNextChapterAvailability (availability) {
      availability = !!availability
      this.nextChapterAvailable = availability
    },
    setPreviousChapterAvailability (availability) {
      availability = !!availability
      this.previousChapterAvailable = availability
    }
  }
}
</script>

<style>

</style>
