export default {
  data () {
    return {
      isMobileThreshold: 960,
      isMobile: false
    }
  },
  methods: {
    checkScreenSize () {
      const windowWidth = window.innerWidth
      // const windowHeight = window.innerHeight

      this.isMobile = (windowWidth < this.isMobileThreshold)
    }
  },
  created () {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkScreenSize)
  }
}
