import axiosAPI from '@/axiosAPI'

const SET_BOOKS = 'SET_BOOKS'
const SET_SEARCH = 'SET_SEARCH'

export default () => ({
  namespaced: true,
  state: {
    books: getInitialBooksState(),
    search: getInitialSearchState()
  },
  mutations: {
    [SET_BOOKS] (state, books = {}) {
      state.books = {
        ...state.books,
        ...books
      }
    },
    [SET_SEARCH] (state, search = {}) {
      state.search = {
        ...state.search,
        ...search
      }
    }
  },
  actions: {
    RESET_BOOKS,
    LOAD_BOOKS,
    SELECT_BOOK,
    DESELECT_BOOK
  }
})

function RESET_BOOKS ({ commit }) {
  commit(SET_BOOKS, getInitialBooksState())
}

function LOAD_BOOKS ({ state, commit }, { query } = {}) {
  const newPageIndex = state.books.currentPageIndex + 1
  return new Promise((resolve, reject) => {
    commit(SET_SEARCH, {
      query
    })
    commit(SET_BOOKS, {
      isLoading: true
    })
    axiosAPI.get('/shop/books', {
      params: {
        pageIndex: newPageIndex,
        ...(state.search.query ? { query: state.search.query } : {})
      }
    }).then(({ data }) => {
      commit(SET_BOOKS, {
        items: [
          ...state.books.items,
          ...data.items
        ],
        totalAmount: data.totalAmount,
        currentPageIndex: newPageIndex,
        isLoading: false
      })
      resolve()
    }).catch((error) => {
      commit(SET_BOOKS, {
        ...getInitialBooksState(),
        errorLoading: true
      })
      reject(error)
    })
  })
}

function SELECT_BOOK ({ commit }, book) {
  commit(SET_BOOKS, {
    selectedItem: book
  })
}

function DESELECT_BOOK ({ commit }) {
  commit(SET_BOOKS, {
    selectedItem: null
  })
}

function getInitialBooksState () {
  return {
    items: [],
    totalAmount: 0,
    currentPageIndex: -1,
    selectedItem: null,
    isLoading: false,
    errorLoading: false
  }
}

function getInitialSearchState () {
  return {
    query: null
  }
}
