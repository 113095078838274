<template>
  <div class="metadata-panel">
    <div class="metadata-panel-options">
      <div class="metadata-panel-option" :title="capitalize($t('close'))" @click="$emit('close')">
        <img class="metadata-panel-option-image" src="/img/icons/close.png" alt="close">
      </div>
      <div class="metadata-panel-option-spacer"></div>
      <button
        v-if="state === STATES.READY && showAddToBookshelfAction && publication"
        class="input-button input-button-compact"
        :title="capitalize($t('bookshelf.addToBookshelf'))"
        @click="addToBookshelf"
        >
        <img class="input-button-image input-button-image-compact" src="/img/icons/bookshelf.png" :alt="capitalize($t('bookshelf.addToBookshelf'))">
    </button>
      <div
        v-if="state === STATES.READY && publication"
        class="metadata-panel-option"
        :title="capitalize($t('share'))"
        >
        <img class="metadata-panel-option-image" src="/img/icons/share.png" alt="share">
        <div class="metadata-panel-option-foldout">
          <h3>{{ capitalize($t('share')) }}</h3>
          <copy-clipboard class="metadata-panel-share" :copyvalue="shareUrl" />
        </div>
      </div>
    </div>
    <publication-metadata
      class="metadata-panel-content"
      v-if="state === STATES.READY && publication"
      :publication="publication"
      :connectionId="connectionId"
    ></publication-metadata>
    <div v-if="state === STATES.LOADING" class="metadata-panel-state">
      <spinner :size="40" :width="4"></spinner>
    </div>
    <div v-if="state === STATES.ERROR" class="metadata-panel-state">
      <img src="/img/searching_500.png" style="max-width: 100%" />
      <div>{{ capitalize($t('there has been an error while searching')) }}</div>
    </div>
  </div>
</template>

<script>
import PublicationMetadata from '@/components/publication/PublicationMetadata'
import Spinner from '@/components/utilities/Spinner'
import STATES from '@/constants/states'
import CopyClipboard from '@/components/form-elements/CopyClipboard'
import { Events } from '@/events'
import ITEM_TYPES from '@/constants/bookshelfItemType'

export default {
  name: 'module-result-detail',
  emits: ['close'],
  props: {
    actionAddToBookshelf: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    shareUrl () {
      if (this.state !== this.STATES.READY || !this.publication) return ''

      const queryObject = {
        query: `id:${this.publicationId}`,
        queryType: 0,
        connection: this.connectionId
      }
      const route = this.$router.resolve({
        name: 'results',
        query: {
          query: JSON.stringify(queryObject)
        }
      })
      const fullUrl = window.location.origin + route.fullPath
      return fullUrl
    },
    showAddToBookshelfAction () {
      if (!this.actionAddToBookshelf) return false
      return this.$store.getters.userHasUserRole
    }
  },
  data: () => ({
    state: STATES.READY,
    publication: null,
    connectionId: null,
    publicationId: null,
    STATES
  }),
  methods: {
    async loadPublication ({ publication, connection: connectionId }) {
      if (publication === this.publicationId) return

      this.publicationId = publication
      this.connectionId = connectionId
      this.state = STATES.LOADING

      try {
        const metadata = await this.$store.dispatch(`searchStore/searchModules/${connectionId}/searchDetail`, publication)

        if (!metadata) {
          this.state = STATES.ERROR
          this.metadata = null
          return
        }

        this.publication = metadata
        this.state = STATES.READY
      } catch {
        this.state = STATES.ERROR
      }
    },
    addToBookshelf () {
      const addData = {
        type: ITEM_TYPES.PUBLICATION,
        data: {
          publicationId: this.publication.id,
          connectionId: parseInt(this.connectionId)
        }
      }

      Events.$emit('openModalBookshelfItemAdd', addData)
    }
  },
  components: {
    'publication-metadata': PublicationMetadata,
    spinner: Spinner,
    'copy-clipboard': CopyClipboard
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .metadata-panel {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    height: 100%;
    width: 460px;
    flex-direction: column;
    box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.46);
    background: white;
    z-index: 1;

    &-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;
      border-bottom: 1px solid #ddd;

      .metadata-panel-option-spacer {
        flex-grow: 1;
      }

      .metadata-panel-option {
        display: inline-block;
        height: 40px;
        padding: 10px;
        flex-shrink: 0;
        cursor: pointer;

        &-image {
          margin: 0;
        }

        &-foldout {
          display: none;
          background-color: white;
          padding: 10px;
          position: absolute;
          right: 0;
          top: calc(100% - 10px);
          border-radius: 4px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          z-index: 2;
        }

        &:hover .metadata-panel-option-foldout {
          display: block;
          cursor: default;
        }
      }
    }

    &-state {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &-content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .metadata-panel-share {
    width: 300px;
  }
</style>
