<template>
  <div class="searchbar-container" v-run-on-blur="closePanel">
    <div class="searchbar-wrapper">
      <input type="text" class="input-text searchbar-searchbar"
        :value="query"
        :placeholder="capitalize($t('search')) + '...'"
        @focus="openPanel"
      />
      <div
        v-show="advancedSearchAltered"
        class="searchbar-advancedlabel"
        :title="capitalize($t('There are filled advanced fields. Click to open the advanced search panel.'))"
        @click="openPanel"
      ></div>
    </div>
    <div v-if="panelOpen" class="advancedsearch-container">
      <advanced-search-panel
        @close="closePanel"
        @search="onSearch"
        ref="searchpanel"
      ></advanced-search-panel>
    </div>
  </div>
</template>

<script>
import AdvancedSearchPanel from './AdvancedSearchPanel'
import { mapState } from 'vuex'

export default {
  name: 'search-bar',
  computed: {
    ...mapState('searchStore/currentSearch', {
      query: state => state.query,
      advancedSearchAltered: state => state.altered
    })
  },
  data: () => ({
    panelOpen: false
  }),
  methods: {
    async openPanel () {
      this.panelOpen = true
      await this.$nextTick()
      if (this.$refs.searchpanel) this.$refs.searchpanel.focusQueryInput()
    },
    closePanel () {
      this.panelOpen = false
    },
    onSearch () {
      this.closePanel()
      this.$router.push({ name: 'results' })
    }
  },
  components: {
    'advanced-search-panel': AdvancedSearchPanel
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .searchbar-searchbar {
    width: 250px;
  }

  .searchbar-wrapper {
    display: inline-block;

    .searchbar-advancedlabel {
      position: absolute;
      right: 6px;
      top: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background: $main_light;
      border-radius: 50%;
    }
  }

  .advancedsearch-container {
    position: absolute;
    top: -$advanced-searchpanel-padding;
    left: -$advanced-searchpanel-padding;
    width: 600px;
    z-index: 1;
  }
</style>
