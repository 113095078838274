export default {
  methods: {
    getFileNameFromUrl (url) {
      const fileNameStart = url.lastIndexOf('/')
      let fileNameEnd = url.lastIndexOf('.')
      if (fileNameEnd === -1) fileNameEnd = url.length

      return url.slice(fileNameStart + 1, fileNameEnd)
    }
  }
}
