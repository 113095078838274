<template>
  <div
    class="cselect"
    @blur.capture="handleComponentBlur"
  >
    <button
      class="cselect-control"
      @click="toggleOptions"
      ref="control"
    >
      <span v-if="selectedOption !== null">{{ disableLabelTranslation ? selectedOption.label : $t(selectedOption.label) }}</span>
    </button>
    <ul
      v-if="optionsVisible"
      class="cselect-options"
      ref="optionsContainer"
      tabindex="-1"
    >
      <li
        v-for="option in options"
        :key="option.label"
        :value="option.value"
        class="cselect-option"
        @click="handleOptionClick(option)"
      >
        {{ disableLabelTranslation ? option.label : $t(option.label) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'custom-select',
  props: {
    modelValue: {
      type: [Number, String],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    disableLabelTranslation: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      selectedOption: null,
      optionsVisible: true
    }
  },
  watch: {
    modelValue (value) {
      const option = this.getOptionByValue(value)

      if (option === null) {
        this.selectFirstAvailableOption()
      } else if (this.selectedOption.value !== option.value) {
        this.setSelectedOption(option)
      }
    }
  },
  methods: {
    showOptions () {
      this.optionsVisible = true
    },
    hideOptions () {
      this.optionsVisible = false
    },
    toggleOptions () {
      this.optionsVisible ? this.hideOptions() : this.showOptions()
    },
    selectFirstAvailableOption () {
      this.setSelectedOption(this.options[0])
    },
    setSelectedOption (option, doUpdate = true) {
      this.selectedOption = option
      if (doUpdate) this.$emit('update:modelValue', option ? option.value : null)
    },
    handleOptionClick (option) {
      this.setSelectedOption(option)
      this.hideOptions()
    },
    handleComponentBlur (e) {
      if (!this.$el.contains(e.relatedTarget)) this.hideOptions()
    },
    getOptionByValue (value) {
      const option = this.options.find(option => option.value === value)
      return (typeof option === 'undefined') ? null : option
    }
  },
  mounted () {
    if (this.modelValue === null) {
      this.selectFirstAvailableOption()
    } else {
      this.setSelectedOption(this.getOptionByValue(this.modelValue))
    }
    const optionsContainerWidth = this.$refs.optionsContainer.offsetWidth
    this.$refs.control.style.width = optionsContainerWidth + 'px'
    this.optionsVisible = false
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  $control-padding: 10px;

  .cselect {
    position: relative;

    &-control {
      border: none;
      background: none;
      padding: $control-padding 20px ($control-padding - 1px) 0;
      border-bottom: 1px solid $grey_middle;
      text-align: left;
      font-size: $font_size;
      font-family: $font;
      cursor: pointer;

      &::after {
        content: '';
        border-bottom: none;
        border-top: 6px solid $grey_dark;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        position: absolute;
        right: 0;
        top: calc(50% - 2px);
      }

      &:hover, &:focus {
        border-bottom-color: black;

        &::after {
          border-top-color: black;
        }
      }
    }

    &-options {
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      list-style: none;
      margin: 0;
      padding: 0;
      border: 1px solid $grey_light;
      border-radius: 4px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      background: white;
      z-index: 20;
      overflow-x: hidden;
      overflow-y: auto;
      max-height:120px;
    }

    &-option {
      padding: $control-padding $control-padding+10 $control-padding $control-padding;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: $grey_light;
      }
    }
  }
</style>
