<template>
  <div class="alert" :class="type ? `alert-${type}` : ''" role="alert">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style>
</style>
