<template>
  <div class="popup-background">
    <div class="popup-window">
      <div class="popup-header">
        <h1 class="popup-title">{{ capitalize($t(id ? 'bookshelf.editBookshelf' : 'bookshelf.addBookshelf')) }}</h1>
      </div>
      <div v-if="validationError" class="form-row">
        <div class="validationerror-block">{{ validationError }}</div>
      </div>
      <div class="popup-form">
        <div class="form-row">
          <label for="name">{{ capitalize($t('bookshelf.bookshelfName')) }}*</label>
          <input type="text" v-model="name" id="name" class="input-text input-fullwidth" />
        </div>
      </div>
      <div class="form-row">
        <button @click="send" class="input-button input-button-primary" :disabled="this.sending">{{ capitalize($t(id ? 'actions.edit' : 'actions.add')) }}</button>
      </div>
      <div class="popup-close" @click="close">&#10006;</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-bookshelf',
  emits: ['close'],
  data: () => ({
    name: '',
    id: null,
    validationError: null,
    sending: false
  }),
  methods: {
    reset () {
      this.validationError = null
      this.sending = false
      this.name = ''
    },
    load (editData = null) {
      this.reset()

      if (!editData) return

      this.id = editData.id
      this.name = editData.name
    },
    async send () {
      if (this.sending) return

      if (!this.name.trim()) {
        this.validationError = this.capitalize(`${this.$t('bookshelf.bookshelfName')} ${this.$t('bookshelf.is_required')}`)
        return false
      }

      this.validationError = null
      this.sending = true

      // submit new list
      if (!this.id) {
        const data = { name: this.name }
        const result = await this.$store.dispatch('bookshelfStore/createNewBookshelf', data)

        if (result.error) {
          this.sending = false
          this.validationError = result.error
          return false
        }

        await this.$store.dispatch('bookshelfStore/fetchUserBookshelves')
        this.$router.push('/bookshelf/' + result.id)
      } else {
        const data = { id: this.id, name: this.name }
        const result = await this.$store.dispatch('bookshelfStore/editBookshelf', data)

        if (result.error) {
          this.sending = false
          this.validationError = result.error
          return false
        }

        await this.$store.dispatch('bookshelfStore/fetchUserBookshelves')
        this.$router.push('/bookshelf/' + result.id)
      }

      this.sending = false
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-row {
    margin: 10px 0;

    label {
      display: block;
    }
  }

  .input-fullwidth {
    width: 100%;
  }

  .validationerror-block {
    padding: 10px;
    border: 1px solid red;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
  }
</style>
