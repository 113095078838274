<template>
  <div class="shop-basket-item">
    <div v-if="isNew" :title="$t('item.new.tooltip', { daysMarkedAsNew })" class="item-new"></div>
    <publication-cover :url="book.cover" ref="cover"></publication-cover>
    <div class="shop-basket-item-info">
      <div v-if="authorDisplay" class="shop-basket-item-authors" :title="authorDisplay">{{ authorDisplay }}</div>
      <div v-if="book.title" class="shop-basket-item-title" v-html="parseDOM(book.title)" :title="parseDOM(book.title)"></div>
      <div v-if="publicationDisplay" class="shop-basket-item-publication" :title="publicationDisplay">{{ publicationDisplay }}</div>
      <div class="flex-grow-1"></div>
      <div v-if="book.priceInCents" class="shop-basket-item-price">
        {{ $filters.currency(book.priceInCents) }}
        <span class="shop-basket-item-price-tax">{{ $t('common.excluding-tax') }}</span>
      </div>
      <div class="flex-grow-1"></div>
      <div class="shop-basket-item-actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div v-if="priceDisplay" class="result-price">
      <span>{{ priceDisplay }}</span>
      <span v-if="licenseAvailable" :title="$t('shop.library.items.already-in-collection')" class="shop-library-license-available-container"><img src="/img/icons/checkmark_color.png" /></span>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import PublicationCover from '@/components/publication/PublicationCover'
import domparserMixin from '@/mixins/domparser'
import viewPortMixin from '@/mixins/viewPort'
import { parse as parseDate, differenceInDays } from 'date-fns'

export default {
  props: {
    book: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    publicationDisplay () {
      let displayString = ''
      const { publisher, yearOfPublication, edition } = this.book

      if (publisher) displayString += publisher + ' '
      if (yearOfPublication) displayString += yearOfPublication + ' '
      if (edition) {
        const wrap = (publisher || yearOfPublication)
        if (wrap) displayString += '('
        displayString += edition
        if (wrap) displayString += ')'
      }

      return displayString
    },
    isNew () {
      if (!this.book.created && !this.book.licenseCreated) return false

      const createdDateString = this.book.created || this.book.licenseCreated
      const validCreatedDateString = createdDateString.includes('T') ? createdDateString.replace('T', ' ') : createdDateString
      const bookCreatedDate = parseDate(validCreatedDateString, 'yyyy-MM-dd HH:mm:ss', new Date())
      const now = new Date()
      return differenceInDays(now, bookCreatedDate) < this.daysMarkedAsNew
    },
    authorDisplay () {
      let displayString = ''

      if (!this.book.authors) return displayString

      for (let i = 0; i < this.book.authors.length; i++) {
        if (i > 0) displayString += '; '
        displayString += this.book.authors[i]
      }

      return displayString
    },
    priceDisplay () {
      if (this.book.price) return this.$filters.currency(this.book.price, this.book.currency)
      return null
    },
    licenseAvailable () {
      return this.book.licenseavailable
    }
  },
  data: () => ({
    daysMarkedAsNew: 14
  }),
  methods: {
    loadBookCover () {
      this.$refs.cover.loadImage()
    },
    scrollIntoView () {
      this.$el.scrollIntoView()
    }
  },
  components: {
    'publication-cover': PublicationCover
  },
  mixins: [
    domparserMixin,
    viewPortMixin
  ]
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/_constants";

  .item-new {
    position: absolute;
    top: -8px;
    left: -9px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #e65133;
    border: 4px solid white;
    z-index: 1;
  }
  .result-price {
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
    font-size: ($font_size + 4);
    width: 120px;
    min-width: 120px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .item-price-tax {
    margin-left: map-get($spacers, 1);
    font-size: 1rem;
    font-weight: normal;
  }
</style>
