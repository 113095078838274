const targetIsPartOf = (target, parent) => {
  return (target === parent || parent.contains(target) || !target.isConnected)
}

const runOnBlur = {
  beforeMount (element, binding) {
    element.runOnBlurEvent = (event) => {
      if (!targetIsPartOf(event.target, element)) binding.value()
    }

    document.addEventListener('click', element.runOnBlurEvent)
  },
  unmounted (element) {
    document.removeEventListener('click', element.runOnBlurEvent)
  }
}

export default runOnBlur
