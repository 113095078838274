<template>
  <div style="width: 100%; height: 100%;">
    <div class="reader-container-loading">
      <div>
        <img src="/img/anim_searching_white.gif" style="max-width: 100%;" />
      </div>
    </div>
  </div>
</template>

<script>
import axiosAPI from '@/axiosAPI'
import { Events } from '@/events'

export default {
  name: 'publication-loader',
  data () {
    return {
      pubKey: this.$route.params.pubKey
    }
  },
  methods: {
    getAppearancesFromPublicationMetadata () {
      return new Promise((resolve, reject) => {
        axiosAPI.get(`/publications/${this.pubKey}`)
          .then(({ data }) => {
            if (!data.metadata || !data.metadata.appearances) {
              resolve([])
            } else {
              resolve(data.metadata.appearances)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  mounted () {
    const _this = this

    this.getAppearancesFromPublicationMetadata()
      .then(appearances => {
        if (!appearances.length) {
          Events.$emit('modalOpen', {
            type: 'error',
            icon: 'nodoc.png',
            title: _this.$t('No appearances found for this publication'),
            body: _this.$t('There are no appearances linked to this publication'),
            requireAction: true
          })
        } else if (appearances.length === 1) {
          _this.$router.push({ name: 'reader', params: { docKey: appearances[0].docKey } })
        } else {
          const actions = []

          for (let i = 0; i < appearances.length; i++) {
            const docKey = appearances[i].docKey
            const mimetype = appearances[i].mimetype.split('/')[1]

            actions.push({
              label: mimetype,
              action () { Events.$emit('modalClose'); _this.$router.push({ name: 'reader', params: { docKey } }) }
            })
          }

          Events.$emit('modalOpen', {
            type: 'info',
            icon: 'multidoc.png',
            title: _this.$t('Multiple appearances found for this publication'),
            body: _this.$t('There are multiple appearances found for this publication.<br>Please choose which appearance you would like to read:'),
            requireAction: true,
            actions
          })
        }
      })
      .catch(error => {
        if (error.response) {
          const statuscode = error.response.status

          switch (statuscode) {
            case 404:
              Events.$emit('modalOpen', {
                type: 'error',
                icon: 'nodoc.png',
                title: _this.$t('Publication not be found'),
                body: _this.$t('The requested publication could not be found'),
                requireAction: true
              })
              break
          }
        }
      })
  }
}
</script>

<style>

</style>
