export default {
  methods: {
    parseDOM (originalString) {
      const textArea = document.createAttribute('textarea')
      let fixedstring

      try {
        // If the string is UTF-8, this will work and not throw an error.
        fixedstring = decodeURIComponent(escape(originalString))
      } catch (e) {
        // If it isn't, an error will be thrown, and we can assume that we have an ISO string.
        fixedstring = originalString
      }

      textArea.value = fixedstring

      return unescape(textArea.value)
    }
  }
}
