<template>
  <div class="facetgroup">
    <div class="facetgroup-header">
      <h3 class="facetgroup-name" @click="toggleCollapse">
        <span class="facetgroup-collapsetoggle">{{ collapsed ? '+' : '-'}}</span>
        {{ capitalize($t(`metadataField.${fieldName}`)) }}
      </h3>
      <div class="facetgroup-settingcontainer">
        <div class="facetgroup-setting" @click="toggleSortType" :title="sortTypeToolTip">
          <img v-if="facetGroupOrderSettings.sortType === FACET_SORTING.FACET_SORT_TYPE_HITS" src="/img/icons/sort_hits.png" />
          <img v-else src="/img/icons/sort_values.png" />
        </div>
        <div class="facetgroup-setting" @click="toggleSortOrder" :title="sortOrderToolTip">
          <img v-if="facetGroupOrderSettings.sortOrder === FACET_SORTING.FACET_SORT_ORDER_DESCENDING" src="/img/icons/sort_descending.png" />
          <img v-else src="/img/icons/sort_ascending.png" />
        </div>
      </div>
    </div>

    <div v-show="!collapsed" class="facetgroup-content">
      <module-facet
        v-for="facet of sortedFacets.slice(0, visibleFacetsAmount)"
        :key="facet.key"
        :facet="facet"
        :active="appliedFacets.includes(facet.key)"
        @toggle="(status) => onFacetToggle(facet, status)"
      ></module-facet>

      <div class="facetgroup-contentcontrols">
        <button
          v-if="visibleFacetsAmount < facets.length"
          class="facetgroup-amountcontrol"
          @click="moreVisibleFacets"
          :title="capitalize($t('more'))"
        >
          <div class="arrow arrow-down"></div>
        </button>
        <button
          v-if="visibleFacetsAmount > defaultFacetsVisible"
          class="facetgroup-amountcontrol"
          @click="lessVisibleFacets"
          :title="capitalize($t('less'))"
        >
          <div class="arrow arrow-up"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleFacet from './ModuleFacet'
import FACET_SORTING from '@/constants/facetSorting'
import { defaultFacetSortType, defaultFacetSortOrder, defaultFacetsVisible } from '@/constants/defaults'
import { mapActions } from 'vuex'
import sortFacets from '@/utilities/facetSortHelper'

const VISIBLE_FACETS_STEP = 10

export default {
  name: 'module-facet-group',
  emits: ['toggle'],
  props: {
    fieldName: String,
    facets: {
      type: Array,
      default: () => []
    },
    facetGroupOrderSettings: {
      type: Object,
      default: () => ({
        sortType: defaultFacetSortType,
        sortOrder: defaultFacetSortOrder
      })
    },
    activeFacets: {
      type: Array,
      default: () => []
    },
    appliedFacets: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    facets () {
      this.resetVisibleFacetsAmount()
      this.processFacets()
    },
    facetGroupOrderSettings () {
      this.resetVisibleFacetsAmount()
      this.processFacets()
    }
  },
  computed: {
    sortTypeToolTip () {
      const currentType = this.facetGroupOrderSettings.sortType === FACET_SORTING.FACET_SORT_TYPE_HITS
        ? this.$t('facets.hits')
        : this.$t('facets.value')

      const alternativeType = this.facetGroupOrderSettings.sortType === FACET_SORTING.FACET_SORT_TYPE_HITS
        ? this.$t('facets.value')
        : this.$t('facets.hits')

      return this.capitalize(this.$t('facets.sorttype', { currentType, alternativeType }))
    },
    sortOrderToolTip () {
      const currentOrder = this.facetGroupOrderSettings.sortOrder === FACET_SORTING.FACET_SORT_ORDER_DESCENDING
        ? this.$t('facets.descending')
        : this.$t('facets.ascending')

      const alternativeOrder = this.facetGroupOrderSettings.sortOrder === FACET_SORTING.FACET_SORT_ORDER_DESCENDING
        ? this.$t('facets.ascending')
        : this.$t('facets.descending')

      return this.capitalize(this.$t('facets.sortorder', { currentOrder, alternativeOrder }))
    }
  },
  data: () => ({
    FACET_SORTING,
    defaultFacetsVisible: defaultFacetsVisible,
    visibleFacetsAmount: defaultFacetsVisible,
    sortedFacets: [],
    collapsed: false
  }),
  methods: {
    ...mapActions('searchStore', {
      setFacetGroupOrderSetting: 'setFacetGroupOrderSetting'
    }),
    toggleSortType () {
      const newSetting = {
        sortType: this.facetGroupOrderSettings.sortType,
        sortOrder: this.facetGroupOrderSettings.sortOrder
      }

      if (this.facetGroupOrderSettings.sortType === FACET_SORTING.FACET_SORT_TYPE_VALUES) {
        newSetting.sortType = FACET_SORTING.FACET_SORT_TYPE_HITS
      } else {
        newSetting.sortType = FACET_SORTING.FACET_SORT_TYPE_VALUES
      }

      this.setFacetGroupOrderSetting({
        group: this.fieldName,
        setting: newSetting
      })

      this.resetVisibleFacetsAmount()
    },
    toggleSortOrder () {
      const newSetting = {
        sortType: this.facetGroupOrderSettings.sortType,
        sortOrder: this.facetGroupOrderSettings.sortOrder
      }

      if (this.facetGroupOrderSettings.sortOrder === FACET_SORTING.FACET_SORT_ORDER_ASCENDING) {
        newSetting.sortOrder = FACET_SORTING.FACET_SORT_ORDER_DESCENDING
      } else {
        newSetting.sortOrder = FACET_SORTING.FACET_SORT_ORDER_ASCENDING
      }

      this.setFacetGroupOrderSetting({
        group: this.fieldName,
        setting: newSetting
      })

      this.resetVisibleFacetsAmount()
    },
    resetVisibleFacetsAmount () {
      this.visibleFacetsAmount = defaultFacetsVisible
    },
    moreVisibleFacets () {
      if (this.visibleFacetsAmount < this.facets.length) {
        this.visibleFacetsAmount += VISIBLE_FACETS_STEP
      }
    },
    lessVisibleFacets () {
      if (this.visibleFacetsAmount > defaultFacetsVisible) {
        this.visibleFacetsAmount -= VISIBLE_FACETS_STEP
      }
    },
    onFacetToggle (facet, active) {
      this.$emit('toggle', {
        group: this.fieldName,
        value: facet.key,
        active
      })
    },
    processFacets () {
      this.sortedFacets = sortFacets(this.facets, this.activeFacets, this.facetGroupOrderSettings)
    },
    toggleCollapse () {
      this.collapsed = !this.collapsed
    }
  },
  components: {
    'module-facet': ModuleFacet
  },
  mounted () {
    this.processFacets()
  }
}
</script>

<style lang="scss" scoped>
  .facetgroup-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .facetgroup-name {
      display: block;
      width: 100%;
      cursor: pointer;

      .facetgroup-collapsetoggle {
        display: inline-block;
        width: 10px;
      }
    }

    .facetgroup-settingcontainer {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-direction: row;
      align-items: center;
    }

    .facetgroup-setting {
      padding: 6px;
      cursor: pointer;

      img {
        display: block;
      }
    }
  }

  .facetgroup-content {
    padding-left: 6px;
  }

  .facetgroup-contentcontrols {
    padding-top: 10px;

    .facetgroup-amountcontrol {
      border: none;
      background: none;
      cursor: pointer;
      opacity: 0.5;
      padding: 4px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .arrow {
    $width: 12px;
    $height: 10px;

    width: $width;
    height: $height;

    &.arrow-down::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: $height solid black;
      border-right: ($width/2) solid transparent;
      border-left: ($width/2)solid transparent;
      border-bottom: none;
    }

    &.arrow-up::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-bottom: $height solid black;
      border-right: ($width/2) solid transparent;
      border-left: ($width/2)solid transparent;
      border-top: none;
    }
  }
</style>
