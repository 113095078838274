<template>
  <transition name="modal-fade">
    <div v-if="show" class="modal modal-component">
      <div class="modal modal-background" @click="closeWithoutAction">
        <div class="modal modal-container" :class="'modal-type-' + options.type" @click.stop="">
          <div class="modal modal-ribbon">
            <img class="modal modal-ribbon-icon" v-if="options.icon != ''" :src="'/img/icons/modal/' + options.icon" />
          </div>
          <div class="modal modal-content">
            <div class="modal modal-title">{{ $t(options.title) }}</div>
            <div class="modal modal-body" v-html="this.$t(options.body)"></div>
            <div class="modal modal-actions">
              <button v-for="(action, index) in options.actions" class="modal modal-action input-button" :class="action.class? action.class : ''" @click="executeAction(index)" :key="action.label">{{ $t(action.label) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Events } from '@/events'

export default {
  name: 'modal',
  data () {
    return {
      show: false,
      options: {
        type: 'info',
        title: '',
        body: '',
        actions: [],
        requireAction: false
      }
    }
  },
  methods: {
    // opens the modal
    openModal () {
      this.show = true
    },

    // closes the modal
    closeModal () {
      this.show = false
    },

    // Sets the given object with options. When a key is missing, it sets a default
    setModalOptions (newOptions) {
      this.options.type = newOptions.type ? newOptions.type : 'info'
      this.options.icon = newOptions.icon ? newOptions.icon : ''
      this.options.title = newOptions.title ? newOptions.title : ''
      this.options.body = newOptions.body ? newOptions.body : ''
      this.options.actions = (newOptions.actions && Array.isArray(newOptions.actions)) ? newOptions.actions : []
      this.options.requireAction = newOptions.requireAction ? newOptions.requireAction : false
    },

    // When a button is clicked, it executes the function as given in the options
    executeAction (index) {
      if (typeof this.options.actions[index].action === 'function') this.options.actions[index].action()
    },

    // Determins of the modal can be closed by clicking the background or pressing escape. If so, closes the modal
    closeWithoutAction () {
      if (!this.options.requireAction) {
        this.closeModal()
      }
    }
  },
  mounted () {
    const _this = this

    Events.$on('modalOpen', (options = {}) => {
      /*
        Available options:
        - type: [string] determines color of the ribbon | info, error
        - icon: [string] filename of the icon to show located in /public/img/icons/modal
        - title: [string] title to display in the modal
        - body: [string] body to display in the modal, can be html
        - actions: [array of objects] array of actions (buttons) to display
            > actions are defined as {label: 'label in button', class: 'additional classes', action: function(){functionality when clicked)}
        - requireAction: [boolean] modal can not be closed by clicking on background or pressing escape
       */
      this.setModalOptions(options)
      this.openModal()
    })
    Events.$on('modalClose', () => {
      this.closeModal()
    })

    document.addEventListener('keyup', (event) => {
      if (event.which === 27 && _this.show) {
        _this.closeWithoutAction()
      }
    })
  }
}
</script>

<style>

</style>
