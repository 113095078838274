<template>
  <div class="popup-background">
    <div class="popup-window">
      <div class="popup-header">
        <h1 class="popup-title">{{ capitalize($t('bookshelf.deleteBookshelf')) }}</h1>
      </div>
      <div v-if="validationError" class="form-row">
        <div class="validationerror-block">{{ validationError }}</div>
      </div>
      <div class="popup-form">
        <div class="form-row">
          <div>{{ capitalize($t('bookshelf.deleteBookshelfConfirmation', { bookshelfName: name })) }}</div>
        </div>
      </div>
      <div class="form-row">
        <button @click="close" class="input-button" :disabled="this.sending">{{ capitalize($t('actions.cancel')) }}</button>
        <button @click="send" class="input-button input-button-primary" :disabled="this.sending">{{ capitalize($t('actions.delete')) }}</button>
      </div>
      <div class="popup-close" @click="close">&#10006;</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-bookshelf-delete',
  emits: ['close'],
  data: () => ({
    name: '',
    id: null,
    validationError: null,
    sending: false
  }),
  methods: {
    reset () {
      this.validationError = null
      this.sending = false
      this.name = ''
    },
    load (bookshelfData = null) {
      this.reset()

      if (!bookshelfData.id) this.close()

      this.id = bookshelfData.id
      this.name = bookshelfData.name
    },
    async send () {
      if (this.sending) return
      if (!this.id) return

      this.validationError = null
      this.sending = true

      const result = await this.$store.dispatch('bookshelfStore/deleteBookshelf', this.id)

      if (result.error) {
        this.sending = false
        this.validationError = result.error
        return false
      }

      await this.$store.dispatch('bookshelfStore/fetchUserBookshelves')
      if (parseInt(this.$route.params.id) === this.id) {
        const userBookshelves = this.$store.getters['bookshelfStore/getUserBookshelves']
        if (userBookshelves.length) {
          this.$router.push(`/bookshelf/${userBookshelves[0].id}`)
        }
      }

      this.sending = false
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-row {
    margin: 10px 0;

    label {
      display: block;
    }
  }

  .input-fullwidth {
    width: 100%;
  }

  .validationerror-block {
    padding: 10px;
    border: 1px solid red;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
  }
</style>
