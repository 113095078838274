<template>
  <input type="checkbox" v-model="status" :disabled="disabled" :id="toggleId" />
  <label class="toggle" :class="{ 'toggle-active': status, 'toggle-disabled': disabled }" :for="toggleId"></label>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  name: 'toggle',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    status: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) }
    }
  },
  data: () => ({
    toggleId: nanoid()
  })
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";
  $toggleHeight: 24px;

  input[type="checkbox"] {
    position: absolute;
    left: -99999999px;

    & + .toggle {
      display: inline-block;
      height: $toggleHeight;
      width: 40px;
      background: $grey_middle;
      border-radius: $toggleHeight / 2;
      vertical-align: middle;
      cursor: pointer;

      transition: background 0.2s;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 4px;
        height: calc($toggleHeight - 10px);
        width: calc($toggleHeight - 10px);
        background-color: white;
        border: 1px solid transparent;
        border-radius: 50%;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
        transition: left 0.2s, right 0.2s;
      }

      &-active {
        background: $main_light;

        &::after {
          left: auto;
          right: 4px;
        }
      }

      &:not(&-disabled):hover {
        &::after {
          border-color: black;
        }
      }

      &-disabled {
        cursor: default;
        &::after {
          background-color: $grey_dark
        }
      }
    }

    &:focus, &:focus {
      & + .toggle {
        &::after {
          border-color: black;
        }
      }
    }
  }
</style>
