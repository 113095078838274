<template>
  <div class="as-container" :class="embed ? '' : 'as-container-floating'">
    <div class="flex-row">
      <div class="flex-item-expand">
        <input
          type="text"
          v-model="query"
          class="input-text"
          style="width: 100%"
          ref="queryInput"
          @keyup.enter="search"
          :placeholder="capitalize($t('search')) + '...'"
          />
      </div>
      <div class="flex-item-constant">{{ $t('in') }}</div>
      <div class="flex-item-constant">
        <custom-select v-model="searchContext" :options="searchContextOptions"></custom-select>
      </div>
      <div class="flex-item-constant" style="width: 40px;"></div>
      <div class="flex-item-constant" v-if="!embed">
        <button class="input-button" @click="closePanel">{{ capitalize($t('close')) }}</button>
      </div>
    </div>
    <div v-for="field in fields" :key="field.key" class="flex-row">
      <div class="flex-item-constant">
        <custom-select v-model="field.relation" :options="fieldRelationOptions"></custom-select>
      </div>
      <div class="flex-item-constant">
        <custom-select v-model="field.name" :options="fieldNameOptions"></custom-select>
      </div>
      <div class="flex-item-constant">
        <custom-select v-model="field.mode" :options="fieldModeOptions"></custom-select>
      </div>
      <div class="flex-item-expand">
        <input v-model="field.query" type="text" class="input-text" style="width: 100%" @keyup.enter="search" />
      </div>
      <div class="flex-item-constant">
        <button class="input-button input-button-danger" @click="removeFieldRow(field.key)" :title="capitalize($t('remove filter'))">&#9587;</button>
      </div>
    </div>
    <div class="flex-row">
      <div class="flex-item-constant">
        <button class="input-button" @click="addFieldRow">{{ capitalize($t('add filter')) }}</button>
      </div>
    </div>
    <div class="flex-row">
      <div class="flex-item-constant">{{ capitalize($t('sort by')) }}</div>
      <div class="flex-item-constant">
        <custom-select v-model="sortField" :options="sortFieldOptions"></custom-select>
      </div>
      <div class="flex-item-constant">
        <custom-select v-model="sortOrder" :options="sortOrderOptions"></custom-select>
      </div>
    </div>
    <div class="flex-row flex-row-right">
      <div class="flex-item-constant">
        <button class="input-button input-button-primary" @click="search">{{ capitalize($t('search')) }}</button>
      </div>
      <div class="flex-item-constant">
        <button class="input-button input-button-danger" @click="reset">{{ capitalize($t('reset')) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/form-elements/CustomSelect'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'advanced-search-panel',
  emits: ['close', 'search'],
  expose: ['focusQueryInput'],
  props: {
    embed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('searchStore', {
      searchContextOptions: state => state.config.searchOptions.searchContextOptions,
      sortFieldOptions: state => state.config.sortOptions.sortFieldOptions,
      sortOrderOptions: state => state.config.sortOptions.sortOrderOptions,
      fieldRelationOptions: state => state.config.advancedSearchFieldOptions.advancedSearchFieldRelationOptions,
      // fieldNameOptions: state => state.config.advancedSearchFieldOptions.advancedSearchFieldNameOptions,
      fieldModeOptions: state => state.config.advancedSearchFieldOptions.advancedSearchFieldModeOptions
    }),
    ...mapState('searchStore/currentSearch', {
      fields: state => state.fields
    }),
    query: {
      get () { return this.$store.state.searchStore.currentSearch.query },
      set (value) { this.$store.dispatch('searchStore/currentSearch/setQuery', value) }
    },
    searchContext: {
      get () { return this.$store.state.searchStore.currentSearch.searchContext },
      set (value) { this.$store.dispatch('searchStore/currentSearch/setSearchContext', value) }
    },
    sortField: {
      get () { return this.$store.state.searchStore.currentSearch.sortField },
      set (value) { this.$store.dispatch('searchStore/currentSearch/setSortField', value) }
    },
    sortOrder: {
      get () { return this.$store.state.searchStore.currentSearch.sortOrder },
      set (value) { this.$store.dispatch('searchStore/currentSearch/setSortOrder', value) }
    },
    fieldNameOptions () {
      return this.$store.getters['searchStore/getAdvancedFieldOptions']
    }
  },
  methods: {
    ...mapActions('searchStore/currentSearch', {
      addFieldRow: 'addField',
      removeFieldRow: 'removeField'
    }),
    closePanel () {
      this.forceAlterCheck()
      this.$emit('close')
    },
    search () {
      this.forceAlterCheck()
      this.$store.dispatch('searchStore/search', true)
      this.$emit('search')
    },
    reset () {
      this.$store.dispatch('searchStore/currentSearch/newSearch')
    },
    forceAlterCheck () {
      this.$store.dispatch('searchStore/currentSearch/onAlter')
    },
    focusQueryInput () {
      this.$refs.queryInput.focus()
    }
  },
  components: {
    'custom-select': CustomSelect
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .as-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;

    &-floating {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $advanced-searchpanel-padding;
    gap: $advanced-searchpanel-padding;

    &.flex-row-right {
      flex-direction: row-reverse;
    }
  }

  .flex-item-expand {
    flex-grow: 1;
  }

  .flex-item-constant {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .flex-item-spacer {
    width: $advanced-searchpanel-padding;
  }
</style>
