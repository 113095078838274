<template>
  <div class="aggregate">
    <module-aggregate-shortlist
      v-for="module of modules"
      :key="module.connection"
      :connectionId="module.connection"
      @navigateToTab="$emit('navigateToTab', module.connection)"
      @select="publicationInfo => $emit('select', publicationInfo)"
    ></module-aggregate-shortlist>
  </div>
</template>

<script>
import ModuleAggregateShortlist from '@/components/module-aggregate/ModuleAggregateShortlist'

export default {
  name: 'module-aggregate-view',
  props: ['modules'],
  emits: ['select', 'navigateToTab'],
  components: {
    'module-aggregate-shortlist': ModuleAggregateShortlist
  }
}
</script>

<style lang="scss" scoped>
  .aggregate {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
