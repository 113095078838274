export default function (amountInCents, upperCaseCurrency) {
  if (typeof amountInCents === 'number' && !isNaN(amountInCents)) {
    if (upperCaseCurrency.toLowerCase().trim() === 'eur') {
      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: upperCaseCurrency.toLowerCase().trim() }).format(amountInCents / 100)
    }
    if (upperCaseCurrency.toLowerCase().trim() === 'gbp') {
      return new Intl.NumberFormat('en-EN', { style: 'currency', currency: upperCaseCurrency.toLowerCase().trim() }).format(amountInCents / 100)
    }
  }

  return ''
}
