import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import capitalize from './mixins/capitalize'
import currencyFilter from './filters/CurrencyFilter'
import runOnBlur from './directives/runOnBlur'

import { Events } from './events'

global.eventBus = Events

const app = createApp(App)

app.config.globalProperties.$filters = {
  currency: currencyFilter
}

app.mixin(capitalize)

app.directive('run-on-blur', runOnBlur)

app.use(i18n).use(store).use(router).mount('#app')
