import axios from 'axios'
import store from '@/store'
import VueCookies from 'vue-cookies'
import { Events } from '@/events'
import router from '@/router'

const RETRY_MAXCOUNT = 3
const RETRY_DELAY = 100

const axiosAPI = axios.create({
  baseURL: window.globals.VUE_APP_APIBASE
})

axiosAPI.interceptors.request.use(
  config => {
    const auth = store.getters.getAuth

    if (auth.accessToken) {
      config.headers.Authorization = 'Bearer ' + auth.accessToken
    }

    if (typeof config.retryCount === 'undefined') {
      config.retryCount = 0
    }

    return config
  },
  error => Promise.reject(error)
)

axiosAPI.interceptors.response.use(
  response => response,
  (error) => {
    const originalRequest = error.config

    function showInvalidTokenModal () {
      Events.$emit('modalOpen', {
        type: 'error',
        icon: 'nodoc.png',
        title: 'Authentication could not be refreshed',
        body: 'The authentication with the server has expired and could not be refreshed. Please log in again to continue using the application.',
        actions: [
          {
            label: 'Log in',
            class: 'input-button-primary',
            action () {
              // redirect to SSO login page
              const route = router.currentRoute
              let SSO_URL = `${window.globals.VUE_APP_SSOURL}/application/${window.globals.VUE_APP_SSOAPPID}`

              if (route.name === 'publicationloader' && route.params.pubKey) SSO_URL += '&pubKey=' + route.params.pubKey
              if (route.name === 'reader' && route.params.docKey) SSO_URL += '&docKey=' + route.params.docKey

              window.location = SSO_URL
            }
          }
        ],
        requireAction: true
      })
    }

    if (!error.response) {
      return new Promise((resolve, reject) => {
        Events.$emit('modalOpen', {
          type: 'error',
          icon: 'server.png',
          title: 'Server could not be reached',
          body: 'There was a problem establishing a connection to the server. Please try again.',
          actions: [
            {
              label: 'Try again',
              class: 'input-button-primary',
              action () {
                Events.$emit('modalClose')

                // send try the same request again
                resolve(axiosAPI.request(originalRequest))
              }
            }
          ],
          requireAction: true
        })
      })
    }

    if (error.response.status === 502 || error.response.status === 503) {
      return new Promise((resolve, reject) => {
        let retryCount = originalRequest.retryCount
        if (retryCount >= RETRY_MAXCOUNT) {
          reject(error)
        } else {
          retryCount++
          originalRequest.retryCount = retryCount
          setTimeout(() => {
            resolve(axiosAPI.request(originalRequest))
          }, retryCount * RETRY_DELAY)
        }
      })
    }

    if (error.response.status === 401) {
      return new Promise((resolve, reject) => {
        // 401 received, need to refresh token
        const currentAuth = store.getters.getAuth

        if (currentAuth.refreshToken) {
          axios.get(`${window.globals.VUE_APP_SSOURL}/application/${window.globals.VUE_APP_SSOAPPID}`, {
            params: {
              appName: window.globals.VUE_APP_SSOAPPNAME,
              client: window.globals.VUE_APP_SSOCLIENT,
              secret: window.globals.VUE_APP_SSOSECRET,
              refresh_token: currentAuth.refreshToken
            }
          })
            .then(({ data }) => {
              if (data.access_token) {
                store.state.auth.accessToken = data.access_token
                VueCookies.set('accessToken', data.access_token)

                if (data.refresh_token) {
                  store.state.auth.refreshToken = data.refresh_token
                  VueCookies.set('refreshToken', data.refresh_token)
                }

                // token updated, send original request again
                resolve(axiosAPI.request(originalRequest))
              } else {
                showInvalidTokenModal()
              }
            })
            .catch(error => {
              console.log(error)
              showInvalidTokenModal()
            })
        } else {
          // could not refresh token, show modal to go login again
          showInvalidTokenModal()
        }
      })
    }

    return Promise.reject(error)
  }
)

export default axiosAPI
