import { localStorageLanguageKey } from '@/constants/defaults'
import store from '@/store'

const getInitialLanguage = () => {
  const availableLanguages = store.getters.getLanguages

  const storedLanguage = localStorage.getItem(localStorageLanguageKey)
  if (storedLanguage && availableLanguages.includes(storedLanguage)) return storedLanguage

  const clientSettings = store.getters['settings/getClientSettings']
  const clientDefaultLanguage = clientSettings.defaultLanguage
  if (clientDefaultLanguage && availableLanguages.includes(clientDefaultLanguage)) return clientDefaultLanguage

  const browserLanguage = window.navigator.language
  if (browserLanguage) {
    const shortName = browserLanguage.substring(0, 2)
    if (availableLanguages.includes(shortName)) return shortName
  }

  return process.env.VUE_APP_I18N_LOCALE || 'nl'
}

const getLanguageFlag = (language) => {
  switch (language) {
    case 'nl':
      return '🇳🇱'
    case 'en':
      return '🇬🇧'
    case 'de':
      return '🇩🇪'
    case 'fr':
      return '🇫🇷'
    default:
      return '🌐'
  }
}

const generateLanguageSelectOptions = (languages) => {
  const options = []

  for (const language of languages) {
    const flag = getLanguageFlag(language)
    options.push({ flag: flag, key: language })
  }

  return options
}

export {
  getInitialLanguage,
  getLanguageFlag,
  generateLanguageSelectOptions
}
