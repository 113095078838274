<template>
  <div class="admin-container">
    <div v-if="loading_state === LOADING_STATES.LOADING" class="admin-loading">
      <spinner :size="60" :width="6"></spinner>
    </div>
    <div class="admin-menu">
      <div class="admin-menu-title">{{ capitalize($t('admin.title')) }}</div>
      <div v-if="clientSettingsModified" class="admin-menu-controls">
        <button type="button" class="input-button input-button-primary" @click="save">{{ capitalize($t('save')) }}</button>
        <button type="button" class="input-button input-button-outlined" @click="reset">{{ capitalize($t('cancel')) }}</button>
      </div>
      <button
        type="button"
        class="admin-menu-tab"
        :class="{ 'admin-menu-tab-active': activeTab === 'general' }"
        @click="switchTab('general')"
        >
          <img src="/img/icons/reader_settings.png" width="20" height="20" alt="general" />
          <div>{{ capitalize($t('admin.general')) }}</div>
      </button>
      <button
        type="button"
        class="admin-menu-tab"
        :class="{ 'admin-menu-tab-active': activeTab === 'search' }"
        @click="switchTab('search')"
        >
          <img src="/img/icons/reader_search.png" width="20" height="20" alt="search" />
          <div>{{ capitalize($t('admin.search')) }}</div>
      </button>
    </div>
    <div class="admin-sectioncontainer">
      <div v-if="activeTab === 'general'" class="admin-section">
        <div class="admin-section-header"><span>{{ capitalize($t('language')) }}</span></div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.defaultLanguage')) }}</div>
          <div class="admin-section-row-input">
            <custom-select class="admin-input-select" v-model="defaultLanguage" :options="languageOptions"></custom-select>
          </div>
        </div>
        <div class="admin-section-header"><span>{{ capitalize($t('admin.logo')) }}</span></div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.changeLogo')) }}</div>
          <div class="admin-section-row-input">
            <image-upload
              @save="onLogoSave"
              ref="logo-upload"
              :preview-height="26"
              />
            <div v-if="$store.state.settings.client.logo" style="margin-top: 10px;">
              <button
                class="input-button input-button-danger"
                @click="deleteLogo"
                >
                {{ capitalize($t('admin.deleteLogo')) }}
              </button>
            </div>
          </div>
        </div>
        <div class="admin-section-header"><span>{{ capitalize($t('admin.informationText')) }}</span></div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.showInformationText')) }}</div>
          <div class="admin-section-row-input">
            <toggle v-model="enableInfotext" />
          </div>
        </div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.informationText')) }}</div>
          <div class="admin-section-row-input">
            <rich-text-editor
              class="informationtext"
              v-model:content="infotext"
              :readonly="false"
              />
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'search'" class="admin-section">
        <div class="admin-section-header"><span>{{ capitalize($t('admin.advancedSearch')) }}</span></div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.showAdvancedSearchOnHome')) }}</div>
          <div class="admin-section-row-input">
            <toggle v-model="showAdvancedSearchOnHome" />
          </div>
        </div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.defaultAdvancedSearchFields')) }}</div>
          <div class="admin-section-row-input">
            <ordered-list v-model="defaultAdvancedSearchFields" :options="advancedFieldNameOptions" :maxItemCount="5"/>
          </div>
        </div>
        <div class="admin-section-header"><span>{{ capitalize($t('admin.searchDefaults')) }}</span></div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.searchContext')) }}</div>
          <div class="admin-section-row-input">
            <custom-select class="admin-input-select" v-model="defaultSearchContext" :options="searchOptions.searchContextOptions" />
          </div>
        </div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.resultSorting')) }}</div>
          <div class="admin-section-row-input">
            <div class="admin-input-group">
              <custom-select class="admin-input-select" v-model="defaultSearchSortField" :options="sortOptions.sortFieldOptions"></custom-select>
              <custom-select class="admin-input-select" v-model="defaultSearchSortOrder" :options="sortOptions.sortOrderOptions"></custom-select>
            </div>
          </div>
        </div>
        <div class="admin-section-header"><span>{{ capitalize($t('admin.searchResultList')) }}</span></div>
        <div class="admin-section-row">
          <div class="admin-section-row-label">{{ capitalize($t('admin.showCopyDocuments')) }}</div>
          <div class="admin-section-row-input">
            <toggle v-model="showCopyDocuments" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from '@/components/form-elements/Toggle'
import RichTextEditor from '@/components/form-elements/RichTextEditor'
import ImageUpload from '@/components/form-elements/ImageUpload'
import CustomSelect from '@/components/form-elements/CustomSelect'
import OrderedList from '@/components/form-elements/OrderedList'
import Spinner from '@/components/utilities/Spinner'
import STATES from '@/constants/states'

import searchOptions from '@/store/config/searchOptions'
import sortOptions from '@/store/config/sortOptions'

export default {
  name: 'admin-area',
  data: () => ({
    LOADING_STATES: STATES,
    loading_state: STATES.READY,
    activeTab: 'general',
    sortOptions: sortOptions,
    searchOptions: searchOptions
  }),
  computed: {
    clientSettingsModified () { return this.$store.getters['settings/isClientSettingsModified'] },
    defaultLanguage: {
      get () { return this.$store.getters['settings/getClientModifySetting']('defaultLanguage') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'defaultLanguage', value }) }
    },
    enableInfotext: {
      get () { return this.$store.getters['settings/getClientModifySetting']('enableInfotext') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'enableInfotext', value }) }
    },
    infotext: {
      get () { return this.$store.getters['settings/getClientModifySetting']('infotext') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'infotext', value }) }
    },
    showCopyDocuments: {
      get () { return this.$store.getters['settings/getClientModifySetting']('showCopyDocuments') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'showCopyDocuments', value }) }
    },
    defaultSearchContext: {
      get () { return this.$store.getters['settings/getClientModifySetting']('defaultSearchContext') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'defaultSearchContext', value }) }
    },
    defaultSearchSortField: {
      get () { return this.$store.getters['settings/getClientModifySetting']('defaultSearchSortField') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'defaultSearchSortField', value }) }
    },
    defaultSearchSortOrder: {
      get () { return this.$store.getters['settings/getClientModifySetting']('defaultSearchSortOrder') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'defaultSearchSortOrder', value }) }
    },
    showAdvancedSearchOnHome: {
      get () { return this.$store.getters['settings/getClientModifySetting']('showAdvancedSearchOnHome') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'showAdvancedSearchOnHome', value }) }
    },
    defaultAdvancedSearchFields: {
      get () { return this.$store.getters['settings/getClientModifySetting']('defaultAdvancedSearchFields') },
      set (value) { this.$store.commit('settings/setClientModifySetting', { key: 'defaultAdvancedSearchFields', value }) }
    },
    languageOptions () {
      const availablelanguages = this.$i18n.availableLocales
      const languageOptions = availablelanguages.map(langKey => ({ label: `languages.${langKey}`, value: langKey }))
      return languageOptions
    },
    advancedFieldNameOptions () {
      return this.$store.getters['searchStore/getAdvancedFieldOptions']
    }
  },
  methods: {
    switchTab (tab) {
      this.activeTab = tab
    },
    async save () {
      this.loading_state = this.LOADING_STATES.LOADING
      await this.$store.dispatch('settings/saveModifiedClientSettings')
      this.loading_state = this.LOADING_STATES.READY
    },
    reset () {
      this.$store.commit('settings/resetClientModifySettings')
    },
    async onLogoSave (uploadedFile) {
      this.loading_state = this.LOADING_STATES.LOADING
      await this.$store.dispatch('settings/saveClientLogo', uploadedFile)
      if (this.$refs['logo-upload']?.reset) this.$refs['logo-upload']?.reset()
      this.loading_state = this.LOADING_STATES.READY
    },
    async deleteLogo () {
      this.loading_state = this.LOADING_STATES.LOADING
      await this.$store.dispatch('settings/deleteClientLogo')
      if (this.$refs['logo-upload']?.reset) this.$refs['logo-upload']?.reset()
      this.loading_state = this.LOADING_STATES.READY
    }
  },
  components: {
    toggle: Toggle,
    'rich-text-editor': RichTextEditor,
    'image-upload': ImageUpload,
    'custom-select': CustomSelect,
    'ordered-list': OrderedList,
    spinner: Spinner
  }
}
</script>

<style lang="scss" scoped>
  @use "sass:color";
  @import "../assets/css/_constants";

  $menu_tab_padding: 10px;
  $menu_tab_border: 4px;

  .admin {
    &-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100% - #{$header_height});
      box-shadow: 0 4px 4px -4px inset rgba(0, 0, 0, 0.4);
      border-top: 1px solid transparent;
    }

    &-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;
    }

    &-menu {
      flex-shrink: 0;
      flex-grow: 0;
      height: 100%;
      width: 240px;
      padding: 20px;

      &-title {
        font-weight: bold;
        font-size: ($font_size + 4);
        margin-bottom: 10px;
      }

      &-controls {
        margin-bottom: 10px;
      }

      &-tab {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        width: 100%;
        border: none;
        background: none;
        font-family: $font;
        font-size: $font_size;
        text-align: left;
        padding: $menu_tab_padding;
        cursor: pointer;
        outline: 0;

        &-active {
          padding-left: $menu_tab_padding - $menu_tab_border;
          border-left: $menu_tab_border solid $main_light;
          background-color: color.change($main_light, $alpha: 0.2);
        }

        &:not(&-active):focus, &:not(&-active):hover {
          padding-left: $menu_tab_padding - $menu_tab_border;
          border-left: $menu_tab_border solid $grey_dark;
        }
      }
    }

    &-sectioncontainer {
      flex-shrink: 0;
      flex-grow: 1;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &-input {
      &-group {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;

        &>* {
          flex-shrink: 0;
        }
      }

      &-select {
        margin-top: -10px;
      }
    }
  }

  .admin-section {
    width: 100%;
    max-width: 800px;
    padding: 20px;

    &-header {
      margin-bottom: 8px;

      &:not(:first-child) {
        margin-top: 8px;
      }

      & > span {
        font-weight: bold;
        font-size: ($font_size + 4);
        background-color: white;
        padding-right: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        border-bottom: 1px solid #ccc;
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      margin: 10px 0;

      &-label {
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
        margin-right: 20px;
      }
    }
  }

  .informationtext {
    width: $informationtext_width;
  }
</style>
