<template>
  <div class="home">
    <div class="home-inner">
      <div class="hero">
        <div class="section-logo">
          <div v-if="$store.state.environmentLabel" class="environment-label">{{ $store.state.environmentLabel }}</div>
          <img class="logo" :src="logoUrl" alt="logo" />
        </div>
        <div v-if="$store.state.initialised && !clientSettings.changeHomeLogo" class="home-environment">{{$store.getters.getSession.client.current.name}}</div>
        <div class="section-searchbar">
          <div class="searchbar-container" v-if="!embedAdvancedSearch">
            <div class="searchbar-wrapper">
              <input
                type="text"
                id="searchbar"
                class="input-text"
                ref="queryInput"
                v-model="query"
                @keyup.enter="search"
                :placeholder="capitalize($t('search')) + '...'"
              />
              <div
                v-show="advancedSearchAltered"
                class="searchbar-advancedlabel"
                :title="capitalize($t('There are filled advanced fields. Click to open the advanced search panel.'))"
                @click="openAdvancedSearchPanel"
              ></div>
            </div>
            <button class="input-button input-button-primary" :title="capitalize($t('search'))" @click="search">
              <img src="/img/icons/search_white.png">
            </button>
          </div>
          <div class="searchbar-actions" v-if="!embedAdvancedSearch">
            <button class="input-button" @click="openAdvancedSearchPanel">{{ capitalize($t('advanced')) }}</button>
          </div>
          <div
            v-if="showAdvancedSearchPanel"
            class="advancedsearch-container"
            :class="embedAdvancedSearch ? 'advancedsearch-container-embedded' : 'advancedsearch-container-floating'"
          >
            <advanced-search-panel
              @close="closeAdvancedSearchPanel"
              @search="afterSearch"
              :embed="embedAdvancedSearch"
              ref="searchpanel"
            ></advanced-search-panel>
          </div>
        </div>
      </div>
      <div class="acquisitions-section">
        <acquisitions @select="onSelectAcquisition"></acquisitions>
      </div>
    </div>
    <Transition name="paneltransition">
      <module-result-detail
        v-show="detailPanelOpen"
        ref="detailLoader"
        :actionAddToBookshelf="true"
        @close="closeDetailPanel"
      ></module-result-detail>
    </Transition>
  </div>
</template>

<script>
import AdvancedSearchPanel from '@/components/search/AdvancedSearchPanel'
import Acquisitions from '@/components/acquisitions/Acquisitions'
import ModuleResultDetail from '@/components/module-results/ModuleResultDetail'

export default {
  name: 'home',
  computed: {
    query: {
      get () { return this.$store.state.searchStore.currentSearch.query },
      set (value) { this.$store.commit('searchStore/currentSearch/setQuery', value) }
    },
    advancedSearchAltered () {
      return this.$store.state.searchStore.currentSearch.altered
    },
    clientSettings () {
      return this.$store.getters['settings/getClientSettings']
    },
    logoUrl () {
      if (this.clientSettings?.changeHomeLogo) {
        const clientLogoUrl = this.$store.getters['settings/getClientLogo']
        if (clientLogoUrl) return clientLogoUrl
      }

      return '/img/hl-logo-full.svg'
    },
    embedAdvancedSearch () {
      return this.clientSettings?.showAdvancedSearchOnHome
    },
    showAdvancedSearchPanel () {
      if (this.embedAdvancedSearch) return true
      return this.advancedSearchOpen
    }
  },
  data: () => ({
    advancedSearchOpen: false,
    detailPanelOpen: false
  }),
  methods: {
    search () {
      this.$store.dispatch('searchStore/search', true)
      this.afterSearch()
    },
    afterSearch () {
      this.closeAdvancedSearchPanel()
      this.$store.commit('searchStore/setActiveResultTab', 'all')
      this.$router.push({ name: 'results' })
    },
    async focusSearchInput () {
      if (this.embedAdvancedSearch) {
        if (this.$refs.searchpanel) {
          await this.$nextTick()
          this.$refs.searchpanel.focusQueryInput()
          return
        }
      }

      if (this.$refs.queryInput) this.$refs.queryInput.focus()
    },
    async openAdvancedSearchPanel () {
      this.advancedSearchOpen = true
      await this.$nextTick()
      if (this.$refs.searchpanel) this.$refs.searchpanel.focusQueryInput()
    },
    closeAdvancedSearchPanel () {
      this.advancedSearchOpen = false
    },
    openDetailPanel () {
      this.detailPanelOpen = true
    },
    closeDetailPanel () {
      this.detailPanelOpen = false
    },
    onSelectAcquisition (acquisitionInfo) {
      if (!this.$refs.detailLoader?.loadPublication) return

      this.$refs.detailLoader.loadPublication(acquisitionInfo)
      this.openDetailPanel()
    }
  },
  components: {
    'advanced-search-panel': AdvancedSearchPanel,
    acquisitions: Acquisitions,
    'module-result-detail': ModuleResultDetail
  },
  mounted () {
    this.focusSearchInput()
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/css/_constants";

  .home {
    width: 100%;
    height: calc(100% - #{$header_height});
    box-shadow: 0 4px 4px -4px inset rgba(0, 0, 0, 0.4);
    border-top: 1px solid transparent;

    &-environment {
      margin-top: 2px;
      font-size: calc($font_size + 2px);
    }
  }

  .home-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 140px;
    margin-bottom: 20px;
  }

  .environment-label {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #999;
    padding: 4px 6px;
    border-radius: 4px;
    background: white;
    z-index: 1;
    font-weight: bold;
  }

  .logo {
    width: 300px;
  }

  .section-searchbar {
    margin-top: 40px;

    .searchbar-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    #searchbar {
      width: 300px;
    }

    .searchbar-actions {
      text-align: right;
      margin-top: 4px;
    }

    .advancedsearch-container {
      width: 670px;

      &-floating {
        position: absolute;
        top: -$advanced-searchpanel-padding;
        left: -$advanced-searchpanel-padding;
        z-index: 1;
      }

      &-embedded {
        margin-bottom: 20px;
      }
    }
  }

  .acquisitions-section {
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;
  }

  .paneltransition {
    &-enter-from, &-leave-to {
      right: -500px;
    }

    &-enter-active {
      transition: right 0.5s ease;
    }

    &-leave-active {
      transition: right 0.2s ease;
    }
  }

  .searchbar-wrapper {
    display: inline-block;
    margin-right: 4px;

    .searchbar-advancedlabel {
      position: absolute;
      right: 6px;
      top: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background: $main_light;
      border-radius: 50%;
    }
  }
</style>
