import ModuleActions from '@/constants/moduleActions'
import { parse as parseDate, differenceInDays } from 'date-fns'

export default {
  data: () => ({
    daysMarkedAsNew: 14
  }),
  computed: {
    module () {
      return this.$store.getters['searchStore/searchModules/getModule'](this.connectionId)
    },
    hasReadAction () {
      const moduleHasReadAction = this.module.actions?.includes(ModuleActions.READ) ?? false
      if (!moduleHasReadAction) return false
      return this.hasLicense
    },
    hasRequestAction () {
      const moduleHasRequestAction = this.module.actions?.includes(ModuleActions.REQUEST) ?? false
      if (!moduleHasRequestAction) return false
      return !!(this.$store.getters.getClient?.email ?? false)
    },
    hasShopAction () {
      const moduleHasShopAction = this.module.actions?.includes(ModuleActions.SHOP) ?? false
      if (!moduleHasShopAction) return false
      return !!(this.publication.price)
    },
    hasOpenInBibisAction () {
      const moduleHasOpenInBibisAction = this.module.actions?.includes(ModuleActions.OPEN_IN_BIBIS) ?? false
      if (!moduleHasOpenInBibisAction) return false
      return !!(this.$store.getters.userHasAdminRole)
    },
    priceDisplay () {
      if (this.hasShopAction) return this.$filters.currency(this.publication.price, this.publication.currency)
      return null
    },
    isNew () {
      if (!this.publication.created && !this.publication.licenseCreated) return false

      const createdDateString = this.publication.created || this.publication.licenseCreated
      const validCreatedDateString = createdDateString.includes('T') ? createdDateString.replace('T', ' ') : createdDateString
      const bookCreatedDate = parseDate(validCreatedDateString, 'yyyy-MM-dd HH:mm:ss', new Date())
      const now = new Date()
      return differenceInDays(now, bookCreatedDate) < this.daysMarkedAsNew
    },
    hasLicense () {
      return !!(this.publication.pubkey)
    }
  }
}
