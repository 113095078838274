<template>
  <div class="viewer-container viewer-container-epub">
    <div class="viewer-holder">
      <div class="viewer-viewport-container">
        <div @click="previousPage" class="viewer-viewport-navigation viewer-viewport-navigation-left"></div>
        <div @click="nextPage" class="viewer-viewport-navigation viewer-viewport-navigation-right"></div>
        <div v-show="isLoading" class="viewer-viewport epubviewer-blanket"></div>
        <iframe ref="epubviewer_frame" id="epubviewer" name="epubviewer" class="viewer-viewport" src=""></iframe>
      </div>
      <div class="reader-bar reader-bar-bottom">
        <xposi-navigationbar></xposi-navigationbar>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from '@/components/reader/NavigationBar.vue'
import axiosAPI from '@/axiosAPI'
import mixinHighlight from '@/mixins/highlight.js'
import { Events } from '@/events'

export default {
  name: 'epub-viewer',
  data () {
    return {
      currentLinkInfo: null,
      currentPage: 0,
      flatTOC: [],
      fontSize: 1.0,
      searchActive: false,
      searchOptions: null,
      isLoading: false,
      printHeight: 0,
      printPosition: 0,
      printAssets: [],
      segmentSearchResultsPosition: [],
      segmentSearchResultsIndex: 0,
      resultFilesIndex: 0
    }
  },
  computed: {
    toc () {
      return this.$store.getters['reader/getToc']
    },
    topTOCLinkInfo () {
      return this.TOCToLinkInfo(this.toc.top)
    },
    licenseKey () {
      return this.$store.getters['reader/getLicenseKey']
    },
    resultFiles () {
      return this.$store.getters['reader/getResultFiles']
    },
    currentTOCIndex () {
      return this.$store.getters['reader/getCurrentTOCIndex']
    }
  },
  methods: {
    TOCToLinkInfo (tocdata = []) {
      const linkInfoList = []

      for (let i = 0; i < tocdata.length; i++) {
        const node = tocdata[i]
        const nodeLinkInfo = this.getLinkInfo(node.link)
        nodeLinkInfo.originalNode = node

        linkInfoList.push(nodeLinkInfo)
      }

      return linkInfoList
    },
    getLinkInfo (link) {
      link = link.split('?')[0]
      const linksplit = link.split('#')
      const info = {
        file: linksplit[0],
        anchor: linksplit.length > 1 ? '#' + linksplit[1] : null,
        link: link
      }

      return info
    },
    getCurrentChapterIndex () {
      const currentFile = this.currentLinkInfo.file
      return this.searchFileInToc(currentFile)
    },
    setCurrentChapterIndex () {
      this.getCurrentChapterIndex()
      this.$store.commit('reader/storeCurrentTOCIndex', this.getCurrentChapterIndex())
    },
    searchFileInToc (file) {
      let foundIndex = -1

      for (let i = 0; i < this.toc.flat.length; i++) {
        const node = this.toc.flat[i]
        const linkInfo = this.getLinkInfo(node.link)

        if (linkInfo.file === file) {
          let foundTopIndex = node.parents.top
          if (!foundTopIndex) foundTopIndex = node.index.top

          foundIndex = this.toc.top[foundTopIndex].index.flat

          break
        }
      }

      return foundIndex
    },
    searchFileInTocItem (item, file) {
      let found = false
      const infoItemForItem = this.getLinkInfo(item.link)

      if (infoItemForItem.file === file) {
        found = true
      } else if (item.children && item.children.length) {
        for (let i = 0; i < item.children.length; i++) {
          found = this.searchFileInTocItem(item.children[i], file)
          if (found) break
        }
      }

      return found
    },
    openLinkInfoItem (linkInfo) {
      return new Promise((resolve, reject) => {
        if (!linkInfo) reject(new Error('no link object given'))
        const currentLinkInfoItem = this.currentLinkInfo

        if (!currentLinkInfoItem || currentLinkInfoItem.file !== linkInfo.file) {
          this.isLoading = true
          // A new segment must be loaded first
          this.openSegmentFile(linkInfo.link)
            .then(() => {
              this.currentLinkInfo = linkInfo
              this.setCurrentChapterIndex()
              Events.$emit('readerNavigated')
              if (linkInfo.anchor) {
                this.openAnchor(linkInfo.anchor)
                  .then(() => {
                    resolve()
                    this.isLoading = false
                  })
                  .catch(error => {
                    this.scrollToPage(0)
                    this.isLoading = false
                    console.error(error.message)
                  })
              } else {
                resolve()
                this.isLoading = false
              }
            })
            .catch(error => {
              console.error(error.message)
              this.isLoading = false
            })
        } else {
          // This is the same segment file as currently loaded, so only navigation is necessary
          if (linkInfo.anchor) {
            this.openAnchor(linkInfo.anchor)
              .then(() => {
                this.currentLinkInfo = linkInfo
                this.setCurrentChapterIndex()
                resolve()
              })
              .catch(error => {
                console.error(error.message)
              })
          } else {
            resolve()
          }
        }
      })
    },
    openSegmentFile (link) {
      return new Promise((resolve, reject) => {
        link = link.split('#')[0]

        const url = '/appearances/' + this.$store.getters['reader/getDocKey'] + '/' + link

        axiosAPI.get(url, {
          params: {
            licenseKey: this.licenseKey
          }
        })
          .then(({ data }) => {
            const iframeDoc = this.getFrameDocument()

            // remove the click event from the current iframe body to avoid memory leaks
            iframeDoc.body.removeEventListener('click', this.handleFrameClick)

            // add licensekey to each src attribute
            const regex = /(?:(src=|href=)")(.+?)(")/gi
            data = data.replace(regex, '$1$2?licenseKey=' + this.licenseKey + '$3')

            // add base element to header for relative links
            const appearanceURL = axiosAPI.defaults.baseURL + '/appearances/' + this.$store.getters['reader/getDocKey'] + '/OEBPS/'
            const baseEl = `<base href="${appearanceURL}">`
            const headStartTag = '<head>'
            const headStartPosition = data.indexOf(headStartTag) + headStartTag.length
            data = data.substr(0, headStartPosition) + baseEl + data.substr(headStartPosition)

            // add local css
            const localDomain = window.location.protocol + '//' + window.location.host
            const stylesheetURL = localDomain + '/style/epubreader.css'
            const styleEl = `<link href="${stylesheetURL}" rel="stylesheet" type="text/css">`
            const headEndTag = '</head>'
            const headEndPosition = data.indexOf(headEndTag)
            data = data.substr(0, headEndPosition) + styleEl + data.substr(headEndPosition)

            // add wrapper div between the body tags and the content
            const wrapperOpeningEl = '<div class="epubviewer-contentwrapper" style="overflow: hidden; height: 100%;">'
            const wrapperClosingEl = '</div>'
            const bodyStartTag = data.match(/(<body.*?>)/gm)[0]
            const bodyEndTag = '</body>'
            const bodyStartPosition = data.indexOf(bodyStartTag) + bodyStartTag.length
            const bodyEndPosition = data.indexOf(bodyEndTag)
            data = data.substr(0, bodyStartPosition) + wrapperOpeningEl + data.substr(bodyStartPosition, bodyEndPosition) + wrapperClosingEl + data.substr(bodyEndPosition)

            // add overflow hidden to body tag
            data = data.replace(bodyStartTag, '<body style="overflow: hidden; height: 100%;">')

            // Remove relative links
            data = data.split('../').join('')

            // add html document in the iframe
            iframeDoc.open()
            iframeDoc.write(data)
            iframeDoc.close()

            // add click event listener to new document body
            iframeDoc.body.addEventListener('click', this.handleFrameClick)

            setTimeout(() => {
              this.initZoom()
              this.divideDocumentIntoPages().then(() => {
                if (this.searchActive) {
                  this.highlightPattern(this.getContentWrapper(), this.searchOptions).then(() => {
                    this.saveHighlightsPosition()
                    resolve()
                  })
                } else {
                  resolve()
                }
              })
            }, 100)
          })
          .catch(error => {
            if (error.response) {
              const statuscode = error.response.status

              if (statuscode === 403) {
                Events.$emit('readerLicenseExpired')
              }
            }
          })
      })
    },
    openAnchor (anchor) {
      return new Promise((resolve, reject) => {
        if (!anchor || anchor === '') reject(new Error('no anchor given'))

        if (anchor.charAt(0) !== '#') anchor = '#' + anchor

        // Add escaping of the . in the anchor, otherwise querySelector will fail
        anchor = anchor.split('.').join('\\.')

        const iframeDoc = this.getFrameDocument()
        const iframeBody = iframeDoc.body
        const anchorEl = iframeBody.querySelector(anchor)

        if (!anchorEl) reject(new Error(`anchor ${anchor} not found`))

        const frame = this.$refs.epubviewer_frame
        const frameHeight = frame.offsetHeight
        const anchorTop = anchorEl.getBoundingClientRect().top + this.getContentWrapper().scrollTop
        const page = Math.floor(anchorTop / frameHeight)

        this.scrollToPage(page)
        resolve()
      })
    },
    getFrameDocument () {
      const iframeEl = this.$refs.epubviewer_frame
      const iframeDoc = iframeEl.contentDocument || iframeEl.contentWindow.document
      return iframeDoc
    },
    getContentWrapper () {
      const iframeDoc = this.getFrameDocument()
      const iframeBody = iframeDoc.body
      const contentWrapper = iframeBody.querySelector('.epubviewer-contentwrapper')
      return contentWrapper
    },
    initSpacer () {
      const iframeDoc = this.getFrameDocument()

      // remove the spacers
      const oldSpacers = iframeDoc.querySelectorAll('#document-spacer')

      if (oldSpacers) {
        for (let i = 0; i < oldSpacers.length; i++) {
          const element = oldSpacers[i]
          element.parentNode.removeChild(element)
        }
      }

      const contentWrapper = this.getContentWrapper()
      const cwScrollHeight = contentWrapper.scrollHeight
      const frameHeight = contentWrapper.offsetHeight

      const spacerHeight = cwScrollHeight > frameHeight ? frameHeight - (cwScrollHeight % frameHeight) : 0

      // append a new spacer
      const newSpacer = document.createElement('div')
      newSpacer.setAttribute('id', 'document-spacer')
      newSpacer.style.height = spacerHeight + 'px'
      contentWrapper.appendChild(newSpacer)
    },
    fixParagraphs () {
      const frame = this.$refs.epubviewer_frame
      const frameHeight = frame.offsetHeight
      const iframeDoc = this.getFrameDocument()
      const iframeBody = iframeDoc.body
      const contentWrapper = this.getContentWrapper()

      // Walk through all paragraphs and see if it is half into a page
      const paragraphs = iframeBody.querySelectorAll('p, table')

      for (let m = 0; m < paragraphs.length; m++) {
        const element = paragraphs[m]
        const elementTop = element.offsetTop
        const elementBottom = (elementTop + element.offsetHeight)

        // checks what element fall outside
        for (let n = 0; n <= (contentWrapper.scrollHeight + frameHeight); n += frameHeight) {
          if (elementTop < n && elementBottom > n) {
            // create an element
            const result = document.createElement('div')
            result.className = 'document-inner-spacer'
            result.style.margin = 0
            result.style.padding = (n - elementTop) + 'px 0px 0px 0px'
            element.parentNode.insertBefore(result, element)
            break
          }
        }
      }
    },
    fixTables () {
      const frame = this.$refs.epubviewer_frame
      const frameHeight = frame.offsetHeight
      const iframeDoc = this.getFrameDocument()
      const iframeBody = iframeDoc.body
      const contentWrapper = this.getContentWrapper()

      // walk through all table rows and see if it is half into a page
      const tableRows = iframeBody.querySelectorAll('tr')

      for (let m = 0; m < tableRows.length; m++) {
        const element = tableRows[m]
        const elementTop = element.offsetTop
        const elementBottom = (elementTop + element.offsetHeight)

        // checks what element fall outside
        for (let n = 0; n <= (contentWrapper.scrollHeight + frameHeight); n += frameHeight) {
          if (elementTop < n && elementBottom > n) {
            // create new table row with child elements
            const newRow = document.createElement('tr')
            newRow.className = 'table-inner-spacer'
            const newCell = newRow.insertCell(0)

            const newDiv = document.createElement('div')
            newDiv.style.margin = 0
            newDiv.style.padding = (n - elementTop) + 'px 0px 0px 0px'

            newCell.appendChild(newDiv)

            element.parentNode.insertBefore(newRow, element)
            break
          }
        }
      }
    },
    divideDocumentIntoPages () {
      return new Promise((resolve, reject) => {
        const frame = this.$refs.epubviewer_frame
        const frameHeight = frame.offsetHeight
        const iframeDoc = this.getFrameDocument()
        const iframeBody = iframeDoc.body

        // remove the inner spacers
        const innerSpacers = iframeDoc.querySelectorAll('.document-inner-spacer, .table-inner-spacer')

        if (innerSpacers) {
          for (let i = 0; i < innerSpacers.length; i++) {
            const element = innerSpacers[i]
            element.parentNode.removeChild(element)
          }
        }

        // First scale images to their original height
        // If images exceed the height of a page, scale the image so it fits on one page
        const images = iframeBody.querySelectorAll('img')

        for (let i = 0; i < images.length; i++) {
          const element = images[i]

          if (element.getAttribute('data-adjust-height') === '1') {
            element.style.height = ''
            element.setAttribute('data-adjust-height', '0')
          }

          const elementHeight = element.offsetHeight

          element.style.maxHeight = (frameHeight - 2) + 'px'

          if (elementHeight > frameHeight) {
            element.style.height = (frameHeight - 2) + 'px'
            element.setAttribute('data-adjust-height', '1')
          }
        }

        this.fixParagraphs()
        this.fixTables()
        this.initSpacer()

        resolve()
      })
    },
    nextPage () {
      if (this.currentPage + 1 < this.getAmountOfPages()) {
        this.scrollToPage(this.currentPage + 1)
      } else {
        let nextFile = null

        for (let i = this.topTOCLinkInfo.length - 1; i >= 0; i--) {
          const TOCEntry = this.topTOCLinkInfo[i]

          if (TOCEntry.file === this.currentLinkInfo.file) break

          nextFile = TOCEntry.file
        }

        if (nextFile) {
          this.openLinkInfoItem(this.getLinkInfo(nextFile))
            .then(() => {
              this.scrollToPage(0)
            })
        }
      }
    },
    previousPage () {
      if (this.currentPage > 0) {
        this.scrollToPage(this.currentPage - 1)
      } else {
        let prevFile = null

        for (let i = 0; i < this.topTOCLinkInfo.length; i++) {
          const TOCEntry = this.topTOCLinkInfo[i]

          if (TOCEntry.file === this.currentLinkInfo.file) break

          prevFile = TOCEntry.file
        }

        if (prevFile) {
          this.openLinkInfoItem(this.getLinkInfo(prevFile))
            .then(() => {
              this.scrollToPage(this.getAmountOfPages() - 1)
            })
        }
      }
    },
    nextChapter () {
      let currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].parents.top
      if (!currentTopLevelNodeIndex) currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].index.top

      if (currentTopLevelNodeIndex >= 0 && currentTopLevelNodeIndex < this.topTOCLinkInfo.length - 1) this.openLinkInfoItem(this.topTOCLinkInfo[currentTopLevelNodeIndex + 1])
    },
    previousChapter () {
      let currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].parents.top
      if (!currentTopLevelNodeIndex) currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].index.top

      if (currentTopLevelNodeIndex > 0) this.openLinkInfoItem(this.topTOCLinkInfo[currentTopLevelNodeIndex - 1])
    },
    getAmountOfPages () {
      const frameHeight = this.$refs.epubviewer_frame.offsetHeight
      const contentWrapper = this.getContentWrapper()
      const cwHeight = contentWrapper.scrollHeight
      return Math.ceil(cwHeight / frameHeight)
    },
    scrollToPage (pagenum) {
      if (isNaN(pagenum) || pagenum < 0) pagenum = 0

      const contentWrapper = this.getContentWrapper()
      const pageAmount = this.getAmountOfPages()
      const cwHeight = contentWrapper.offsetHeight

      if (pagenum > pageAmount - 1) pagenum = pageAmount - 1

      const pageTop = cwHeight * pagenum

      contentWrapper.scrollTop = pageTop

      this.currentPage = pagenum
    },
    keyNavigation (e) {
      switch (e.keyCode) {
        case 37: // left arrow
          this.previousPage()
          break
        case 39: // right arrow
          this.nextPage()
          break
      }
    },
    handleFrameClick (e) {
      e.preventDefault()
      const targetEl = e.target
      let href = ''

      if (targetEl.tagName === 'A') {
        href = targetEl.href
      } else if (targetEl.parentNode.tagName === 'A') {
        href = targetEl.parentNode.href
      } else {
        return
      }

      if (href.indexOf('/OEBPS/') >= 0) {
        // internal link
        const internalLink = href.substring(href.indexOf('OEBPS'))
        this.openLinkInfoItem(this.getLinkInfo(internalLink))
      } else {
        // external link, open in new tab
        window.open(href, '_blank')
      }
    },
    readerLoadedEventHandler () {
      if (this.topTOCLinkInfo.length) this.openLinkInfoItem(this.topTOCLinkInfo[0])
    },
    navigateToFileEventHandler (link) {
      if (!link || link === '') return
      this.openLinkInfoItem(this.getLinkInfo(link))
    },
    resizeEventHandler (e) {
      this.divideDocumentIntoPages()
      this.initZoom()
      this.scrollToPage(this.currentPage)
    },
    initZoom () {
      this.setZoom(this.fontSize)
    },
    setZoom (size) {
      const iframeDoc = this.getFrameDocument()
      const iframeBody = iframeDoc.body
      this.fontSize = size

      iframeBody.style.fontSize = size + 'em'
      this.divideDocumentIntoPages()
      this.scrollToPage(this.currentPage)
    },
    zoomDefault () {
      this.setZoom(1.0)
    },
    zoomIn () {
      const maxReached = (this.fontSize + 0.2) > 2.0
      this.setZoom(maxReached ? 2.0 : (this.fontSize + 0.2))
      if (maxReached) return true
    },
    zoomOut () {
      const maxReached = (this.fontSize - 0.2) < 0.4
      this.setZoom(maxReached ? 0.4 : (this.fontSize - 0.2))
      if (maxReached) return true
    },
    saveHighlightsPosition () {
      const contentWrapper = this.getContentWrapper()
      const cwHeight = contentWrapper.offsetHeight
      const cwScrollTop = contentWrapper.scrollTop
      const segmentHighlights = contentWrapper.querySelectorAll('.xposi-highlight')
      if (!segmentHighlights) return

      this.segmentSearchResultsPosition = []

      for (let i = 0; i < segmentHighlights.length; i++) {
        const element = segmentHighlights[i]
        const elementTop = element.getBoundingClientRect().top
        const top = elementTop + cwScrollTop
        const pagenum = Math.floor(top / cwHeight)

        if (this.segmentSearchResultsPosition.indexOf(pagenum) === -1) {
          this.segmentSearchResultsPosition.push(pagenum)
        }
      }
    },
    scrollToNextHighlight () {
      let srIndex = this.segmentSearchResultsIndex
      srIndex++

      if (srIndex > this.segmentSearchResultsPosition.length - 1) {
        const currentRfIndex = this.resultFilesIndex
        const newRfIndex = currentRfIndex === this.resultFiles.length - 1 ? 0 : currentRfIndex + 1

        const newSegment = this.resultFiles[newRfIndex].path

        this.openLinkInfoItem(this.getLinkInfo(newSegment)).then(() => {
          this.scrollToPage(this.segmentSearchResultsPosition[0])
          this.segmentSearchResultsIndex = 0
        })
        this.resultFilesIndex = newRfIndex
      } else {
        this.scrollToPage(this.segmentSearchResultsPosition[srIndex])
        this.segmentSearchResultsIndex = srIndex
      }
    },
    scrollToPreviousHighlight () {
      let srIndex = this.segmentSearchResultsIndex
      srIndex--

      if (srIndex < 0) {
        const currentRfIndex = this.resultFilesIndex
        const newRfIndex = currentRfIndex === 0 ? this.resultFiles.length - 1 : currentRfIndex - 1

        const newSegment = this.resultFiles[newRfIndex].path
        this.openLinkInfoItem(this.getLinkInfo(newSegment)).then(() => {
          this.scrollToPage(this.segmentSearchResultsPosition[this.segmentSearchResultsPosition.length - 1])
          this.segmentSearchResultsIndex = this.segmentSearchResultsPosition.length - 1
        })
        this.resultFilesIndex = newRfIndex
      } else {
        this.scrollToPage(this.segmentSearchResultsPosition[srIndex])
        this.segmentSearchResultsIndex = srIndex
      }
    },
    searchStartEventHandler (searchOptions) {
      this.searchActive = true
      this.searchOptions = searchOptions

      const segmentPath = this.resultFiles[0].path

      if (segmentPath === this.currentLinkInfo.link) {
        this.highlightPattern(this.getContentWrapper(), this.searchOptions).then(() => {
          this.saveHighlightsPosition()
        })
      } else {
        this.openLinkInfoItem(this.getLinkInfo(segmentPath)).then(() => {
          this.scrollToPage(this.segmentSearchResultsPosition[0])
          this.segmentSearchResultsIndex = 0
        })
      }
    },
    searchStopEventHandler () {
      this.searchActive = false
      this.searchOptions = null
      this.resultFilesIndex = 0
      this.segmentSearchResultsIndex = 0
      this.segmentSearchResultsPosition = []

      const frame = this.$refs.epubviewer_frame
      const container = frame.contentWindow.document.body
      this.removeHighlights(container)
    },
    readerPanelEventHandler () {
      setTimeout(() => {
        this.resizeEventHandler()
      }, 100)
    },
    print (printAmount) {
      const _this = this

      if (isNaN(printAmount) || printAmount < 0 || !printAmount) printAmount = 1

      const frame = _this.$refs.epubviewer_frame
      const frameDoc = _this.getFrameDocument()
      const frameDocBody = frameDoc.body
      const frameHeight = frame.offsetHeight
      const frameWidth = frame.offsetWidth
      const frameTop = frameHeight * _this.currentPage

      // create seperate print window
      const contents = '' +
        '<!DOCTYPE html>' +
        '<html>' +
          '<head>' +
            frameDoc.head.innerHTML +
            '<style>' +
                '@page {size: auto; margin: 20mm 20mm 20mm 20mm;}' +
              '</style>' +
          '</head>' +
          '<body>' +
            frameDocBody.innerHTML +
          '</body>' +
        '</html>'

      const printWindow = window.open('about:blank', '',
        'height=' + frameHeight + ',width=' + frameWidth)
      const printWindowDoc = printWindow.document
      printWindowDoc.write(contents)
      printWindowDoc.body.style.visibility = 'hidden'

      // specify where user is within chapter
      _this.printHeight = frameHeight
      _this.printPosition = frameTop

      // handeling the links
      const frameLinks = frameDoc.querySelectorAll('link')
      for (let i = 0; i < frameLinks.length; i++) {
        const element = frameLinks[i]
        _this.printAssets.push(element.href)
      }

      const printLinks = printWindowDoc.querySelectorAll('link')
      for (let i = 0; i < printLinks.length; i++) {
        const element = printLinks[i]
        if (undefined !== _this.printAssets[0]) {
          element.setAttribute('href', _this.printAssets.shift())
        }
      }

      // handeling the paragraphs
      const frameDocCopy = _this.getFrameDocument()
      const startOffset = Math.floor(frameTop / frameHeight) * frameHeight
      const endOffset = startOffset + (printAmount * frameHeight)
      const printElementsPositions = []

      const frameParagraphs = frameDocCopy.querySelectorAll('p, table')
      for (let position = 0; position < frameParagraphs.length; position++) {
        const element = frameParagraphs[position]
        const elemTop = element.offsetTop
        const elemBottom = (elemTop + element.offsetHeight)
        if (elemTop >= startOffset && elemTop <= endOffset && elemBottom <= endOffset) {
          printElementsPositions.push(position)
        }
      }

      const printParagraphs = printWindowDoc.querySelectorAll('p, table')
      for (let position = 0; position < printParagraphs.length; position++) {
        const element = printParagraphs[position]
        if (printElementsPositions.indexOf(position) === -1) {
          element.style.display = 'none'
        }
      }

      const printSpacers = printWindowDoc.querySelectorAll('.document-inner-spacer , .table-inner-spacer, #document-spacer')
      for (let i = 0; i < printSpacers.length; i++) {
        const element = printSpacers[i]
        element.style.display = 'none'
      }

      printWindowDoc.body.style.visibility = 'visible'
      printWindowDoc.body.classList.add('print')
      printWindowDoc.close()
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    },
    printEventHandler (amount) {
      this.print(amount)
    }
  },
  mounted () {
    const _this = this

    Events.$on('readerLoaded', _this.readerLoadedEventHandler)
    Events.$on('readerNavigationNextPage', _this.nextPage)
    Events.$on('readerNavigationPreviousPage', _this.previousPage)
    Events.$on('readerNavigateToNextChapter', _this.nextChapter)
    Events.$on('readerNavigateToPreviousChapter', _this.previousChapter)
    Events.$on('readerNavigateToFile', _this.navigateToFileEventHandler)
    Events.$on('readerZoomIn', _this.zoomIn)
    Events.$on('readerZoomOut', _this.zoomOut)
    Events.$on('readerZoomDefault', _this.zoomDefault)
    Events.$on('readerSearchStart', _this.searchStartEventHandler)
    Events.$on('readerSearchStop', _this.searchStopEventHandler)
    Events.$on('readerPanelExpand', _this.readerPanelEventHandler)
    Events.$on('readerPanelCollapse', _this.readerPanelEventHandler)
    Events.$on('readerPrint', _this.printEventHandler)
    Events.$on('readerNavigateToNextResult', _this.scrollToNextHighlight)
    Events.$on('readerNavigateToPreviousResult', _this.scrollToPreviousHighlight)

    window.addEventListener('resize', _this.resizeEventHandler)

    document.addEventListener('keyup', _this.keyNavigation)
  },
  beforeUnmount () {
    const _this = this

    Events.$off('readerLoaded', _this.readerLoadedEventHandler)
    Events.$off('readerNavigationNextPage', _this.nextPage)
    Events.$off('readerNavigationPreviousPage', _this.previousPage)
    Events.$off('readerNavigateToNextChapter', _this.nextChapter)
    Events.$off('readerNavigateToPreviousChapter', _this.previousChapter)
    Events.$off('readerNavigateToFile', _this.navigateToFileEventHandler)
    Events.$off('readerZoomIn', _this.zoomIn)
    Events.$off('readerZoomOut', _this.zoomOut)
    Events.$off('readerZoomDefault', _this.zoomDefault)
    Events.$off('readerSearchStart', _this.searchStartEventHandler)
    Events.$off('readerSearchStop', _this.searchStopEventHandler)
    Events.$off('readerPanelExpand', _this.readerPanelEventHandler)
    Events.$off('readerPanelCollapse', _this.readerPanelEventHandler)
    Events.$off('readerPrint', _this.printEventHandler)
    Events.$off('readerNavigateToNextResult', _this.navigateToNextResultEventHandler)
    Events.$off('readerNavigateToPreviousResult', _this.navigateToPreviousResultEventHandler)

    window.removeEventListener('resize', _this.resizeEventHandler)

    document.removeEventListener('keyup', _this.keyNavigation)
  },
  mixins: [mixinHighlight],
  components: {
    'xposi-navigationbar': NavigationBar
  }
}
</script>
