import Joi from 'joi'

const advancedFieldValidator = Joi.object({
  mode: Joi.number().integer().min(0).max(2).required(),
  operator: Joi.number().integer().min(0).max(1).required(),
  name: Joi.string().min(1).required(),
  value: Joi.string().allow('').required()
})

const facetsValidator = Joi.object({}).pattern(
  Joi.string().min(1),
  Joi.array().items(Joi.string().min(1)).required()
)

const sortingOrderRegex = /^(asc|desc)$/

const queryObjectValidator = Joi.object({
  query: Joi.string().allow('').required(),
  queryType: Joi.number().integer().min(0).max(2).required(),
  fields: Joi.array().items(advancedFieldValidator),
  sortField: Joi.string().min(1),
  sortOrder: Joi.string().regex(sortingOrderRegex, 'asc or desc'),
  module: Joi.number().integer().min(0),
  connection: Joi.number().integer().min(0),
  facets: facetsValidator
}).with('sortField', 'sortOrder').with('sortOrder', 'sortField').with('facets', 'connection')

export default queryObjectValidator
