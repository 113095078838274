export default {
  data () {
    return {
      debounceTime: 200,
      debounceTimeout: null
    }
  },
  methods: {
    debounce (functionToExecute) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        functionToExecute()
      }, this.debounceTime)
    }
  }
}
