<template>
  <div>
    <div class="toc-list-item" :data-index="flatindex" @click="sendOpenEvent" v-html="parseDOM(title)" :class="{ 'toc-list-current': doHighlight }"></div>
    <div class="toc-list-children" v-if="children.length > 0">
      <toc-list-item v-for="child in children" :title="child.title" :file="child.link" :children="child.children" :flatindex="child.index.flat" :key="child.title + child.link"></toc-list-item>
    </div>
  </div>
</template>

<script>
import { Events } from '@/events'
import domparser from '@/mixins/domparser'

export default {
  name: 'toc-list-item',
  computed: {
    doHighlight () {
      const highlights = this.$store.getters['reader/getTOCHighlights']

      return (highlights.indexOf(this.flatindex) !== -1)
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    file: {
      type: String,
      required: true
    },
    children: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    flatindex: {
      type: Number,
      required: true
    }
  },
  methods: {
    /**
     * Triggers the readerNavigateToFile on the event bus with the filename from this item
     * @return {undefined}
     */
    sendOpenEvent () {
      Events.$emit('readerNavigateToFile', this.file)
    }
  },
  mixins: [domparser]
}
</script>
