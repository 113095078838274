<template>
  <div class="multimedia">
    <img
      v-if="iconUrl"
      :src="iconUrl"
      class="multimedia-icon"
      :class="{ 'multimedia-icon-gallery': mediatype === MultimediaTypes.ImageGallery }"
    />

    <div v-if="mediatype === MultimediaTypes.ImageGallery" class="multimedia-gallery">
      <a
        v-for="image of images"
        :key="image"
        :href="image"
        :title="getFileNameFromUrl(image)"
        target="_blank"
        class="multimedia-gallery-link"
      >
        <img :src="image" class="multimedia-gallery-image" />
      </a>
    </div>
    <a v-else :href="url" :title="linkDisplay" target="_blank" class="multimedia-link">{{ linkDisplay }}</a>
  </div>
</template>

<script>
import fileNameParsingMixin from '@/mixins/fileNameParsing'

const MultimediaTypes = Object.freeze({
  Link: 'link',
  Document: 'document',
  Sound: 'sound',
  Movie: 'movie',
  ImageGallery: 'imagegallery'
})

export default {
  name: 'publication-multimedia',
  props: {
    mediatype: {
      validator (value) { return Object.values(MultimediaTypes).includes(value) }
    },
    url: { type: String, default: '' },
    display: { type: String, default: '' },
    images: { type: Array, default: () => [] }
  },
  computed: {
    iconUrl () {
      let icontype = ''

      switch (this.mediatype) {
        case MultimediaTypes.Link: icontype = 'link'; break
        case MultimediaTypes.Document: icontype = 'document'; break
        case MultimediaTypes.Sound: icontype = 'sound'; break
        case MultimediaTypes.Movie: icontype = 'movie'; break
        case MultimediaTypes.ImageGallery: icontype = 'image'; break
        default: icontype = ''
      }

      if (!icontype) return null
      return `/img/icons/multimedia_${icontype}.png`
    },
    linkDisplay () {
      return this.display ? this.display : this.getFileNameFromUrl(this.url)
    }
  },
  data: () => ({
    MultimediaTypes
  }),
  mixins: [fileNameParsingMixin]
}

export { MultimediaTypes }
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .multimedia {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: 2px 0;

    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 4px;

      &-gallery {
        align-self: flex-start;
      }
    }

    &-link {
      color: $main_dark;
    }

    &-gallery {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;

      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border: 1px solid $grey_middle;

        &:hover {
          border-color: black;
        }
      }

      &-image {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
</style>
