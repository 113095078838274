<template>
  <div class="copy-clipboard">
    <input
      type="text"
      class="input-text"
      :value="copyvalue"
      @focus="onInputFocus"
      readonly />
    <button
      class="input-button input-button-outlined"
      @click="copyToClipboard"
    >{{ capitalize($t('copy')) }}</button>
  </div>
</template>

<script>
export default {
  name: 'copy-clipboard',
  props: {
    copyvalue: {
      type: String,
      default: ''
    }
  },
  methods: {
    async copyToClipboard () {
      try {
        await navigator.clipboard.writeText(this.copyvalue)
      } catch (e) {
        console.error(e)
      }
    },
    onInputFocus (e) {
      e.target.select()
    }
  }
}
</script>

<style lang="scss" scoped>
  .copy-clipboard {
    display: flex;
    flex-direction: row;

    input {
      cursor: pointer;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
    }

    button {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
</style>
