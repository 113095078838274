const sortFieldOptions = [
  { label: 'sorting.fields.score', value: 'score' },
  { label: 'metadataField.title', value: 'title' },
  { label: 'metadataField.yearofpublication', value: 'yearofpublication' }
]

const sortOrderOptions = [
  { label: 'sorting.order.descending', value: 'desc' },
  { label: 'sorting.order.ascending', value: 'asc' }
]

export default {
  sortFieldOptions,
  sortOrderOptions
}
