<template>
  <div class="reader-settings-container">
    <div>
      <div class="label">{{ $t('Text size') }}</div>
      <div>
        <xposi-zoombuttons></xposi-zoombuttons>
      </div>
    </div>
  </div>
</template>

<script>
import ZoomButtons from '@/components/reader/ZoomButtons.vue'

export default {
  components: {
    'xposi-zoombuttons': ZoomButtons
  }
}
</script>

<style>

</style>
