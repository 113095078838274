<template>
  <div class="popup-background">
    <div class="popup-window">
      <div class="popup-header">
        <h1 class="popup-title">{{ capitalize($t('bookshelf.addToBookshelf')) }}</h1>
      </div>
      <div v-if="validationError" class="form-row">
        <div class="validationerror-block">{{ validationError }}</div>
      </div>
      <div class="popup-form">
        <div class="form-row">
          <label for="name">{{ capitalize($t('bookshelf.addItemTo')) }}:</label>
          <div v-if="hasExistingBookshelves" class="form-radio">
            <input type="radio" value="existing" v-model="addToOption"/>
            {{ capitalize($t('bookshelf.existingBookshelf')) }}
          </div>
          <div v-if="hasExistingBookshelves" class="form-indent">
            <custom-select v-model="selectedBookshelf" :options="bookshelfOptions" :disableLabelTranslation="true" style="width: 100%;"></custom-select>
          </div>
          <div class="form-radio">
            <input type="radio" value="new" v-model="addToOption"/>
            {{ capitalize($t('bookshelf.newBookshelf')) }}
          </div>
          <div class="form-indent">
            <input type="text" class="input-text" v-model="newBookshelfName" :placeholder="capitalize($t('bookshelf.bookshelfName'))" style="width: 100%;"/>
          </div>
        </div>
      </div>
      <div class="form-row">
        <button @click="send" class="input-button input-button-primary" :disabled="this.sending">{{ capitalize($t('actions.add')) }}</button>
      </div>
      <div class="popup-close" @click="close">&#10006;</div>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/form-elements/CustomSelect'
import { Events } from '@/events'

export default {
  name: 'modal-bookshelf-item-add',
  emits: ['close'],
  computed: {
    bookshelves () {
      return this.$store.getters['bookshelfStore/getUserBookshelves']
    },
    bookshelfOptions () {
      return this.bookshelves.map(shelf => ({ label: shelf.name, value: shelf.id }))
    }
  },
  data: () => ({
    hasExistingBookshelves: false,
    addToOption: 'existing',
    selectedBookshelf: null,
    newBookshelfName: '',
    data: null,
    validationError: null,
    sending: false
  }),
  methods: {
    reset () {
      this.validationError = null
      this.sending = false
      this.selectedBookshelf = null
      this.data = null
    },
    load (addData = null) {
      this.reset()

      if (!addData) return

      this.data = addData
    },
    async send () {
      if (this.sending || !this.data) return

      this.validationError = null
      this.sending = true

      let result = null

      let bookshelfId = this.selectedBookshelf

      if (this.addToOption === 'new') {
        if (!this.newBookshelfName.trim()) {
          this.validationError = this.capitalize(`${this.$t('bookshelf.bookshelfName')} ${this.$t('bookshelf.is_required')}`)
          this.sending = false
          return false
        }

        const data = { name: this.newBookshelfName }
        const result = await this.$store.dispatch('bookshelfStore/createNewBookshelf', data)

        if (result.error) {
          this.sending = false
          this.validationError = result.error
          return false
        }

        await this.$store.dispatch('bookshelfStore/fetchUserBookshelves')

        bookshelfId = result.id
      }

      if (Array.isArray(this.data)) {
        const storeData = {
          bookshelfId: bookshelfId,
          items: this.data
        }

        result = await this.$store.dispatch('bookshelfStore/addBookshelfItems', storeData)
      } else {
        const storeData = {
          bookshelfId: bookshelfId,
          item: this.data
        }

        result = await this.$store.dispatch('bookshelfStore/addBookshelfItem', storeData)
      }

      if (!result || result.error) {
        this.sending = false
        this.validationError = result.error
        return false
      }

      await this.$store.dispatch('bookshelfStore/fetchUserBookshelves')

      this.sending = false
      Events.$emit('uncheckResults')
      this.close()
    },
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    if (this.bookshelves.length) {
      this.hasExistingBookshelves = true
    } else {
      this.addToOption = 'new'
    }
  },
  components: {
    'custom-select': CustomSelect
  }
}
</script>

<style lang="scss" scoped>
  .form-row {
    margin: 10px 0;

    label {
      display: block;
    }
  }

  .form-radio {
    margin-top: 10px;
  }

  .form-indent {
    padding-left: 28px;
  }

  .input-fullwidth {
    width: 100%;
  }

  .validationerror-block {
    padding: 10px;
    border: 1px solid red;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
  }
</style>
