export default {
  methods: {
    highlightPattern (container, searchOptions) {
      return new Promise((resolve, reject) => {
        if (!searchOptions || typeof searchOptions !== 'object') return reject(new Error('No searchOptions provided for highlighting'))
        if (!searchOptions.query || searchOptions.query === '') return reject(new Error('No query provided in searchOptions for highlighting'))
        if (
          !searchOptions.searchMode ||
          (searchOptions.searchMode !== 'phrase' && searchOptions.searchMode !== 'words')
        ) return reject(new Error('Invalid search mode provided in searchOptions for highlighting'))
        if (!container) return reject(new Error('No existing container given'))

        this.highlightNode(container, searchOptions)
        resolve()
      })
    },
    highlightNode (node, searchOptions) {
      const patternRegex = createRegExpForSearchOptions(searchOptions)
      let skipNewNode = false

      if (node.nodeType === 3) {
        let match

        do {
          match = patternRegex.exec(node.data.toUpperCase())
          if (!match) break

          const textNodeMatch = node.splitText(match.index)
          textNodeMatch.splitText(match[0].length)
          const newNodeText = textNodeMatch.cloneNode(true)

          const newNode = document.createElement('span')
          newNode.className = 'xposi-highlight'
          newNode.appendChild(newNodeText)

          textNodeMatch.parentNode.replaceChild(newNode, textNodeMatch)

          skipNewNode = true
        }
        while (match)
      } else if (node.nodeType === 1 && node.childNodes.length && !(node.nodeName === 'script' || node.nodeName === 'style')) {
        for (let i = 0; i < node.childNodes.length; i++) {
          // when highlightnode returns 1, skip the newly created span element to avoid an infinite loop
          i += this.highlightNode(node.childNodes[i], searchOptions)
        }
      }

      return skipNewNode
    },
    removeHighlights (container) {
      if (!container) return

      const highlights = container.querySelectorAll('.xposi-highlight')

      for (let i = 0; i < highlights.length; i++) {
        const highlightNode = highlights[i]
        const textNode = highlightNode.childNodes[0]
        const textNodeClone = textNode.cloneNode(true)

        highlightNode.parentNode.replaceChild(textNodeClone, highlightNode)
      }
    }
  }
}

function createRegExpForSearchOptions (searchOptions) {
  let regexString = ''

  if (searchOptions.searchMode === 'phrase') {
    regexString = createRegexStringForFindingASpecificPhrase(searchOptions)
  } else if (searchOptions.searchMode === 'words') {
    regexString = createRegexStringForFindingAnyOfTheProvidedWords(searchOptions)
  }

  return new RegExp(regexString, 'g')
}

function createRegexStringForFindingASpecificPhrase (searchOptions) {
  const splitPattern = searchOptions.query.toUpperCase().split(' ')
  let regexString = ''

  for (let i = 0; i < splitPattern.length; i++) {
    regexString += '[\\("\'`]?(' + splitPattern[i].toUpperCase() + ')[\\)"\'`]?'
    if (i < splitPattern.length - 1) {
      regexString += '[ ]{1,}'
    }
  }

  return regexString
}

function createRegexStringForFindingAnyOfTheProvidedWords (searchOptions) {
  const splitPattern = searchOptions.query
    .toUpperCase()
    .match(createRegExpForFindingSeparateWords())
  let regexString = ''

  regexString += '('
  for (let i = 0; i < splitPattern.length; i++) {
    regexString += splitPattern[i].toUpperCase()
    if (i < splitPattern.length - 1) {
      regexString += '|'
    }
  }
  regexString += ')'

  return regexString
}

function createRegExpForFindingSeparateWords () {
  return /[^\s"]+/g
}
