<template>
  <div class="page-container">

    <div class="page-content">

      <div class="page-content-container">
        <h1>{{ $t('shop.success.title') }}</h1>
        <p>{{ $t('shop.success.text') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style>
</style>
