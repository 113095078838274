<template>
  <div class="facet">
    <div class="facet-toggle">
      <input type="checkbox" @change="$emit('toggle', !active)" :checked="active"/>
    </div>
    <div class="facet-key">
      {{ facet.key }}
    </div>
    <div class="facet-count">
      {{ facet.value }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['toggle'],
  props: {
    active: Boolean,
    facet: Object
  }
}
</script>

<style lang="scss" scoped>
  .facet {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;

    &-toggle {
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 4px;

      input {
        margin: 2px;
      }
    }

    &-key {
      width: 100%;
    }

    &-count {
      width: 34px;
      text-align: right;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
</style>
