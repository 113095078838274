import API from '@/axiosAPI'

export default () => ({
  namespaced: true,
  state: {
    bookshelves: [],
    external: null
  },
  getters: {
    getUserBookshelves (state) {
      return state.bookshelves
    },
    getUserBookshelf (state) {
      return (id) => {
        const bookshelf = state.bookshelves.find(i => i.id === id)
        return bookshelf ?? null
      }
    },
    getExternalBookshelf (state) {
      return state.external
    }
  },
  actions: {
    async initialise ({ dispatch }) {
      await dispatch('fetchUserBookshelves')
    },
    async fetchUserBookshelves ({ commit }) {
      try {
        const { data } = await API.get('/bookshelf')
        commit('setBookshelves', data)
      } catch (e) {
        console.log(e)
      }
    },
    async createNewBookshelf (_, bookshelfData) {
      try {
        const { data } = await API.post('/bookshelf', bookshelfData, { responseType: 'json' })
        return data
      } catch (e) {
        return { error: e.message }
      }
    },
    async fetchExternalBookshelf ({ commit }, id) {
      try {
        const { data } = await API.get(`/bookshelf/${id}`)
        commit('setExternalBookshelf', data)
        return data
      } catch (e) {
        return { error: e.message }
      }
    },
    async clearExternalBookshelf ({ commit }) {
      commit('setExternalBookshelf', null)
    },
    async editBookshelf (_, bookshelfData) {
      try {
        const body = { name: bookshelfData.name }
        const { data } = await API.put(`/bookshelf/${bookshelfData.id}`, body, { responseType: 'json' })
        return data
      } catch (e) {
        return { error: e.message }
      }
    },
    async deleteBookshelf (_, bookshelfId) {
      try {
        const { data } = await API.delete(`/bookshelf/${bookshelfId}`, { responseType: 'json' })
        return data
      } catch (e) {
        return { error: e.message }
      }
    },
    async addBookshelfItem (_, bookshelfItemData) {
      const { bookshelfId, item } = bookshelfItemData

      try {
        const { data } = await API.post(`/bookshelf/${bookshelfId}/add`, item, { responseType: 'json' })
        return data
      } catch (e) {
        return { error: e.message }
      }
    },
    async addBookshelfItems (_, bookshelfItemData) {
      const { bookshelfId, items } = bookshelfItemData

      const returnData = []

      try {
        for (const item of items) {
          const { data } = await API.post(`/bookshelf/${bookshelfId}/add`, item, { responseType: 'json' })
          returnData.push(data)
        }
        return returnData
      } catch (e) {
        return { error: e.message }
      }
    },
    async deleteBookshelfItem (_, bookshelfItemData) {
      const { bookshelfId, bookshelfItemId } = bookshelfItemData

      try {
        const { data } = await API.delete(`/bookshelf/${bookshelfId}/${bookshelfItemId}`, { responseType: 'json' })
        return data
      } catch (e) {
        return { error: e.message }
      }
    }
  },
  mutations: {
    setBookshelves (state, bookshelfList) {
      if (!Array.isArray(bookshelfList)) return
      state.bookshelves = bookshelfList
    },
    setExternalBookshelf (state, bookshelf) {
      state.external = bookshelf
    }
  }
})
