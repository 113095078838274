<template>
  <div class="readerpanel-container" :class="[expanded ? 'readerpanel-expanded' : 'readerpanel-collapsed']">
    <div v-show="expanded" class="readerpanel-inner">
      <div class="readerpanel-collapse" @click="collapse"><img src="/img/icons/back.png" /></div>
      <div class="readerpanel-search-container">
        <xposi-readersearchbar></xposi-readersearchbar>
      </div>
      <table class="readerpanel-tabs-holder">
        <td
            v-if="tocPresent"
            id="readerpanel-tab-toc"
            class="readerpanel-tabs-tab"
            :class="activeTab === 'toc' ? 'readerpanel-tabs-tab-active' : ''"
            @click="openTab('toc')"
          ><img src="/img/icons/reader_toc.png" /></td>
        <td
            id="readerpanel-tab-metadata"
            class="readerpanel-tabs-tab"
            :class="activeTab === 'metadata' ? 'readerpanel-tabs-tab-active' : ''"
            @click="openTab('metadata')"
          ><img src="/img/icons/information.png" /></td>
        <td
            v-if="mimetype === 'pdf'"
            id="readerpanel-tab-thumbnails"
            class="readerpanel-tabs-tab"
            :class="activeTab === 'thumbnails' ? 'readerpanel-tabs-tab-active' : ''"
            @click="openTab('thumbnails')"
          ><img src="/img/icons/reader_thumbnails.png" /></td>
        <td
            id="readerpanel-tab-searchresults"
            class="readerpanel-tabs-tab"
            :class="activeTab === 'searchresults' ? 'readerpanel-tabs-tab-active' : ''"
            @click="openTab('searchresults')"
          ><img src="/img/icons/reader_search.png" /></td>
        <td
            id="readerpanel-tab-print"
            class="readerpanel-tabs-tab"
            :class="activeTab === 'print' ? 'readerpanel-tabs-tab-active' : ''"
            @click="openTab('print')"
          ><img src="/img/icons/reader_print.png" /></td>
        <td
            v-if="isMobile"
            id="readerpanel-tab-settings"
            class="readerpanel-tabs-tab"
            :class="activeTab === 'settings' ? 'readerpanel-tabs-tab-active' : ''"
            @click="openTab('settings')"
          ><img src="/img/icons/reader_settings.png" /></td>
      </table>
      <div class="readerpanel-tabs-content">
        <xposi-toclist v-show="activeTab === 'toc'" id="readerpanel-content-toc" ref="toc"></xposi-toclist>
        <xposi-readermetadata v-show="activeTab === 'metadata'" id="readerpanel-content-metadata" ref="metadata">Metadata</xposi-readermetadata>
        <xposi-thumbnaillist v-show="activeTab === 'thumbnails'" id="readerpanel-content-thumbnails" ref="thumbnails"></xposi-thumbnaillist>
        <xposi-readersearchresults v-show="activeTab === 'searchresults'" id="readerpanel-content-searchresults" ref="searchresults">Searchresults</xposi-readersearchresults>
        <xposi-readerprint v-show="activeTab === 'print'" id="readerpanel-content-print" ref="print">Print</xposi-readerprint>
        <xposi-readersettings v-show="activeTab === 'settings'" id="readerpanel-content-settings" ref="settings"></xposi-readersettings>
      </div>
    </div>
    <div v-show="!expanded" class="readerpanel-shortcuts">
      <div
          v-if="tocPresent"
          id="readerpanel-shortcut-toc"
          class="readerpanel-shortcuts-shortcut"
          @click="expand('toc')"
      ><img src="/img/icons/reader_toc.png" /></div>
      <div
          id="readerpanel-shortcut-metadata"
          class="readerpanel-shortcuts-shortcut"
          @click="expand('metadata')"
      ><img src="/img/icons/information.png" /></div>
      <div
          v-if="mimetype === 'pdf'"
          id="readerpanel-shortcut-thumbnails"
          class="readerpanel-shortcuts-shortcut"
          @click="expand('thumbnails')"
      ><img src="/img/icons/reader_thumbnails.png" /></div>
      <div
          id="readerpanel-shortcut-searchresults"
          class="readerpanel-shortcuts-shortcut"
          @click="expand('searchresults')"
      ><img src="/img/icons/reader_search.png" /></div>
      <div
          id="readerpanel-shortcut-print"
          class="readerpanel-shortcuts-shortcut"
          @click="expand('print')"
        ><img src="/img/icons/reader_print.png" /></div>
      <div
          v-if="isMobile"
          id="readerpanel-shortcut-settings"
          class="readerpanel-shortcuts-shortcut"
          @click="expand('settings')"
      ><img src="/img/icons/reader_settings.png" /></div>
    </div>
  </div>
</template>

<script>
import ScreenSizeMixin from '@/mixins/screensize'
import TocList from '@/components/reader/TocList.vue'
import ReaderSettings from '@/components/reader/ReaderSettings.vue'
import ReaderMetadata from '@/components/reader/ReaderMetadata.vue'
import ThumbnailList from '@/components/reader/ThumbnailList.vue'
import ReaderSearchResults from '@/components/reader/ReaderSearchResults.vue'
import ReaderPrint from '@/components/reader/ReaderPrint.vue'
import ReaderSearchBar from '@/components/reader/ReaderSearchBar.vue'
import { Events } from '@/events'

export default {
  data () {
    return {
      activeTab: 'toc',
      tocPresent: false
    }
  },
  props: {
    expanded: Boolean
  },
  computed: {
    mimetype () {
      return this.$store.getters['reader/getMimetype']
    },
    toc () {
      return this.$store.getters['reader/getTocTree']
    }
  },
  methods: {
    expand (withTabName) {
      if (withTabName) this.openTab(withTabName)
      Events.$emit('readerPanelExpand')
    },
    collapse () {
      Events.$emit('readerPanelCollapse')
    },
    openTab (name) {
      this.activeTab = name
      if (this.$refs[name] && this.$refs[name].activate) this.$refs[name].activate()
    },
    onReaderLoaded () {
      if (!this.toc.length && this.mimetype === 'pdf') {
        this.tocPresent = false
        if (!this.isMobile) {
          this.expand('thumbnails')
        }
      } else {
        this.tocPresent = true
        if (!this.isMobile) {
          this.expand('toc')
        }
      }
    }
  },
  mounted () {
    Events.$on('readerLoaded', this.onReaderLoaded)
    Events.$on('readerPanelOpenTab', this.expand)
  },
  beforeUnmount () {
    Events.$off('readerLoaded', this.onReaderLoaded)
    Events.$off('readerPanelOpenTab', this.expand)
  },
  mixins: [ScreenSizeMixin],
  components: {
    'xposi-toclist': TocList,
    'xposi-readersettings': ReaderSettings,
    'xposi-readermetadata': ReaderMetadata,
    'xposi-thumbnaillist': ThumbnailList,
    'xposi-readersearchresults': ReaderSearchResults,
    'xposi-readerprint': ReaderPrint,
    'xposi-readersearchbar': ReaderSearchBar
  }
}
</script>

<style>

</style>
