<template>
  <div class="reader-thumbnails-container">
    <div v-show="scrolling" class="reader-thumbnails-scrolllabel"></div>
    <div class="reader-thumbnails-list" ref="thumbnail-list" @scroll="handleScroll">
      <div
          v-for="page in numPages"
          class="reader-thumbnail-container"
          ref="thumbnail-item"
          @click="openPage(page)"
          :key="page"
          :class="(page === currentPage) ? 'reader-thumbnail-current' : ''"
        >
        <xposi-lazyloadingimg class="reader-thumbnail-image" :url="getThumbnailUrlForPage(page)" ref="thumbnail-image">
          <template v-slot:status-default>
            <div class="reader-thumbnail-default" style="height: 200px"></div>
          </template>
          <template v-slot:status-loading>
            <div class="reader-thumbnail-loading"><img src="/img/anim_loading.gif"/></div>
          </template>
        </xposi-lazyloadingimg>
        <div class="reader-thumbnail-index">{{ page }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyLoadingImg from '@/components/LazyLoadingImg.vue'
import { Events } from '@/events'

export default {
  data () {
    return {
      activationMargin: 10,
      scrollTimer: 100,
      scrollTimeout: null,
      scrolling: false,
      THUMBS_PER_ROW: 3
    }
  },
  computed: {
    appearance () {
      return this.$store.getters['reader/getAppearance']
    },
    numPages () {
      if (!this.appearance || !this.appearance.numPages) return 0
      return this.appearance.numPages
    },
    currentPage () {
      return this.$store.getters['reader/getCurrentPage']
    }
  },
  components: {
    'xposi-lazyloadingimg': LazyLoadingImg
  },
  methods: {
    getThumbnailUrlForPage (page) {
      if (!page) return null
      const docKey = (this.appearance && this.appearance.docKey) ? this.appearance.docKey : null
      if (!docKey) return null
      const url = `${window.globals.VUE_APP_APIBASE}/appearances/${docKey}/${page}.png`
      return url
    },
    handleScroll () {
      this.scrolling = true

      const scrollLabel = this.$el.querySelector('.reader-thumbnails-scrolllabel')
      if (scrollLabel) {
        const listEl = this.$refs['thumbnail-list']
        const listHeight = listEl.scrollHeight
        const scrollTop = listEl.scrollTop
        const thumbListLength = this.$refs['thumbnail-item'].length

        const heightPerRow = Math.floor(listHeight / Math.ceil(thumbListLength / this.THUMBS_PER_ROW))
        const rowPosition = Math.ceil(scrollTop / heightPerRow)
        const thumbPosition = rowPosition * this.THUMBS_PER_ROW + 1

        scrollLabel.innerHTML = thumbPosition
      }

      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(this.handleActivation, this.scrollTimer)
    },
    handleActivation () {
      const rootEl = this.$refs['thumbnail-list']
      const thumbnails = this.$refs['thumbnail-image']
      const scrollTop = rootEl.scrollTop
      const scrollBottom = scrollTop + rootEl.offsetHeight

      this.scrolling = false

      if (!scrollBottom) return

      for (let i = 0; i < thumbnails.length; i++) {
        const thumbnail = thumbnails[i]
        const item = thumbnail.$el.parentElement
        const itemTop = item.offsetTop
        const itemBottom = item.offsetTop + item.offsetHeight

        if ((itemTop > (scrollTop - this.activationMargin) && itemTop < (scrollBottom + this.activationMargin)) || (itemBottom > (scrollTop - this.activationMargin) && itemBottom < (scrollBottom + this.activationMargin))) {
          thumbnail.loadImage()
        }
      }
    },
    openPage (page) {
      Events.$emit('readerNavigateToPage', page)
    },
    scrollToIndex (index) {
      if (!this.numPages) return

      const items = this.$refs['thumbnail-item']

      if (index > items.length) return

      const item = items[index]
      if (!item) return
      const itemTop = item.offsetTop
      const itemHeight = item.offsetHeight

      const rootEl = this.$refs['thumbnail-list']
      const scrollHeight = rootEl.offsetHeight

      rootEl.scrollTop = itemTop + itemHeight * 0.5 - scrollHeight * 0.5
    },
    onReaderNavigated () {
      this.scrollToIndex(this.currentPage - 1)
      this.handleActivation()
    },
    activate () {
      setTimeout(() => {
        this.scrollToIndex(this.currentPage - 1)
        this.handleActivation()
      }, 100)
    }
  },
  mounted () {
    Events.$on('readerNavigated', this.onReaderNavigated)
  },
  beforeUnmount () {
    Events.$off('readerNavigated', this.onReaderNavigated)
  }
}
</script>

<style>

</style>
