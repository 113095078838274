<template>
  <div class="shop-basket-total">
    <div v-for="(price, currency, index) in totalPrice"
          :key="index"
          class="shop-basket-total-child">
          <div class="shop-basket-total-price-end">&nbsp;</div>
          <div v-if="index === 0" class="shop-basket-total-price-label">{{ $t('shop.basket.total') }} {{ $t('common.excluding-tax') }}:</div>
          <div v-else class="shop-basket-total-price-label">&nbsp;</div>
          <div v-if="index === 0" class="shop-basket-total-price-first">{{ price }}</div>
          <div v-else  class="shop-basket-total-price">+ {{ price }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    books: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    totalPrice () {
      const prices = {}
      for (const book of this.books) {
        const currency = book.currency
        if (!prices[currency]) {
          prices[currency] = book.price
          continue
        }
        prices[currency] += book.price
      }
      const priceWithCurrency = {}
      const availableCurrencies = Object.keys(prices)
      for (const availableCurrency of availableCurrencies) {
        priceWithCurrency[availableCurrency] = this.$filters.currency(prices[availableCurrency], availableCurrency)
      }
      return priceWithCurrency
    }
  }
}
</script>

<style>
</style>
