<template>
  <div class="reader-searchbar-container">
      <input
        type="search"
        class="input-text reader-searchbar-bar"
        ref="readerSearchBar"
        :placeholder="$t('Search in this document')"
        @keypress.enter="executeSearch()"
        @focus="openSearchModeDialog()" />
      <button class="input-button input-button-primary reader-searchbar-button" @click="executeSearch()">
        <img src="/img/icons/search_white.png" />
      </button>
      <div v-show="searchModeDialogOpened" class="reader-advancedsearch-container">
        <div>
          <span class="reader-advancedsearch-label">{{ $t('Search for') }}: </span>
          <span class="reader-advancedsearch-row">
            <label class="reader-advancedsearch-option">
              <input type="radio" :name="'context'+contextSwitchId" v-model="searchContext" value="words"> {{ $t('Seperate words') }}
            </label>
            <label class="reader-advancedsearch-option">
              <input type="radio" :name="'context'+contextSwitchId" v-model="searchContext" value="phrase"> {{ $t('Exact sentence') }}
            </label>
          </span>
        </div>
      </div>
  </div>
</template>

<script>
import { Events } from '@/events'
import { v4 as uuidv4 } from 'uuid'

export default {
  data () {
    return {
      contextSwitchId: uuidv4(),
      searchModeDialogOpened: false
    }
  },
  computed: {
    searchContext: {
      get () {
        return this.$store.getters['reader/getSearchMode']
      },
      set (searchMode) {
        this.$store.commit('reader/storeSearchMode', searchMode)
      }
    }
  },
  methods: {
    executeSearch () {
      const query = this.$refs.readerSearchBar.value.trim() || ''
      this.$refs.readerSearchBar.value = query

      Events.$emit('readerSearchExecute', query)
      Events.$emit('readerPanelOpenTab', 'searchresults')
    },
    openSearchModeDialog () {
      this.searchModeDialogOpened = true
      document.addEventListener('click', this.handleSearchModeDialogClick)
    },
    closeSearchModeDialog () {
      this.searchModeDialogOpened = false
    },
    handleSearchModeDialogClick (e) {
      if (!this.$el.contains(e.target)) {
        document.removeEventListener('click', this.handleSearchModeDialogClick)
        this.closeSearchModeDialog()
      }
    },
    handleReaderSearchExecute (query) {
      this.$refs.readerSearchBar.value = query
    }
  },
  mounted () {
    Events.$on('readerSearchExecute', this.handleReaderSearchExecute)
  },
  beforeUnmount () {
    Events.$off('readerSearchExecute', this.handleReaderSearchExecute)
  }
}
</script>

<style>
</style>
