<template>
  <div class="image-upload">
    <img
      v-if="previewBlobUrl"
      class="image-upload-preview"
      :src="previewBlobUrl"
      :width="previewWidth"
      :height="previewHeight"
      >
    <input
      type="file"
      class="image-upload-file-input"
      ref="file-input"
      :accept="acceptedMimetypes.join(',')"
      @change="onImageUpload"
      />
    <div class="image-upload-controls">
      <button
        v-if="uploadedFile"
        class="input-button input-button-primary"
        @click="save">{{ capitalize($t('save')) }}</button>
      <button
        class="input-button"
        :class="[uploadedFile ? 'input-button-outlined' : 'input-button-primary']"
        @click="$refs['file-input'].click()"
        >{{ capitalize($t('select_image')) }}...</button>
      <button
        v-if="uploadedFile"
        class="input-button"
        @click="reset"
        >{{ capitalize($t('cancel')) }}</button>
    </div>
    <div v-if="errorMessage" class="image-upload-error-container">
      <span class="image-upload-error-message">{{ errorMessage}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'image-upload',
  emits: ['save'],
  props: {
    previewWidth: {
      type: [Number, null],
      default: null
    },
    previewHeight: {
      type: [Number, null],
      default: null
    }
  },
  data: () => ({
    acceptedMimetypes: ['image/bmp', 'image/jpeg', 'image/png', 'image/x-png', 'image/gif'],
    previewBlobUrl: null,
    uploadedFile: null,
    errorMessage: null
  }),
  methods: {
    onImageUpload (changeEvent) {
      const { target } = changeEvent
      if (target.files.length < 0) {
        this.errorMessage = this.capitalize(this.$t('error_image_no_file'))
        return
      }

      const file = target.files[0]
      if (!this.acceptedMimetypes.includes(file.type)) {
        this.errorMessage = this.capitalize(this.$t('error_image_wrong_type', {
          exampleFileTypes: '.bmp, .jpg, .jpeg, .png, .gif'
        }))
        return
      }

      const blobUrl = URL.createObjectURL(file)
      this.previewBlobUrl = blobUrl
      this.uploadedFile = file
      this.errorMessage = null
    },
    reset () {
      this.$refs['file-input'].value = null
      this.previewBlobUrl = null
      this.uploadedFile = null
      this.errorMessage = null
    },
    save () {
      if (!this.uploadedFile) return
      this.$emit('save', this.uploadedFile)
    }
  }
}
</script>

<style lang="scss" scoped>
  @use "sass:color";
  @import "../../assets/css/_constants";

  .image-upload {
    &-preview {
      margin-bottom: 10px;
    }

    &-file-input {
      position: absolute;
      left: -99999px
    }

    &-error {
      &-container {
        margin-top: 10px;
      }

      &-message {
        display: inline-block;
        padding: 10px;
        border: 1px solid $red;
        background: color.change($red, $alpha: 0.2);
        border-radius: 4px;
      }
    }
  }
</style>
