<template>
  <div class="orderedlist-container">
    <div class="orderedlist-controls">
      <custom-select
        class="admin-input-select"
        v-model="selectedOption"
        :options="options"
        :disableLabelTranslation="disableLabelTranslation"
        ></custom-select>
      <button
        class="input-button input-button-primary"
        @click="addSelectedOptionToList"
        :disabled="list.length >= maxItemCount"
        >{{ capitalize($t('actions.add')) }}</button>
    </div>
    <div class="orderedlist-list">
      <div
        v-for="item of parsedListItems"
        class="orderedlist-item"
        :class="{ 'orderedlist-item-invalid': !item.active }"
        :key="item.index"
        :title="item.active ? '' : capitalize($t('valueNotSupported'))"
        >
        <div class="orderedlist-item-label">
          {{
            item.active
              ? disableLabelTranslation
                ? item.label
                : $t(item.label)
              : item.value
          }}
        </div>
        <div class="orderedlist-item-move">
          <button
            v-if="item.index > 0"
            class="input-button input-button-compact"
            @click="moveItemUp(item.index)"
            :title="capitalize($t('actions.moveUp'))"
            >
            <img class="input-button-image input-button-image-compact" src="/img/icons/arrow_up.png" />
          </button>
        </div>
        <div class="orderedlist-item-move">
          <button
            v-if="item.index < parsedListItems.length - 1"
            class="input-button input-button-compact"
            @click="moveItemDown(item.index)"
            :title="capitalize($t('actions.moveDown'))"
            >
            <img class="input-button-image input-button-image-compact" src="/img/icons/arrow_down.png" />
          </button>
        </div>
        <div class="orderedlist-item-delete">
          <button
            class="input-button input-button-compact input-button-danger"
            @click="removeItem(item.index)"
            :title="capitalize($t('actions.delete'))"
            >&#9587;</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from './CustomSelect'

export default {
  name: 'ordered-list',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    disableLabelTranslation: {
      type: Boolean,
      default: () => false
    },
    maxItemCount: {
      type: [Number, null],
      default: null
    }
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue (value) {
      this.list = [...value]
    }
  },
  computed: {
    parsedListItems () {
      const items = []

      for (let i = 0; i < this.list.length; i++) {
        const value = this.list[i]
        const option = this.options.find((o) => o.value === value)

        const item = {
          index: i,
          value,
          label: option?.label ?? null,
          active: !!option
        }

        items.push(item)
      }

      return items
    }
  },
  data: () => ({
    list: [],
    selectedOption: null
  }),
  methods: {
    sendUpdate () {
      this.$emit('update:modelValue', this.list)
    },
    addSelectedOptionToList () {
      if (!this.selectedOption) return
      if (this.maxItemCount && this.list.length >= this.maxItemCount) return
      this.list.push(this.selectedOption)
      this.sendUpdate()
    },
    removeItem (index) {
      this.list = this.list.filter((_, i) => i !== index)
      this.sendUpdate()
    },
    moveItemUp (index) {
      if (index < 1) return

      const value = this.list[index]
      this.list.splice(index, 1)
      this.list.splice(index - 1, 0, value)
      this.sendUpdate()
    },
    moveItemDown (index) {
      if (index >= this.list.length - 1) return

      const value = this.list[index]
      this.list.splice(index, 1)
      this.list.splice(index + 1, 0, value)
      this.sendUpdate()
    }
  },
  mounted () {
    this.list = [...this.modelValue]
  },
  components: {
    'custom-select': CustomSelect
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .orderedlist {
    &-controls {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 10px;
    }

    &-list {
      width: 500px;
      height: 260px;
      border: 1px solid $grey_middle;
      border-radius: 4px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 0 4px 10px;
      border-bottom: 1px solid $grey_middle;

      &-invalid {
        background-color: #eee;
      }

      &-label {
        flex-grow: 1;
        flex-shrink: 0;
        padding-right: 10px;
      }

      &-move {
        width: 36px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &-delete {
        flex-grow: 0;
        flex-shrink: 0;
        padding-left: 10px;
      }
    }
  }
</style>
