class EventBus {
  constructor () {
    this.events = {}
  }

  $on (eventName, eventFunction) {
    if (!this.events[eventName]) this.events[eventName] = []
    this.events[eventName].push(eventFunction)
  }

  $off (eventName, eventFunction) {
    if (!this.events[eventName]) return

    this.events[eventName] = this.events[eventName].filter(fn => fn !== eventFunction)
  }

  $emit (eventName, eventData) {
    if (!this.events[eventName]) return

    for (const eventFunction of this.events[eventName]) {
      eventFunction(eventData)
    }
  }
}

export const Events = new EventBus()
