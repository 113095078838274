<template>
  <div class="popup-background">
    <div class="popup-window">
      <div v-if="state === STATES.INITIAL"></div>
      <div v-if="state === STATES.LOADING" class="popup-center">
        <spinner :size="40" :width="4"></spinner>
      </div>
      <div v-if="state === STATES.READY">
        <div class="popup-header">
          <h1 class="popup-title">{{ capitalize($t('request')) }}</h1>
          <div class="request-title">{{ metadata.title }}</div>
        </div>
        <div v-if="validationError" class="form-row">
          <div class="validationerror-block">{{ validationError }}</div>
        </div>
        <div class="form-row">
          <label for="fromName">{{ capitalize($t('email_request.name')) }}*</label>
          <input type="text" v-model="fromName" id="fromName" class="input-text input-fullwidth" />
        </div>
        <div class="form-row">
          <label for="fromEmail">{{ capitalize($t('email_request.email')) }}*</label>
          <input type="text" v-model="fromEmail" id="fromEmail" class="input-text input-fullwidth" />
        </div>
        <div class="form-row">
          <label for="department">{{ capitalize($t('email_request.department')) }}</label>
          <input type="text" v-model="department" id="department" class="input-text input-fullwidth" />
        </div>
        <div class="form-row">
          <label for="message">{{ capitalize($t('email_request.message')) }}</label>
          <textarea v-model="message" id="message" class="input-text input-fullwidth" />
        </div>
        <div class="form-row">
          <button @click="send" class="input-button input-button-primary" :disabled="this.sending">{{ capitalize($t('email_request.send')) }}</button>
        </div>
      </div>
      <div v-if="state === STATES.ERROR">
        {{ capitalize($t('The requested publication could not be found')) }}
      </div>
      <div class="popup-close" @click="close">&#10006;</div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/utilities/Spinner'
import STATES from '@/constants/states'
import axiosAPI from '@/axiosAPI'

export default {
  name: 'modal-request',
  emits: ['close'],
  data: () => ({
    state: STATES.INITIAL,
    publicationId: null,
    connectionId: null,
    fromName: '',
    fromEmail: '',
    message: '',
    department: '',
    metadata: {},
    validationError: null,
    sending: false,
    STATES
  }),
  methods: {
    reset () {
      this.state = STATES.INITIAL
      this.publicationId = null
      this.connectionId = null
      this.fromName = ''
      this.fromEmail = ''
      this.message = ''
      this.department = ''
      this.validationError = null
      this.metadata = {}
      this.sending = false
    },
    async load ({ publication, connection }) {
      this.reset()

      this.publicationId = publication
      this.connectionId = connection
      this.state = STATES.LOADING

      try {
        const metadata = await this.$store.dispatch(`searchStore/searchModules/${connection}/searchDetail`, publication)

        if (!metadata) {
          this.state = STATES.ERROR
          this.metadata = null
          return
        }

        this.metadata = metadata
        this.state = STATES.READY
      } catch {
        this.state = STATES.ERROR
      }
    },
    validateEmail (email) {
      const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
      return re.test(email)
    },
    async send () {
      if (this.state !== STATES.READY || this.sending) return

      if (!this.fromName) {
        this.validationError = this.capitalize(`${this.$t('email_request.name')} ${this.$t('email_request.is_required')}`)
        return false
      }

      if (!this.fromEmail) {
        this.validationError = this.capitalize(`${this.$t('email_request.email')} ${this.$t('email_request.is_required')}`)
        return false
      }

      if (!this.validateEmail(this.fromEmail)) {
        this.validationError = this.capitalize(`${this.$t('email_request.email')} ${this.$t('email_request.is_invalid')}`)
        return false
      }

      this.validationError = null
      this.sending = true

      const requestBody = {
        requesterName: this.fromName,
        requesterEmail: this.fromEmail,
        publicationId: this.publicationId,
        connectionId: this.connectionId,
        language: this.$store.state.language
      }

      if (this.department) requestBody.department = this.department
      if (this.message) requestBody.message = this.message

      try {
        const result = await axiosAPI.post('/module/requestpublication', requestBody, {
          responseType: 'json'
        })

        if (result.status < 200 || result.status >= 300) {
          this.validationError = this.capitalize(this.$t('Something went wrong while sending the request, pleasy try again later'))
          this.sending = false
          return false
        }

        this.sending = false
        this.close()
      } catch (error) {
        this.validationError = this.capitalize(this.$t('Something went wrong while sending the request, pleasy try again later'))
        this.sending = false
      }
    },
    close () {
      this.$emit('close')
    }
  },
  components: {
    spinner: Spinner
  }
}
</script>

<style lang="scss" scoped>
  .request-title {
    margin-bottom: 20px;
  }

  .form-row {
    margin: 10px 0;

    label {
      display: block;
    }
  }

  .input-fullwidth {
    width: 100%;
  }

  .validationerror-block {
    padding: 10px;
    border: 1px solid red;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
  }
</style>
