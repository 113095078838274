<template>
  <div class="menu">
    <div v-if="userHasUserRole" class="menu-item">
      <div class="menu-user-name">{{session.user.name.full}}</div>
      <div class="menu-user-email" :title="session.user.email">{{session.user.email}}</div>
    </div>
    <div class="menu-item" :class="{ 'menu-submenu-trigger': session.client.switch.length > 1 }">
      <div class="menu-label">{{capitalize($t('client'))}}</div>
      <div>{{session.client.current.name}}</div>
      <div v-if="session.client.switch.length > 1" class="menu-submenu">
        <div
          v-for="client of session.client.switch"
          :key="client.ref"
          @click="switchClient(client.ref)"
          class="menu-button"
          >
          {{client.name}}
        </div>
      </div>
    </div>
    <div class="menu-item" :class="{ 'menu-submenu-trigger': languageOptions.length > 1 }">
      <div class="menu-label">{{capitalize($t('language'))}}</div>
      <div>{{currentLanguage.flag}} {{capitalize($t(`languages.${currentLanguage.key}`))}}</div>
      <div v-if="languageOptions.length > 1" class="menu-submenu">
        <div
          v-for="language of languageOptions"
          :key="language.key"
          @click="setLanguage(language.key)"
          class="menu-button"
          >
          {{language.flag}} {{capitalize($t(`languages.${language.key}`))}}
        </div>
      </div>
    </div>
    <button v-if="userHasAdminRole" class="menu-button" @click="openAdmin">{{ capitalize($t('header.admin')) }}</button>
    <button class="menu-button" @click="openAbout">{{ capitalize($t('header.about')) }}</button>
    <button v-if="!isPublicLibrary" class="menu-button" @click="logout">{{ capitalize($t('header.logout')) }}</button>
  </div>
</template>

<script>
import { Events } from '@/events'
import { generateLanguageSelectOptions } from '@/utilities/languagehelper'
import VueCookies from 'vue-cookies'
import axiosAPI from '@/axiosAPI'

export default {
  name: 'usermenu',
  computed: {
    session () {
      return this.$store.getters.getSession
    },
    appVersion () {
      return this.$store.getters.getAppVersion
    },
    languageOptions () {
      const availablelanguages = this.$i18n.availableLocales
      return generateLanguageSelectOptions(availablelanguages)
    },
    currentLanguage () {
      const languageKey = this.$store.state.language
      const languageData = { flag: '', key: languageKey }
      if (!this.languageOptions) return languageData

      const languageOption = this.languageOptions.find(item => item.key === languageKey)
      if (languageOption) languageData.flag = languageOption.flag
      return languageData
    },
    userHasUserRole () {
      return this.$store.getters.userHasUserRole
    },
    userHasAdminRole () {
      return this.$store.getters.userHasAdminRole
    },
    isPublicLibrary () {
      return this.$store.getters.isPublicLibrary
    }
  },
  methods: {
    async switchClient (value) {
      if (value === this.session.client.current.ref) return

      try {
        const result = await axiosAPI.post('/client/switch', {
          target: value
        }, {
          responseType: 'json'
        })

        if (!result.data) return this.openSwitchErrorDialog()

        window.location.href = window.globals.VUE_APP_HOST
      } catch (e) {
        this.openSwitchErrorDialog()
      }
    },
    openSwitchErrorDialog () {
      Events.$emit('modalOpen', {
        type: 'error',
        icon: 'server.png',
        title: 'Could not switch client',
        body: 'Something went wrong while switching client, please try again later',
        actions: [
          {
            label: 'Close',
            class: 'input-button-primary',
            action () { Events.$emit('modalClose') }
          }
        ],
        requireAction: true
      })
    },
    setLanguage (value) {
      this.$store.dispatch('changeLanguage', { language: value, i18n: this.$i18n, store: true })
    },
    openAbout () {
      const currentYear = new Date().getFullYear()
      const contents = `
        <div style="margin: 20px 0 0 10px; text-align: center;">
          <img src="/img/hl-logo-full.svg" style="width: 250px;"/>
        </div>
        <div style="text-align: center; font-size: 1em; padding: 15px">
          ${this.$t('Version')} <strong>${this.appVersion}</strong>
        </div>
        <div style="text-align: center; color: #999999; font-size: 11px; margin-bottom: 20px;">
          &copy; ${currentYear} Square Information Solutions BV
        </div>
      `

      Events.$emit('modalOpen', {
        type: 'none',
        icon: '',
        title: '',
        body: contents,
        actions: [
          {
            label: this.$t('Close'),
            class: 'input-button-primary',
            action () { Events.$emit('modalClose') }
          }
        ],
        requireAction: true
      })
    },
    openBookshelves () {
      this.$router.push({ name: 'bookshelf' })
    },
    openAdmin () {
      this.$router.push({ name: 'admin' })
    },
    async logout () {
      if (this.$route.name === 'reader') {
        await this.$store.dispatch('reader/APILicenseRelease', this.$store.getters['reader/getLicenseKey'], { root: true })
      }

      VueCookies.remove('accessToken')
      VueCookies.remove('refreshToken')
      this.$store.dispatch('clearAuth')

      window.location = `${window.globals.VUE_APP_SSOURL}/logout`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/css/_constants";

  .menu {
    width: 240px;
    background-color: white;
    font-weight: normal;
    cursor: default;

    &-label {
      font-size: calc($font-size - 2px);
      font-weight: bold;
    }

    &-item {
      padding: 9px 13px;
    }

    &-button {
      background: none;
      border: none;
      width: 100%;
      font-family: $font;
      font-size: $font_size;
      padding: 9px 13px;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      color: black;
      text-align: left;
      font-weight: normal;
      white-space: nowrap;

      &:hover {
        background-color: $grey_light;
      }
    }

    & > *:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    &-submenu {
      display: none;
      position: absolute;
      top: 9px;
      right: 100%;
      background-color: white;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

      & > *:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }

    &-submenu-trigger {
      padding-left: 20px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 4px;
        width: 0;
        height: 0;
        border: none;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 6px solid #aaa;
      }

      &:hover {
        &::after {
          border-right-color: #444;
        }

        & > .menu-submenu {
          display: block;
        }
      }
    }

    &-user {
      &-name {
        font-size: calc($font_size + 2px);
        font-weight: bold;
        padding-bottom: 2px;
      }

      &-email {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
</style>
