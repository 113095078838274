<template>
  <div class="bookshelf-container">
    <div v-if="state === STATES.LOADING" class="bookshelf-loading">
      <spinner :size="60" :width="6"></spinner>
    </div>
    <div class="bookshelfmenu">
      <div v-if="externalBookshelf" class="title">{{ capitalize($t('bookshelf.externalBookshelves')) }}</div>
      <side-menu-item
        v-if="externalBookshelf"
        :to="`/bookshelf/${externalBookshelf.id}`"
        :icon="'/img/icons/bookshelf.png'"
        :title="externalBookshelf.name"
        :active="bookshelfId === externalBookshelf.id"
        style="margin-bottom: 10px;"
      />
      <div class="title-container">
          <div class="title">{{ capitalize($t('bookshelf.myBookshelves')) }}</div>
          <button
            v-if="userHasUserRole"
            class="input-button input-button-compact"
            @click="openNewBookshelfModal"
            :title="capitalize($t('actions.add'))"
            >
              <img src="/img/icons/add.png" class="input-button-image input-button-image-compact"/>
          </button>
      </div>
      <side-menu-item
        v-for="shelf of bookshelves"
        :to="`/bookshelf/${shelf.id}`"
        :icon="'/img/icons/bookshelf.png'"
        :title="shelf.name"
        :active="bookshelfId === shelf.id"
        :options="userHasUserRole ? [
          { 'label': capitalize($t('actions.edit')), 'action': () => { openEditBookshelfModal(shelf.id, shelf.name) } },
          { 'label': capitalize($t('actions.delete')), 'action': () => { openDeleteBookshelfModal(shelf.id, shelf.name) } },
        ] : null"
        :key="shelf.id"
      />
    </div>
    <div class="bookshelfcontent-container">
      <router-view v-if="state === STATES.READY"></router-view>
      <div v-if="state === STATES.INITIAL" class="bookshelf-initial">
        <div>{{ capitalize($t('bookshelf.emptyMessage')) }}</div>
        <div v-if="userHasUserRole" style="margin-top: 10px;">
          <button class="input-button input-button-primary" @click="openNewBookshelfModal">{{ capitalize($t('actions.add')) }}</button>
        </div>
      </div>
      <div v-if="state === STATES.ERROR" class="bookshelf-error">
        <img src="/img/searching_500.png" />
        <div>{{ capitalize($t('bookshelf.bookshelfLoadError')) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/utilities/Spinner'
import SideMenuItem from '@/components/sidemenu/SideMenuItem'
import STATES from '@/constants/states'
import { mapState } from 'vuex'
import { Events } from '@/events'

export default {
  name: 'bookshelf',
  computed: {
    ...mapState('bookshelfStore', {
      bookshelves: state => state.bookshelves,
      externalBookshelf: state => state.external
    }),
    bookshelfId () {
      const id = parseInt(this.$route.params.id)
      return isNaN(id) ? null : id
    },
    userHasUserRole () {
      return this.$store.getters.userHasUserRole
    }
  },
  watch: {
    bookshelfId (newId, oldId) {
      if (newId === oldId) return
      if (!newId) this.state = this.STATES.INITIAL

      this.loadBookshelf(newId)
    }
  },
  data: () => ({
    state: STATES.INITIAL,
    error: null,
    STATES
  }),
  methods: {
    openNewBookshelfModal () {
      Events.$emit('openModalBookshelf')
    },
    openEditBookshelfModal (id, name) {
      const editData = { id, name }
      Events.$emit('openModalBookshelf', editData)
    },
    openDeleteBookshelfModal (id, name) {
      const bookshelfData = { id, name }
      Events.$emit('openModalBookshelfDelete', bookshelfData)
    },
    async loadBookshelf (id) {
      if (!id) return

      this.state = STATES.LOADING
      this.error = null

      const userBookshelf = this.$store.getters['bookshelfStore/getUserBookshelf'](id)
      if (userBookshelf) {
        this.$store.dispatch('bookshelfStore/clearExternalBookshelf')
        this.state = STATES.READY
        return
      }

      const externalBookshelf = await this.$store.dispatch('bookshelfStore/fetchExternalBookshelf', id)
      if (externalBookshelf.error) {
        this.state = STATES.ERROR
        this.error = 'ERROR while fetching bookshelf: ' + externalBookshelf.error
        return
      }

      this.state = STATES.READY
      return false
    },
    openFirstAvailableBookshelf () {
      if (this.bookshelves.length) {
        this.$router.push(`/bookshelf/${this.bookshelves[0].id}`)
      }
    }
  },
  mounted () {
    this.$store.dispatch('bookshelfStore/clearExternalBookshelf')

    if (this.bookshelfId) {
      this.loadBookshelf(this.bookshelfId)
    } else {
      this.openFirstAvailableBookshelf()
    }
  },
  beforeRouteUpdate (newRoute) {
    if (!newRoute.params?.id) {
      this.openFirstAvailableBookshelf()
    }
  },
  components: {
    spinner: Spinner,
    'side-menu-item': SideMenuItem
  }
}
</script>

<style lang="scss" scoped>
  @use "sass:color";
  @import "../assets/css/_constants";

  $menu_tab_padding: 10px;
  $menu_tab_border: 4px;

  .bookshelf-container {
    width: 100%;
    height: calc(100% - #{$header_height});
    box-shadow: 0 4px 4px -4px inset rgba(0, 0, 0, 0.4);
    display:flex;
    flex-direction: row;
  }

  .bookshelf-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1;
  }

  .bookshelf-initial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .bookshelf-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .bookshelfmenu {
    display: flex;
    height: 100%;
    width: $sidebar_width;
    padding: 20px;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;

    .title {
      font-weight: bold;
      font-size: ($font_size + 4);
      margin-bottom: 10px;

      &:not(:first-child) {
        margin-top: 14px;
      }

      &-container {
        display: flex;
        flex-direction: row;

        .title {
          flex-grow: 1;
        }
      }

      button {
        flex-shrink: 0;
        flex-grow: 0;
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start;
      width: 100%;
      border: none;
      background: none;
      font-family: $font;
      font-size: $font_size;
      text-align: left;
      padding: $menu_tab_padding;
      cursor: pointer;
      outline: 0;
      text-decoration: none;
      color: black;

      &-active {
        padding-left: $menu_tab_padding - $menu_tab_border;
        border-left: $menu_tab_border solid $main_light;
        background-color: color.change($main_light, $alpha: 0.2);
      }

      &:not(&-active):focus, &:not(&-active):hover {
        padding-left: $menu_tab_padding - $menu_tab_border;
        border-left: $menu_tab_border solid $grey_dark;
      }

      &-text {
        flex-grow: 1;
      }

      &-options {
        &-button {
          visibility: hidden;
          height: 20px;
          background: white;
          padding-left: 9px;
          padding-right: 9px;

          img {
            margin-left: -10px;
            margin-right: -10px;
          }
        }
      }

      &:hover {
        .item-options-button {
          visibility: visible;
        }
      }
    }
  }

  .bookshelfcontent-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
</style>
