<template>
  <div class="panel-fold-container">
    <div class="panel-fold-header-container" @click="toggleOpen">
      <h3>
        <span v-if="open" class="panel-fold-header-indicator">-</span>
        <span v-if="!open" class="panel-fold-header-indicator">+</span>
        <slot name="title"></slot>
      </h3>
    </div>
    <slot name="title-additional"></slot>
    <transition name="panel-fold-transition">
      <div v-show="open" class="panel-fold-content-container">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    defaultOpen: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      open: true
    }
  },
  methods: {
    toggleOpen () {
      this.open = !this.open
    }
  },
  created () {
    this.open = this.defaultOpen
  }
}
</script>

<style>
</style>
