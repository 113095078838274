<template>
  <div class="lazy-loading-img" :class="{ loaded: status === 'loaded' }">
    <slot v-if="status === 'default'" name="status-default">
      <div class="lli-slot-default"></div>
    </slot>
    <slot v-if="status === 'loading'" name="status-loading">
      <div class="lli-slot-loading"></div>
    </slot>
    <transition name="lazy-loading-img-transition">
      <slot v-if="status === 'failed'" name="status-failed">
        <div class="lli-slot-failed"></div>
      </slot>
    </transition>
    <transition name="lazy-loading-img-transition">
      <img v-show="status === 'loaded'" ref="image" class="lli-slot-loaded"/>
    </transition>
  </div>
</template>

<script>
import axiosAPI from '@/axiosAPI'

export default {
  data () {
    return {
      loaded: false,
      status: 'default', // default - loading - failed - loaded
      currentUrl: null
    }
  },
  methods: {
    imageLoadedSuccess () {
      this.status = 'loaded'
    },
    imageLoadedFailed () {
      this.status = 'failed'
    },
    loadImage () {
      if (this.status !== 'default') return
      if (this.url === this.currentUrl) return
      this.currentUrl = this.url
      this.status = 'loading'
      if (this.url === '') this.imageLoadedFailed()

      axiosAPI.get(this.url, {
        responseType: 'blob'
      }).then(({ data }) => {
        const objectUrl = URL.createObjectURL(data)
        const imageEl = this.$refs.image

        imageEl.onload = this.imageLoadedSuccess
        imageEl.onerror = this.imageLoadedFailed
        imageEl.src = objectUrl
      }).catch(() => {
        this.imageLoadedFailed()
      })
    }
  },
  props: {
    url: {
      default: '',
      type: String
    }
  }
}
</script>

<style>

</style>
