<template>
  <div>
    <div ref="zoomin" class="reader-navigation-button" :class="{'reader-navigation-button-disabled': !zoomInAvailable}" :title="$t('Zoom in')" @click="zoomIn">
      <img src="/img/icons/zoom_plus.png"/>
    </div>
    <div ref="zoomdefault" class="reader-navigation-button" :title="$t('Automatic zoom')" @click="zoomDefault">
      <img src="/img/icons/zoom_default.png"/>
    </div>
    <div ref="zoomout" class="reader-navigation-button" :class="{'reader-navigation-button-disabled': !zoomOutAvailable}" :title="$t('Zoom out')" @click="zoomOut">
      <img src="/img/icons/zoom_minus.png"/>
    </div>
  </div>
</template>

<script>
import { Events } from '@/events'

export default {
  data () {
    return {
      zoomInAvailable: true,
      zoomOutAvailable: true
    }
  },
  methods: {
    zoomIn () {
      if (!this.zoomInAvailable) return
      Events.$emit('readerZoomIn')
    },
    zoomOut () {
      if (!this.zoomOutAvailable) return
      Events.$emit('readerZoomOut')
    },
    zoomDefault () {
      Events.$emit('readerZoomDefault')
    }
  }
}
</script>

<style>

</style>
