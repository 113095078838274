<template>
  <RouterLink
    :to="to"
    class="sidemenuitem"
    :class="{ 'sidemenuitem-active': active }"
    >
      <img
        v-if="icon"
        :src="icon"
        width="20"
        height="20"
        alt="item"
      />
      <div class="sidemenuitem-text">{{ title }}</div>
      <div v-if="options">
        <button
          class="input-button input-button-compact sidemenuitem-options-button"
          @click.prevent="showOptions = true"
          :title="capitalize($t('email_request.message'))"
          >
            <img src="/img/icons/options.png" class="input-button-image input-button-image-compact"/>
        </button>
        <div
          v-if="showOptions"
          class="sidemenuitem-options-menu"
          @mouseleave="showOptions = false"
          >
            <div
              v-for="option of options"
              class="sidemenuitem-options-menu-item"
              @click.prevent="option.action"
              :key="option.label"
              >
                {{ option.label }}
            </div>
        </div>
      </div>
  </RouterLink>
</template>

<script>
export default {
  name: 'sidemenu-item',
  props: ['to', 'icon', 'title', 'active', 'options'],
  data: () => ({
    showOptions: false
  })
}
</script>

<style lang="scss" scoped>
  @use "sass:color";
  @import "../../assets/css/_constants";

  $menu_tab_padding: 10px;
  $menu_tab_border: 4px;

  .sidemenuitem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: $menu_tab_padding;
    background: none;
    color: black;
    border: none;
    font-family: $font;
    font-size: $font_size;
    text-align: left;
    cursor: pointer;
    outline: 0;
    text-decoration: none;

    &-active {
      padding-left: $menu_tab_padding - $menu_tab_border;
      border-left: $menu_tab_border solid $main_light;
      background-color: color.change($main_light, $alpha: 0.2);
    }

    &:not(&-active):focus, &:not(&-active):hover {
      padding-left: $menu_tab_padding - $menu_tab_border;
      border-left: $menu_tab_border solid $grey_dark;
    }

    &-text {
      flex-grow: 1;
    }

    &:hover {
      .sidemenuitem-options-button {
        visibility: visible;
      }
    }
  }

  .sidemenuitem .sidemenuitem-options {
    &-button {
      visibility: hidden;
      height: 20px;
      background: white;
      padding-left: 9px;
      padding-right: 9px;

      img {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    &-menu {
      position: absolute;
      bottom: 0;
      right: 0;
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

      &-item {
        min-width: 140px;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background: $grey_light;
        }
      }
    }
  }
</style>
