<template>
  <span class="searchable" @click="onClick">{{ value }}</span>
</template>

<script>
export default {
  name: 'publication-searchable',
  props: ['value', 'field'],
  methods: {
    onClick () {
      this.$store.dispatch('searchStore/searchExactValueAndField', {
        value: this.value,
        field: this.field
      })
      this.$router.push({ name: 'results' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .searchable {
    color: $main_dark;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
