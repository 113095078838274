class Collection {
  constructor (data) {
    this._data = []

    if (Array.isArray(data)) {
      // make a copy of the given data to get rid of the original data reference
      this._data = [...data]
    }

    this._collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
  }

  // sort the data on a given field (path) in either ascending/descending or an array of values where the 'rest' will be placed after the given values from a base dataset given by a baseDataPath path
  sortField (fieldPathInBaseData, sortType, baseDataPath = '') {
    const baseData = this._getValueFromPath(baseDataPath)
    if (!baseData) return this

    if (typeof sortType === 'string') {
      if (sortType === Collection.SORT_ASCENDING) {
        this._sortAscending(baseData, fieldPathInBaseData)
      } else if (sortType === Collection.SORT_DESCENDING) {
        this._sortDescending(baseData, fieldPathInBaseData)
      }
    } else if (Array.isArray(sortType)) {
      this._sortArray(baseData, sortType, fieldPathInBaseData)
    }

    return this
  }

  // return the data
  get () {
    return this._data
  }

  // given a path as string, search for the value in the data (e.g.: "attribute.anotherAttribute.0.id")
  _getValueFromPath (path, fromData) {
    const notFound = null

    if (typeof path !== 'string') return notFound
    if (!path) return this._data

    if (!fromData) fromData = this._data

    return path.split('.').reduce((prev, curr) => { return prev ? prev[curr] : notFound }, fromData)
  }

  // sort given data ascending on value of given field (path)
  _sortAscending (data, field = '') {
    data.sort((a, b) => {
      const aval = field ? this._getValueFromPath(field, a) : a
      const bval = field ? this._getValueFromPath(field, b) : b

      const rank = this._collator.compare(aval, bval)

      return rank === 0 ? 0 : rank > 0 ? 1 : -1
    })
  }

  // sort given data descending on value of given field (path)
  _sortDescending (data, field = '') {
    this._sortAscending(data, field)
    data.reverse()
  }

  // sort given data on value of given field (path) as order of appearance in given order array
  _sortArray (data, order = [], field = '') {
    data.sort((a, b) => {
      const aval = field ? this._getValueFromPath(field, a) : a
      const bval = field ? this._getValueFromPath(field, b) : b

      const aindex = order.indexOf(aval)
      const bindex = order.indexOf(bval)

      if (aindex === bindex) return 0
      if (bindex === -1) return -1

      return aindex < bindex ? -1 : 1
    })
  }
}

// Class attributes to be used with setting the type of sorting
Collection.SORT_ASCENDING = 'ascending'
Collection.SORT_DESCENDING = 'descending'

export default Collection
