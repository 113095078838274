<template>
  <div
    class="spinner"
    :style="{
      width: size + 'px',
      height: size + 'px',
      borderWidth: width + 'px'
    }"
  ></div>
</template>

<script>
export default {
  name: 'spinner',
  props: {
    size: {
      type: Number,
      default: 40
    },
    width: {
      type: Number,
      default: 4
    }
  }
}
</script>

<style lang="scss" scoped>
  .spinner {
    display: block;
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border-top-color: black;
    animation: spinnerAnimation 1s ease-in-out infinite;
  }

  @keyframes spinnerAnimation {
    to { -webkit-transform: rotate(360deg); }
  }
</style>
