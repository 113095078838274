<template>
  <div class="acquisition" :title="acquisition.title">
    <publication-cover ref="cover" :url="acquisition.cover"></publication-cover>
  </div>
</template>

<script>
import PublicationCover from '@/components/publication/PublicationCover'

export default {
  name: 'acquisition',
  props: {
    acquisition: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    load () {
      if (this.$refs.cover?.loadImage) this.$refs.cover.loadImage()
    }
  },
  components: {
    'publication-cover': PublicationCover
  }
}
</script>

<style lang="scss" scoped>
  .acquisition {
    width: 100px;
    height: 150px;
    margin: 0 10px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    }
  }
</style>
