<template>
  <div class="texteditor-container">
    <quill-editor
      :class="{ 'texteditor-disabled': readonly }"
      :options="editorOptions"
      v-model:content="editorContent"
      :placeholder="capitalize($t('placeholders.rich_text_editor'))"
    />
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import Delta from 'quill-delta'

import '@vueup/vue-quill/dist/vue-quill.snow.css'

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link'],
  ['clean']
]

// Controls which formats/elements can be used / pasted. If not here, the styling or element is stripped
// For example, we don't allow images or videos to be pasted, so we leave them out of the array.
const allowedFormats = ['bold', 'italic', 'underline', 'strike', 'link', 'header', 'indent', 'list']

export default {
  name: 'rich-text-editor',
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    content: {
      type: [String, null],
      default: null
    }
  },
  emits: ['update:content'],
  computed: {
    editorContent: {
      get () {
        try {
          return new Delta(JSON.parse(this.content ? this.content : null))
        } catch (_) {
          return null
        }
      },
      set (value) {
        this.$emit('update:content', JSON.stringify(value))
      }
    }
  },
  data: function () {
    return {
      editorOptions: {
        theme: 'snow',
        readOnly: this.readonly,
        modules: {
          toolbar: this.readonly ? false : toolbarOptions
        },
        formats: allowedFormats
      }
    }
  },
  components: {
    'quill-editor': QuillEditor
  }
}
</script>

<style lang="scss" scoped>
  .texteditor {
    &-container {
      width: auto;
    }
  }
</style>

<style lang="scss">
  .texteditor-disabled {
    border: none !important;
  }
</style>
