export default {
  "common.excluding-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excl. BTW"])},
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit document"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
  },
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
  "Try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw proberen"])},
  "Version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie"])},
  "Loading reader...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reader laden..."])},
  "Loading publication...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicatie laden..."])},
  "Loan period expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leentermijn verlopen"])},
  "The loan period for this document has expired. Choose \"request extension\" to request an extension, or \"close\" to close this document.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leentermijn voor dit document is verlopen. Kies \"verlenging aanvragen\" om een verlenging van de leentermijn aan te vragen, of \"sluiten\" om het document te sluiten."])},
  "The loan period is about to expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leentermijn is bijna verlopen"])},
  "The loan period is about to expire due to inactivity. Do you want to continue using this document or do you want to close it?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leentermijn is bijna verlopen door inactiviteit. Wil je dit document blijven gebruiken of sluiten?"])},
  "Continue using this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met dit document"])},
  "Request extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlenging aanvragen"])},
  "Close document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document sluiten"])},
  "Read online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online lezen"])},
  "In use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In gebruik"])},
  "Filter results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten filteren"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteren"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
  "Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trefwoorden"])},
  "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteurs"])},
  "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgever"])},
  "jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsgebieden"])},
  "yearofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar van uitgave"])},
  "Authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteurs"])},
  "Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "ISBN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
  "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgever"])},
  "Edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editie"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])},
  "Jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsgebied"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
  "Text size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekstgrootte"])},
  "Zoom in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitvergroten"])},
  "Zoom out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkleinen"])},
  "Automatic zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische grootte"])},
  "Navigate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren"])},
  "Navigate to the first page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar de eerste pagina"])},
  "Navigate to the last page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar de laatste pagina"])},
  "Navigate to the previous chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar het vorige hoofdstuk"])},
  "Navigate to the next chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar het volgende hoofdstuk"])},
  "Navigate to the previous page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar de vorige pagina"])},
  "Navigate to the next page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar de volgende pagina"])},
  "Searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met zoeken"])},
  "Search in this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken in dit document"])},
  "Current query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige zoekvraag"])},
  "There are no results for this query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen resultaten gevonden voor deze zoekvraag"])},
  "Stop search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken stoppen"])},
  "Use the searchbar to start searching in this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik de zoekbalk om te zoeken in dit document"])},
  "Search for title, author or text in a document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op titel, auteur of tekst in een document"])},
  "Search Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoekresultaat"])},
  "result found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaat gevonden"])},
  "results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaten gevonden"])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printen"])},
  "Print from the current page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printen vanaf de huidige pagina"])},
  "Amount of pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal pagina's"])},
  "Preparing print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printen voorbereiden"])},
  "Search in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken in"])},
  "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])},
  "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide"])},
  "Search for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar"])},
  "searchbar.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op"])},
  "Seperate words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Losse woorden"])},
  "Exact sentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exacte zin"])},
  "License key expired/not known": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licentie sleutel is afgelopen/niet bekent"])},
  "Server could not be reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server kan niet worden bereikt"])},
  "There was a problem establishing a connection to the server. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden tijdens het verbinden met de server. Probeer het opnieuw."])},
  "Could not get page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan pagina niet vinden"])},
  "Looks like the page you are trying to load doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die je probeert op te vragen bestaat niet"])},
  "No appearances found for this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen exemplaren gevonden voor deze publicatie"])},
  "There are no appearances linked to this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen exemplaren gelinkt aan deze publicatie"])},
  "Multiple appearances found for this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meerdere exemplaren gevonden voor deze publicatie"])},
  "There are multiple appearances found for this publication.<br>Please choose which appearance you would like to read:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn meerdere exemplaren gevonden voor deze publicatie.<br>Kies welk exemplaar je wilt lezen:"])},
  "Publication not be found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicatie niet gevonden"])},
  "Document not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document niet gevonden"])},
  "The requested publication could not be found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gevraagde publicatie kan niet worden gevonden"])},
  "Metadata not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata niet gevonden"])},
  "The metadata could not be found for this document. This data is needed to initialize the reader.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen metadata gevonden voor deze publicatie. Deze data is nodig om de reader te starten."])},
  "This document could not be opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit document kan niet worden geopend"])},
  "This document is already in use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit document is al in gebruik"])},
  "This document is already in use by a colleague. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit document is op dit moment al in gebruik door een collega. Probeer het later opnieuw."])},
  "This document is currently opened by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit document is op dit moment in gebruik door"])},
  "Please try again when one of the open documents is closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het later opnieuw wanneer er een document beschikbaar is"])},
  "Could not get the page for printing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kon de pagina niet ophalen voor het afdrukken"])},
  "Looks like the page you are trying to print doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het lijkt erop dat de pagina die u probeert af te drukken niet bestaat"])},
  "Authentication could not be refreshed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie kon niet worden vernieuwd"])},
  "The authentication with the server has expired and could not be refreshed. Please log in again to continue using the application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatie met de server is verlopen en kon niet worden vernieuwd. Log opnieuw in verder te gaan met de applicatie."])},
  "An error occurred in our server while searching in the catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden in onze server tijdens het zoeken in de catalogus"])},
  "Please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het later opnieuw"])},
  "Could not switch client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan omgeving niet wisselen"])},
  "Something went wrong while switching client, please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan bij het wisselen van omgeving, probeer het later opnieuw."])},
  "Something went wrong while sending the request, pleasy try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er is iets fout gegaan tijdens het versturen van de aanvraag, probeer het later opnieuw."])},
  "You will automatically be redirected for authentication.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina wordt automatisch doorverwezen voor authenticatie."])},
  "Click here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier"])},
  "to navigate to the authentication page if automatically redirecting is taking a long time.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["om handmatig te navigeren naar de authenticatiepagina als de automatische doorverwijzing te lang duurt."])},
  "A problem occurred while authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden tijdens het authenticeren"])},
  "Token missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missende sleutel"])},
  "Please try again later or contact an administrator if the problem keeps occurring.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het later opnieuw of neem contact op met een administrator wanneer het probleem zich blijft voordoen"])},
  "varReaderSearchResultsPages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er zijn ", _interpolate(_named("maxIndex")), " pagina's waar resultaten in gevonden zijn"])},
  "varReaderSearchResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pagina ", _interpolate(_named("currentIndex")), " van ", _interpolate(_named("maxIndex"))])},
  "Shopping basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelwagen"])},
  "There are no items in your shopping basket.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zit niks in je winkelwagen."])},
  "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
  "Back to shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de shop"])},
  "Continue shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder winkelen"])},
  "item.new.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze publicatie is minder dan ", _interpolate(_named("daysMarkedAsNew")), " dagen geleden toegevoegd."])},
  "shop": {
    "library": {
      "search": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op titel of ISBN"])}
      },
      "items": {
        "add-to-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan winkelmand"])},
        "already-in-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al in winkelmand"])},
        "already-in-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al in je collectie"])}
      }
    },
    "basket": {
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])}
    },
    "checkout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
      "complete-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling afronden"])},
      "order-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bestelling is mislukt. Probeer het a.u.b. op een later tijdstip opnieuw."])},
      "validation": {
        "accept-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer a.u.b. alle voorwaarden"])}
      },
      "privacy-agreements": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ik heb de ", _interpolate(_list(0)), " gelezen en ga hiermee akkoord."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy voorwaarden"])}
      },
      "general-agreements": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ik heb de ", _interpolate(_list(0)), " gelezen en ga hiermee akkoord."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algemene voorwaarden"])}
      }
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling succesvol verzonden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dank je wel voor je bestelling! Je ontvangt zo snel mogelijk een ontvangstbevestiging per E-mail, en wij nemen wederom contact met je op wanneer de bestelde boeken beschikbaar zijn in je XPOSI."])}
    }
  },
  "searchOption": {
    "searchMode": {
      "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpel"])},
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geavanceerd"])}
    },
    "searchContext": {
      "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metadata"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inhoud"])},
      "metadataAndContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metadata en inhoud"])}
    },
    "fieldRelation": {
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
      "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
    },
    "fieldMode": {
      "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat"])},
      "exact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exact"])},
      "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet"])}
    }
  },
  "sorting": {
    "order": {
      "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aflopend (Z-A)"])},
      "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oplopend (A-Z)"])}
    },
    "fields": {
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relevantie"])}
    }
  },
  "metadataField": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titel"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auteur"])},
    "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auteurs"])},
    "corporateauthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corporatieve auteur"])},
    "corporateauthors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corp. auteurs"])},
    "yearofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaar van uitgave"])},
    "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitgever"])},
    "documenttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentsoort"])},
    "seriestitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serie"])},
    "seriesnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serienummer"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referentie"])},
    "placeofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaats van uitgave"])},
    "edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editie"])},
    "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annotatie"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taal"])},
    "filenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dossiernummer"])},
    "filedatestart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startdatum"])},
    "filedateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einddatum"])},
    "filedatedestroyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vernietigingsdatum"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bron"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rubriek"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classificatie"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trefwoorden"])},
    "geographicalkeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geografische trefwoorden"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stamboeknummer"])},
    "collation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collatie"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bibliotheek"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vindplaats"])},
    "loanstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitleenstatus"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaar"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deel"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemplaar"])},
    "abstract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samenvatting"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signatuur"])},
    "bibiscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIBIScode"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document"])},
    "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collectie"])},
    "additionalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionele informatie"])},
    "parenttitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bovenliggende titels"])},
    "childtitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onderliggende titels"])},
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rubriek"])},
    "nuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuancering"])},
    "extranuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extra nuancering"])}
  },
  "facets": {
    "sorttype": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["gesorteerd op ", _interpolate(_named("currentType")), ", klik om te sorteren op ", _interpolate(_named("alternativeType"))])},
    "sortorder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["gesorteerd op ", _interpolate(_named("currentOrder")), " volgorde, klik om te sorteren op ", _interpolate(_named("alternativeOrder")), " volgorde"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waarde"])},
    "hits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aantal"])},
    "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oplopende"])},
    "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aflopende"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters toepassen"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters verwijderen"])},
    "cancelChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter wijzigingen annuleren"])},
    "freeFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vrije filters"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoeken"])},
  "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geavanceerd"])},
  "sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorteren op"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["binnen"])},
  "add filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter toevoegen"])},
  "remove filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter verwijderen"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaten"])},
  "all results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle resultaten"])},
  "show all {modulename} results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["alle ", _interpolate(_named("modulename")), " resultaten"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wissen"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sluiten"])},
  "load additional results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laad meer resultaten"])},
  "loading additional results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meer resultaten aan het laden"])},
  "all results have been loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle resultaten zijn geladen"])},
  "there are no results for this query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er zijn geen resultaten gevonden voor deze zoekvraag"])},
  "there has been an error while searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er is een probleem opgetreden tijdens het zoeken"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezig met zoeken"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meer"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minder"])},
  "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kenmerken"])},
  "abstract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samenvatting"])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multimedia"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitgave"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archief"])},
  "other characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overige kenmerken"])},
  "title relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titel relaties"])},
  "copies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemplaren"])},
  "moreCopyDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemplaardocument(en)"])},
  "loanable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitleenbaar"])},
  "not loanable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet uitleenbaar"])},
  "loaned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitgeleend"])},
  "new acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nieuwe aanwinsten"])},
  "there was a problem loading the acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de aanwinsten kunnen niet worden geladen"])},
  "there are no new acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er zijn geen nieuwe aanwinsten"])},
  "There are filled advanced fields. Click to open the advanced search panel.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn gevulde geavanceerde zoekvelden. Klik om het geavanceerd zoeken paneel te openen."])},
  "email_request": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naam"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailadres"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afdeling"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bericht"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verzenden naar bibliotheek"])},
    "is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is verplicht"])},
    "is_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is ongeldig"])}
  },
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omgeving"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taal"])},
  "languages": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
  },
  "placeholders": {
    "rich_text_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hier een text in..."])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opslaan"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleren"])},
  "select_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afbeelding kiezen"])},
  "error_image_wrong_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit bestandstype wordt niet ondersteund, probeer een van de volgende bestandstypen: ", _interpolate(_named("exampleFileTypes"))])},
  "error_image_no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bestand geselecteerd"])},
  "admin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beheer"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algemeen"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo"])},
    "changeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo wijzigen"])},
    "deleteLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huidige logo verwijderen"])},
    "informationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informatietekst"])},
    "showInformationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informatietekst tonen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoeken"])},
    "searchResultList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultatenlijst"])},
    "showCopyDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemplaardocumenten in resultatenlijst tonen"])},
    "defaultLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standaard taal"])},
    "searchDefaults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoekstandaarden"])},
    "searchContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["context"])},
    "resultSorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortering"])},
    "advancedSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geavanceerd zoeken"])},
    "showAdvancedSearchOnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geavanceerd zoeken openen op startpagina"])},
    "defaultAdvancedSearchFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standaard toegevoegde zoekvelden"])}
  },
  "valueNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deze waarde wordt niet (meer) ondersteund"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delen"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopiëren"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opties"])},
  "resultlistmode": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaatweergave"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lijst"])},
    "tiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tegels"])}
  },
  "actions": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toevoegen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijderen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wijzigen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleren"])},
    "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omhoog verplaatsen"])},
    "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omlaag verplaatsen"])}
  },
  "bookshelf": {
    "bookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boekenplank"])},
    "bookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boekenplanken"])},
    "myBookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mijn boekenplanken"])},
    "externalBookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externe boekenplanken"])},
    "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er zijn nog geen boekenplanken"])},
    "addBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boekenplank toevoegen"])},
    "editBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boekenplank wijzigen"])},
    "deleteBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boekenplank verwijderen"])},
    "bookshelfName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boekenplank naam"])},
    "is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is verplicht"])},
    "bookshelfLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kan de gevraagde boekenplank niet laden"])},
    "deleteBookshelfConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["weet je zeker dat je [ ", _interpolate(_named("bookshelfName")), " ] wil verwijderen?"])},
    "addToBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toevoegen aan boekenplank"])},
    "emptyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deze boekenplank is nog leeg"])},
    "addItemTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toevoegen aan"])},
    "newBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nieuwe boekenplank"])},
    "existingBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestaande boekenplank"])}
  },
  "selectedResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " geselecteerd resultaat"])},
  "selectedResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " geselecteerde resultaten"])},
  "moduleAction": {
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lezen"])},
    "inUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in gebruik"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanvragen"])},
    "addToBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan winkelmand"])},
    "alreadyInBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al in winkelmand"])},
    "openInBibis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open in BIBIS"])}
  }
}