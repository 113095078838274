import {
  defaultSearchContext,
  defaultSortField,
  defaultSortOrder
} from '@/constants/defaults'
import sortOptions from '@/store/config/sortOptions'
import searchOptions from '@/store/config/searchOptions'

const getDefaultSearchContext = (rootgetters) => {
  let searchContext = defaultSearchContext

  if (rootgetters) {
    const clientSettings = rootgetters['settings/getClientSettings']
    const { defaultSearchContext: settingsSearchContext } = clientSettings

    if (settingsSearchContext && searchOptions.searchContextOptions.some(option => option.value === settingsSearchContext)) {
      searchContext = settingsSearchContext
    }
  }

  return searchContext
}

const getDefaultSorting = (rootgetters) => {
  let sortField = defaultSortField
  let sortOrder = defaultSortOrder

  if (rootgetters) {
    const clientSettings = rootgetters['settings/getClientSettings']
    const { defaultSearchSortField: settingsSortField, defaultSearchSortOrder: settingsSortOrder } = clientSettings

    if (settingsSortField && sortOptions.sortFieldOptions.some(option => option.value === settingsSortField)) {
      sortField = settingsSortField
    }
    if (settingsSortOrder && sortOptions.sortOrderOptions.some(option => option.value === settingsSortOrder)) {
      sortOrder = settingsSortOrder
    }
  }

  return { sortField, sortOrder }
}

const getDefaultAdvancedFields = (rootgetters) => {
  const fields = []

  if (rootgetters) {
    const clientSettings = rootgetters['settings/getClientSettings']
    let { defaultAdvancedSearchFields } = clientSettings
    if (!defaultAdvancedSearchFields) defaultAdvancedSearchFields = []

    for (let i = 0; i < defaultAdvancedSearchFields.length; i++) {
      const fieldName = defaultAdvancedSearchFields[i]
      const field = createEmptyFieldObject(i)
      field.name = fieldName
      fields.push(field)
    }
  }

  return fields
}

const createEmptySearchState = (rootgetters) => {
  const defaultSearchContext = getDefaultSearchContext(rootgetters)
  const { sortField: defaultSortField, sortOrder: defaultSortOrder } = getDefaultSorting(rootgetters)
  const defaultAdvancedSearchFields = getDefaultAdvancedFields(rootgetters)

  return {
    query: '',
    searchContext: defaultSearchContext,
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
    fields: defaultAdvancedSearchFields,
    currentFieldIndex: defaultAdvancedSearchFields.length,
    altered: false
  }
}

const createEmptyFieldObject = (key) => ({
  key,
  relation: null,
  name: null,
  mode: null,
  query: ''
})

const getSearchRequestBody = (state) => {
  const query = state.query.trim() === '' ? '*' : state.query
  const advancedFields = prepareAdvancedFields(state.fields)

  return {
    query,
    queryType: state.searchContext,
    fields: advancedFields,
    sortField: state.sortField,
    sortOrder: state.sortOrder
  }
}

const prepareAdvancedFields = (fields) => {
  const filteredAdvancedFields = fields.filter(field => field.query.trim() !== '')
  return filteredAdvancedFields.map(mapFieldsForRequest)
}

const mapFieldsForRequest = (field) => {
  return {
    name: field.name,
    value: field.query,
    mode: field.mode,
    operator: field.relation
  }
}

const checkIfAdvancedStateIsAltered = (state, rootgetters) => {
  const defaultSearchContext = getDefaultSearchContext(rootgetters)
  const { sortField: defaultSortField, sortOrder: defaultSortOrder } = getDefaultSorting(rootgetters)

  const isSameSearchContext = state.searchContext === defaultSearchContext
  const isSameSortField = state.sortField === defaultSortField
  const isSameSortOrder = state.sortOrder === defaultSortOrder
  const hasFilledAdvancedFields = !!state.fields.filter(f => f.query !== '').length

  return !(isSameSearchContext && isSameSortField && isSameSortOrder && !hasFilledAdvancedFields)
}

export default {
  namespaced: true,
  state: createEmptySearchState(),
  getters: {
    getSearchRequestBody
  },
  actions: {
    newSearch ({ dispatch }) {
      dispatch('resetState')
    },
    resetState ({ state, rootGetters }) {
      Object.assign(state, createEmptySearchState(rootGetters))
    },
    addField ({ state, commit, dispatch }) {
      const newField = createEmptyFieldObject(state.currentFieldIndex)
      commit('addField', newField)
      state.currentFieldIndex++
      dispatch('onAlter')
    },
    removeField ({ commit, dispatch }, removedFieldKey) {
      commit('removeField', removedFieldKey)
      dispatch('onAlter')
    },
    createExactFieldSearch ({ commit, dispatch }, { value, field }) {
      dispatch('newSearch')
      dispatch('addField')
      commit('setField', {
        key: 0,
        mode: 0,
        name: field,
        query: value,
        relation: 0
      })
      dispatch('onAlter')
    },
    setQuery ({ commit }, query) {
      commit('setQuery', query)
    },
    setSearchContext ({ commit, dispatch }, context) {
      commit('setSearchContext', context)
      dispatch('onAlter')
    },
    setSortField ({ commit, dispatch }, sortField) {
      commit('setSortField', sortField)
      dispatch('onAlter')
    },
    setSortOrder ({ commit, dispatch }, sortOrder) {
      commit('setSortOrder', sortOrder)
      dispatch('onAlter')
    },
    addFilledField ({ state, commit, dispatch }, { mode, value, field, relation }) {
      dispatch('addField')

      const fieldKey = state.fields[state.fields.length - 1].key

      commit('setField', {
        key: fieldKey,
        mode: mode,
        name: field,
        query: value,
        relation: relation
      })
      dispatch('onAlter')
    },
    onAlter ({ state, commit, rootGetters }) {
      commit('setAltered', checkIfAdvancedStateIsAltered(state, rootGetters))
    }
  },
  mutations: {
    setQuery (state, query) {
      state.query = query
    },
    setSearchContext (state, context) {
      state.searchContext = context
    },
    setSortField (state, sortField) {
      state.sortField = sortField
    },
    setSortOrder (state, sortOrder) {
      state.sortOrder = sortOrder
    },
    addField (state, field) {
      state.fields.push(field)
    },
    removeField (state, removedFieldKey) {
      state.fields = state.fields.filter(field => field.key !== removedFieldKey)
    },
    setField (state, fieldValues) {
      const field = state.fields.find(i => i.key === fieldValues.key)
      if (!field) return

      for (const key of Object.keys(field)) {
        if (key === 'key') continue
        if (Object.hasOwn(fieldValues, key)) field[key] = fieldValues[key]
      }
    },
    setAltered (state, alteredState) {
      state.altered = alteredState
    }
  }
}
