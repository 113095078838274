const advancedSearchFieldRelationOptions = [
  { label: 'searchOption.fieldRelation.and', value: 0 },
  { label: 'searchOption.fieldRelation.or', value: 1 }
]

const advancedSearchFieldNameOptions = [
  { label: 'metadataField.title', value: 'title' },
  { label: 'metadataField.author', value: 'authors' },
  { label: 'metadataField.isbn', value: 'isbn' },
  { label: 'metadataField.publisher', value: 'publisher' },
  { label: 'metadataField.keywords', value: 'keywords' }
]

const advancedSearchFieldModeOptions = [
  { label: 'searchOption.fieldMode.contains', value: 1 },
  { label: 'searchOption.fieldMode.exact', value: 0 },
  { label: 'searchOption.fieldMode.not', value: 2 }
]

export default {
  advancedSearchFieldRelationOptions,
  advancedSearchFieldNameOptions,
  advancedSearchFieldModeOptions
}
