<template>
  <div class="reader-metadata-container">
    <div class="panel-content-section">
      <div class="reader-metadata-title-container">
        <div class="reader-metadata-title" v-html="parseDOM(metadata.title)"></div>
      </div>
      <div class="reader-metadata-cover-container">
        <xposi-lazyloadingimg v-if="(metadata.cover && metadata.cover != '')" class="reader-metadata-cover" :url="metadata.cover" ref="metadataCover">
          <template v-slot:status-failed>
            <img src="/img/cover_placeholder.png" />
          </template>
        </xposi-lazyloadingimg>
      </div>
      <div class="reader-metadata-metadata-container">
        <table style="border-collapse: collapse;">
            <tr v-if="metadata.authors && metadata.authors.length && metadata.authors[0] !== ''" class="reader-metadata-authors">
              <td>{{ metadata.authors.length > 1 ? $t('Authors') : $t('Author') }}</td>
              <td>
                <div v-for="(author, index) in metadata.authors" :key="author" class="reader-metadata-authors-author">{{ author }}{{ (index === metadata.authors.length-1) ? '' : ';' }}</div>
              </td>
            </tr>
            <tr v-if="metadata.isbn" class="reader-metadata-isbn">
              <td>{{ $t('ISBN') }}</td>
              <td>{{ metadata.isbn }}</td>
            </tr>
            <tr v-if="metadata.yearOfPublication" class="reader-metadata-year">
              <td>{{ $t('Year') }}</td>
              <td>{{ metadata.yearOfPublication }}</td>
            </tr>
            <tr v-if="metadata.publisher" class="reader-metadata-publisher">
              <td>{{ $t('Publisher') }}</td>
              <td>{{ metadata.publisher }}</td>
            </tr>
            <tr v-if="metadata.edition" class="reader-metadata-edition">
              <td>{{ $t('Edition') }}</td>
              <td>{{ metadata.edition }}</td>
            </tr>
            <tr v-if="metadata.jurisdiction" class="reader-metadata-jurisdiction">
              <td>{{ $t('Jurisdiction') }}</td>
              <td>
                <div v-for="(jurisdiction, index) in jurisdictionList" :key="jurisdiction" class="reader-metadata-jurisdiction-jurisdiction">{{ jurisdiction }}{{ (index === jurisdictionList.length - 1) ? '' : ',' }}</div>
              </td>
            </tr>
          </table>
      </div>
      <div class="clear"></div>
    </div>
    <div v-if="metadata.abstract" class="panel-content-section">
      <xposi-expendable-panel-section ref="reader-metadata-section-abstract" :defaultOpen="true">
        <template v-slot:title>
          {{ $t('Description') }}
        </template>
        <template v-slot:content>
          <div class="reader-metadata-abstract" v-html="parseDOM(metadata.abstract)"></div>
        </template>
      </xposi-expendable-panel-section>
    </div>
  </div>
</template>

<script>
import LazyLoadingImg from '@/components/LazyLoadingImg.vue'
import ExpandablePanelSection from '@/components/ExpandablePanelSection.vue'
import domparser from '@/mixins/domparser'

export default {
  name: 'reader-metadata',
  computed: {
    metadata () {
      return this.$store.getters['reader/getMetadata']
    },
    jurisdictionList () {
      let jurisdictionSplit = []

      if (this.metadata && this.metadata.jurisdiction) {
        jurisdictionSplit = this.metadata.jurisdiction.split('|')
      }

      return jurisdictionSplit
    }
  },
  methods: {
    activate () {
      if (this.$refs.metadataCover) {
        this.$refs.metadataCover.loadImage()
      }
    }
  },
  components: {
    'xposi-lazyloadingimg': LazyLoadingImg,
    'xposi-expendable-panel-section': ExpandablePanelSection
  },
  mixins: [domparser]
}
</script>

<style>

</style>
