<template>
  <div class="moduletab" :class="{ 'moduletab-active': isActive }" @click="$emit('select')">
    <span class="moduletab-modulename">
      {{ capitalize($t('all results')) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'module-tabbar-all-tab',
  props: {
    isActive: Boolean
  },
  emits: ['select']
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";
  @import "../../assets/css/components/moduleTab";
</style>
