import API from '@/axiosAPI'
import {
  defaultClientLanguage,
  defaultSearchContext,
  defaultSortField,
  defaultSortOrder
} from '@/constants/defaults'

const isSettingModified = (oldValue, newValue) => {
  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    return JSON.stringify(oldValue) !== JSON.stringify(newValue)
  }

  return oldValue !== newValue
}

export default () => ({
  namespaced: true,
  state: {
    clientDefaults: {
      defaultLanguage: defaultClientLanguage,
      showCopyDocuments: true,
      changeHomeLogo: false,
      defaultSearchContext: defaultSearchContext,
      defaultSearchSortField: defaultSortField,
      defaultSearchSortOrder: defaultSortOrder,
      showAdvancedSearchOnHome: false,
      defaultAdvancedSearchFields: []
    },
    client: {},
    clientLogo: null,
    modified: {
      client: {}
    }
  },
  getters: {
    getClientSettings (state) {
      return { ...state.clientDefaults, ...state.client }
    },
    getClientModifySetting: (state) => (settingKey) => {
      let settingValue = state.modified.client[settingKey]
      if (settingValue === undefined) settingValue = state.client[settingKey]
      if (settingValue === undefined) settingValue = state.clientDefaults[settingKey]
      return settingValue
    },
    isClientSettingsModified (state) {
      return !!Object.keys(state.modified.client).length
    },
    getClientLogo (state) {
      return state.clientLogo
    }
  },
  mutations: {
    setClientSettings (state, settings = {}) {
      state.client = settings
    },
    setClientModifySetting (state, { key, value }) {
      const keyInClientSettings = Object.hasOwn(state.client, key)
      const keyHasDefaultValue = Object.hasOwn(state.clientDefaults, key)

      const deleteFromModified = keyInClientSettings
        ? !isSettingModified(state.client[key], value)
        : keyHasDefaultValue
          ? !isSettingModified(state.clientDefaults[key], value)
          : false

      if (deleteFromModified) {
        const modifiedSettingsCopy = { ...state.modified.client }
        delete modifiedSettingsCopy[key]
        state.modified.client = modifiedSettingsCopy
        return
      }

      state.modified.client[key] = value
    },
    resetClientModifySettings (state) {
      state.modified.client = {}
    },
    setClientLogoBlobUrl (state, blobUrl = null) {
      state.clientLogo = blobUrl
    }
  },
  actions: {
    async fetchClientSettings ({ commit, dispatch }) {
      const { data } = await API.get('/client/settings')
      commit('setClientSettings', data)
      await dispatch('loadClientLogo')
    },
    async saveModifiedClientSettings ({ state, commit }) {
      try {
        const { data } = await API.post('/client/settings', state.modified.client)
        if (!data) return false
        commit('setClientSettings', data)
        commit('resetClientModifySettings')
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async loadClientLogo ({ state, commit }) {
      if (!state.client.logo) {
        commit('setClientLogoBlobUrl', null)
        return
      }

      try {
        const { data } = await API.get('/client/logo', { responseType: 'blob' })
        const blobUrl = URL.createObjectURL(data)
        commit('setClientLogoBlobUrl', blobUrl)
      } catch (e) {
        console.log(e)
        commit('setClientLogoBlobUrl', null)
      }
    },
    async saveClientLogo ({ dispatch }, file) {
      const formData = new FormData()
      formData.append('logo-file', file)

      try {
        await API.post('/client/logo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        await dispatch('fetchClientSettings')
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async deleteClientLogo ({ dispatch }) {
      try {
        await API.delete('/client/logo')
        await dispatch('fetchClientSettings')
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    }
  }
})
