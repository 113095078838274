import searchModuleStore from './searchModuleStore'
import API from '@/axiosAPI'
import ModuleActions from '@/constants/moduleActions'

export default {
  namespaced: true,
  state: {
    modules: []
  },
  getters: {
    getModule (state) {
      return (connectionId) => state.modules.find(module => module.connection === connectionId)
    },
    getModuleByModuleTypeId (state) {
      return (moduleId) => state.modules.filter(module => module.module === moduleId)
    },
    hasModuleWithShopAction (state) {
      const shopModules = state.modules.filter(m => m.actions.includes(ModuleActions.SHOP))
      return shopModules.length !== 0
    }
  },
  actions: {
    async initialise ({ commit }) {
      const { data } = await API.post('/search/moduleinfo')

      for (const module of data) {
        this.registerModule(['searchStore', 'searchModules', module.connection], searchModuleStore(module.connection, module.name))
      }

      commit('setModules', data)
    },
    search ({ state, dispatch }, isNewSearch) {
      for (const module of state.modules) {
        dispatch(`${module.connection}/search`, isNewSearch)
      }
    },
    searchWithOneModuleFaceted ({ state, dispatch }, { connectionId, facets }) {
      for (const module of state.modules) {
        if (module.connection === connectionId) {
          dispatch(`${module.connection}/searchAndFilter`, facets)
        } else {
          dispatch(`${module.connection}/search`, true)
        }
      }
    }
  },
  mutations: {
    setModules (state, modules) {
      state.modules = modules
    }
  }
}
