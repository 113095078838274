<template>
  <div class="facetgroup">
    <div class="facetgroup-header">
      <h3 class="facetgroup-name" @click="toggleCollapse">
        <span class="facetgroup-collapsetoggle">{{ collapsed ? '+' : '-'}}</span>
        {{ capitalize($t('facets.freeFilters')) }}
      </h3>
    </div>

    <div v-show="!collapsed" class="facetgroup-content">
      <div class="facetfilter-input-container">
        <input
          type="text"
          class="input-text input-text-compact facetfilter-input-input"
          v-model="newValue"
          @keypress.enter="submitFilter"
        />
        <button
          class="input-button input-button-compact facetfilter-input-submit"
          :disabled="!canSubmitNewValue"
          @click="submitFilter"
          :title="capitalize($t('actions.add'))"
        >
          <img class="input-button-image input-button-image-compact" src="/img/icons/add.png" />
        </button>
      </div>
      <div v-for="facet of appliedFacets" class="facetfilter-filter" :key="facet">
        <button
          class="input-button input-button-compact facetfilter-filter-remove"
          @click="deleteFilter(facet)"
          :title="capitalize($t('actions.delete'))"
        >
          <img class="input-button-image input-button-image-compact" src="/img/icons/close.png" />
        </button>
        <div class="facetfilter-filter-value">{{ facet }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'module-facet-filters',
  emits: ['toggle'],
  props: {
    appliedFacets: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    canSubmitNewValue () {
      const trimmed = this.newValue.trim()
      if (!trimmed) return false
      return !this.appliedFacets.includes(trimmed)
    }
  },
  data: () => ({
    collapsed: false,
    newValue: ''
  }),
  methods: {
    onFacetToggle (facet, active) {
      this.$emit('toggle', {
        group: this.fieldName,
        value: facet.key,
        active
      })
    },
    resetInput () {
      this.newValue = ''
    },
    toggleCollapse () {
      this.collapsed = !this.collapsed
    },
    submitFilter () {
      if (!this.canSubmitNewValue) return

      const filterValue = this.newValue.trim()

      if (filterValue) {
        this.$emit('toggle', {
          group: 'filters',
          value: filterValue,
          active: true
        })
      }

      this.resetInput()
    },
    deleteFilter (filterValue) {
      this.$emit('toggle', {
        group: 'filters',
        value: filterValue,
        active: false
      })
    },
    mounted () {
      this.resetInput()
    }
  }
}
</script>

<style lang="scss" scoped>
  .facetgroup {
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-name {
      display: block;
      width: 100%;
      cursor: pointer;

      &-collapsetoggle {
        display: inline-block;
        width: 10px;
      }
    }

    &-content {
      padding-left: 6px;
    }
  }

  .facetfilter {
    &-input {
      &-container {
        display: flex;
        flex-direction: row;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      &-input {
        flex-grow: 1;
        flex-shrink: 1;
      }

      &-submit {
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 4px;

        img {
          width: 16px;
          opacity: 0.6;
        }

        &:disabled img {
          opacity: 0.2;
          cursor: default;
        }

        &:not(:disabled):hover img {
          opacity: 1;
        }
      }
    }

    &-filter {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      &-value {
        flex-grow: 1;
        flex-shrink: 1;
      }

      &-remove {
        margin-left: -4px;

        img {
          width: 16px;
          opacity: 0.6;
        }

        &:hover img {
          opacity: 1;
        }
      }
    }
  }
</style>
