<template>
  <div class="shortlist">
    <div class="shortlist-header">
      <div class="shortlist-header-modulename">{{ moduleName }}</div>
      <button
        v-if="loadingState === LOADING_STATES.READY && shortlist.length"
        class="shortlist-header-resultamount"
        @click="$emit('navigateToTab')"
      >
        {{ totalAmount }} {{ $t('results') }}
      </button>
    </div>
    <div v-if="loadingState === LOADING_STATES.READY && shortlist.length" class="shortlist-list">
      <div class="shortlist-list-inner">
        <module-aggregate-publication
          v-for="publication of shortlist"
          :key="publication.id"
          :publication="publication"
          :connectionId="connectionId"
          @click="onSelectPublication(publication.id)"
          class="shortlist-publication"
        ></module-aggregate-publication>
      </div>
      <div class="shortlist-list-navigation">
        <button class="input-button" @click="$emit('navigateToTab')">{{ capitalize($t('show all {modulename} results', { modulename: moduleName })) }}</button>
      </div>
    </div>
    <div v-else-if="loadingState === LOADING_STATES.READY && !shortlist.length" class="shortlist-state shortlist-empty">
      {{ capitalize($t('there are no results for this query')) }}
    </div>
    <div v-else-if="loadingState === LOADING_STATES.LOADING" class="shortlist-state shortlist-loading">
      <spinner :size="20" :width="2"></spinner>
    </div>
    <div v-else-if="loadingState === LOADING_STATES.ERROR" class="shortlist-state shortlist-error">
      {{ capitalize($t('there has been an error while searching')) }}
    </div>
  </div>
</template>

<script>
import ModuleAggregatePublication from '@/components/module-aggregate/ModuleAggregatePublication'
import Spinner from '@/components/utilities/Spinner'
import { mapState } from 'vuex'
import STATES from '@/constants/states'

export default {
  name: 'module-aggregate-shortlist',
  props: ['connectionId'],
  emits: ['select'],
  computed: {
    ...mapState('searchStore/searchModules', {
      moduleName (state) { return state[this.connectionId].moduleName },
      loadingState (state) { return state[this.connectionId].loadingState },
      shortlist (state) { return state[this.connectionId].shortlist },
      totalAmount (state) { return state[this.connectionId].totalAmount }
    })
  },
  data: () => ({
    LOADING_STATES: STATES,
    hoverOverPublicationList: false
  }),
  methods: {
    onSelectPublication (publicationId) {
      this.$emit('select', {
        publication: publicationId,
        connection: this.connectionId
      })
    }
  },
  components: {
    'module-aggregate-publication': ModuleAggregatePublication,
    spinner: Spinner
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  $publication_padding: 10px;
  $publication_cover_width: 100px;
  $publication_metadata_width: 600px;

  @mixin publication_full {
    width: $publication_cover_width + $publication_metadata_width + (2 * $publication_padding);
    border-right: 1px solid $grey_middle;
    border-left: 1px solid $grey_middle;

    &:first-child {
      border-left: 0;
    }
  }

  .shortlist {
    padding: 20px;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      &-modulename {
        font-size: $font_size + 4;
        font-weight: bold;
      }

      &-resultamount {
        border: none;
        background: none;
        margin-left: 20px;
        text-decoration: underline;
        cursor: pointer;
        color: $grey_dark;

        &:hover {
          color: black;
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: row;
      width: 100%;

      &-inner {
        display: flex;
        flex-direction: row;

        &:not(:hover) .shortlist-publication:first-child {
          @include publication_full;
        }
      }

      &-navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &-state {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 50px;
    }

    &-error {
      color: $red;
    }
  }

  .shortlist-publication {
    width: $publication_cover_width + (2 * $publication_padding);
    overflow: hidden;
    border: 0 solid transparent;
    transition: width 0.1s ease, border 0.1s ease;

    &:hover {
      @include publication_full;
    }
  }
</style>
