<template>
  <div class="reader-searchresults-container">
    <div v-show="state === STATES.INITIAL" ref="containerInitial">
      <div class="reader-searchresults-contentcontainer">
        <p>{{ $t('Use the searchbar to start searching in this document') }}</p>
      </div>
    </div>

    <div v-show="state === STATES.LOADING" ref="containerLoading">
      <div class="reader-searchresults-contentcontainer">
        <img class="reader-searchresults-loadingimg" src="/img/anim_searching_white.gif" />
        <p class="text-italic">{{ $t('Searching') }}...</p>
      </div>
    </div>

    <div v-show="state === STATES.RESULTS" ref="containerResults">
        <div class="reader-searchresults-contentcontainer">
            <p>{{ $t('Current query') }}:</p>
            <p class="text-bold">{{ query }}</p>
            <hr />
            <div>
              <table class="reader-searchresults-navigation-container">
                <tr v-if="mimetype === 'pdf'">
                  <td colspan="3">
                    {{ $t('varReaderSearchResultsPages', { maxIndex: results.length }) }}
                  </td>
                </tr>
                <tr>
                  <td class="reader-searchresults-navigation-buttonholder">
                    <button ref="previousresult" class="input-button reader-searchresults-navigation-button" @click="previousResult">
                      <img src="/img/icons/prev_page.png" />
                    </button>
                  </td>
                  <td v-if="mimetype === 'pdf'">
                    <p>{{ $t('varReaderSearchResults', { currentIndex: index + 1, maxIndex: results.length }) }}</p>
                  </td>
                  <td v-else>
                    <p>{{ $t('Navigate') }}</p>
                  </td>
                  <td class="reader-searchresults-navigation-buttonholder">
                    <button ref="nextresult" class="input-button reader-searchresults-navigation-button" @click="nextResult">
                      <img src="/img/icons/next_page.png" />
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <button ref="stopsearch" class="input-button" @click="stopSearch">{{ $t('Stop search') }}</button>
            </div>
        </div>
    </div>

    <div v-show="state === STATES.NORESULTS" ref="containerNoResults">
      <div class="reader-searchresults-contentcontainer">
        <p>{{ $t('There are no results for this query') }}:</p>
        <p class="text-bold">{{ query }}</p>
      </div>
    </div>

    <div v-show="state === STATES.ERROR" ref="containerError">
        <div class="reader-searchresults-contentcontainer">
          <p>{{ error }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import { Events } from '@/events'
import axiosAPI from '@/axiosAPI'

export default {
  name: 'reader-searchresults',
  data () {
    return {
      STATES: {
        INITIAL: 0,
        LOADING: 1,
        RESULTS: 2,
        NORESULTS: 3,
        ERROR: 4
      },
      state: -1,
      query: '',
      results: [],
      error: null,
      index: 0,
      searchMode: 'words'
    }
  },
  computed: {
    docKey () {
      return this.$store.getters['reader/getDocKey']
    },
    license () {
      return this.$store.getters['reader/getLicense']
    },
    mimetype () {
      return this.$store.getters['reader/getMimetype']
    }
  },
  methods: {
    executeSearch (query) {
      this.stopSearch()
      this.state = this.STATES.LOADING
      this.query = query
      this.searchMode = this.$store.getters['reader/getSearchMode']

      axiosAPI.get(`/appearances/${this.docKey}/search`, {
        params: {
          query: this.translateQueryWithSearchMode(this.query, this.searchMode),
          licenseKey: this.license.licenseKey
        }
      })
        .then(({ data }) => {
          this.$store.commit('reader/storeResultFiles', data)
          if (!data.length) {
            this.results = []
            this.state = this.STATES.NORESULTS
          } else {
            this.results = data
            this.state = this.STATES.RESULTS
            this.startSearchHighlight()
            this.navigateToResult(0)
          }
        })
        .catch(error => {
          this.state = this.STATES.ERROR

          if (error.response) {
            const statuscode = error.response.status

            if (statuscode === 403) {
              Events.$emit('readerLicenseExpired')
              this.error = this.$t('License key expired/not known')
            }

            if (statuscode === 404) {
              this.error = this.$t('Document not found')
            }
          } else {
            this.error = this.$t('Server could not be reached')
          }
        })
    },
    translateQueryWithSearchMode (query, searchMode) {
      let translatedQuery = query

      if (searchMode === 'phrase') {
        translatedQuery = `"${query}"`
      } else {
        const seperatedQuery = query.split(' ')
        const seperatedQueryWithWildcards = seperatedQuery.map(part => `*${part}*`)
        translatedQuery = `(${seperatedQueryWithWildcards.join(' ')})`
      }
      return translatedQuery
    },
    stopSearch () {
      this.stopSearchHighlight()

      this.state = this.STATES.INITIAL
      this.query = ''
      this.index = 0
      this.error = null
      this.results = []
    },
    startSearchHighlight () {
      Events.$emit('readerSearchStart', {
        query: this.query,
        searchMode: this.searchMode
      })
    },
    stopSearchHighlight () {
      Events.$emit('readerSearchStop')
    },
    previousResult () {
      let newIndex = this.index - 1

      if (newIndex < 0) newIndex = this.results.length - 1

      this.navigateToResult(newIndex)

      Events.$emit('readerNavigateToPreviousResult')
    },
    nextResult () {
      let newIndex = this.index + 1

      if (newIndex > this.results.length - 1) newIndex = 0

      this.navigateToResult(newIndex)

      Events.$emit('readerNavigateToNextResult')
    },
    navigateToResult (index) {
      index = parseInt(index)
      if (isNaN(index) || index < 0 || index > this.results.length - 1) return

      this.index = index

      Events.$emit('readerNavigateToResult', index)
    }
  },
  created () {
    this.state = this.STATES.INITIAL
  },
  mounted () {
    Events.$on('readerSearchExecute', this.executeSearch)
  },
  beforeUnmount () {
    Events.$off('readerSearchExecute', this.executeSearch)
  }
}
</script>

<style>

</style>
