<template>
  <div class="copy">
    <div v-if="hasYearPartSubscription" class="row">
      <div class="row-label">{{ yearPartSubscriptionLabel }}</div>
      <div class="row-data">{{ yearPartSubscriptionData }}</div>
    </div>
    <div v-if="copy.registry" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.registry')) }}</div>
      <div class="row-data">{{ copy.registry }}</div>
    </div>
    <div v-if="copy.isbn" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.isbn')) }}</div>
      <div class="row-data">{{ copy.isbn }}</div>
    </div>
    <div v-if="copy.edition" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.edition')) }}</div>
      <div class="row-data">{{ copy.edition }}</div>
    </div>
    <div v-if="copy.collation" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.collation')) }}</div>
      <div class="row-data">{{ copy.collation }}</div>
    </div>
    <div v-if="copy.library" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.library')) }}</div>
      <div class="row-data">{{ copy.library }}</div>
    </div>
    <div v-if="copy.location" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.location')) }}</div>
      <div class="row-data">{{ copy.location }}</div>
    </div>
    <div v-if="copy.document" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.document')) }}</div>
      <div class="row-data">
        <publication-multimedia :mediatype="MultimediaTypes.Document" :url="copy.document" />
      </div>
    </div>
    <div v-if="isLoanable" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.loanstatus')) }}</div>
      <div v-if="isFreeToLoan" class="row-data">{{ capitalize($t('loanable')) }}</div>
      <div v-else class="row-data">
        <div style="color: red">{{ capitalize($t('loaned')) }}</div>
      </div>
    </div>
    <div v-else class="row">
      <div class="row-label">{{ capitalize($t('metadataField.loanstatus')) }}</div>
      <div style="color: red">{{ capitalize($t('not loanable')) }}</div>
    </div>
    <div v-if="isLoanInfo" class="row">
      <div class="row-label" style="color: red">{{ capitalize($t('loaned')) }}</div>
      <div class="loaninfo" v-html="copy.loanedInfo"></div>
    </div>
    <div v-if="copy.additionalinfo" class="row">
      <div class="row-label">{{ capitalize($t('metadataField.additionalinfo')) }}</div>
      <div class="row-data">{{ copy.additionalinfo }}</div>
    </div>
  </div>
</template>
<script>
import PublicationMultimedia, { MultimediaTypes } from '@/components/publication/PublicationMultimedia'
export default {
  name: 'publication-copy',
  props: ['copy'],
  computed: {
    hasYearPartSubscription () {
      return (
        this.copy.year ||
        this.copy.part ||
        this.copy.subscription
      )
    },
    yearPartSubscriptionLabel () {
      const parts = []
      if (this.copy.year) parts.push(this.$t('metadataField.year'))
      if (this.copy.part) parts.push(this.$t('metadataField.part'))
      if (this.copy.subscription) parts.push(this.$t('metadataField.subscription'))
      return this.capitalize(parts.join('/'))
    },
    yearPartSubscriptionData () {
      const parts = []
      if (this.copy.year) parts.push(this.copy.year)
      if (this.copy.part) parts.push(this.copy.part)
      if (this.copy.subscription) parts.push(this.copy.subscription)
      return parts.join('/')
    },
    isLoanable () {
      return this.copy.loanable === null || this.copy.loanable === '0'
    },
    isFreeToLoan () {
      return this.isLoanable
    },
    isLoanInfo () {
      return this.copy.loanedInfo !== null && this.copy.loanedInfo.trim() !== ''
    },
    print () {
      return this.copy.loanedinfo
    }
  },
  data: () => ({
    MultimediaTypes
  }),
  components: {
    'publication-multimedia': PublicationMultimedia
  }
}
</script>
<style lang="scss" scoped>
  .copy {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    .row {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      margin: 4px 0;
      .row-label {
        width: 140px;
        flex-shrink: 0;
        flex-grow: 0;
      }
      .row-data {
        width: 100%;
      }
    }
    .loaninfo {
      border: 1px solid #ccc;
      padding: 4px;
      border-radius: 4px;
    }
  }
</style>
