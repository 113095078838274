import axiosAPI from '@/axiosAPI'

const SET_BOOKS = 'SET_BOOKS'
const SET_ORDER = 'SET_ORDER'

export default () => ({
  namespaced: true,
  state: {
    books: getInitialBooksState(),
    order: getInitialOrderState()
  },
  mutations: {
    [SET_BOOKS] (state, books = []) {
      state.books = [
        ...books
      ]
      window.sessionStorage.setItem('shoppingBasketBooks', JSON.stringify(state.books))
    },
    [SET_ORDER] (state, order = null) {
      state.order = {
        ...(order || getInitialOrderState())
      }
    }
  },
  actions: {
    ADD_BOOK_TO_BASKET,
    REMOVE_BOOK_FROM_BASKET,
    CLEAR_BOOKS_IN_BASKET,
    ORDER_BOOKS_IN_BASKET
  }
})

function ADD_BOOK_TO_BASKET ({ commit, state }, book) {
  commit(SET_BOOKS, [
    ...state.books.filter(b => b.pubkey !== book.pubkey),
    book
  ])
}

function REMOVE_BOOK_FROM_BASKET ({ commit, state }, book) {
  commit(SET_BOOKS, [
    ...state.books.filter(b => b.pubkey !== book.pubkey)
  ])
}

function CLEAR_BOOKS_IN_BASKET ({ commit }) {
  commit(SET_BOOKS, [])
}

function ORDER_BOOKS_IN_BASKET ({ commit, dispatch, state, rootGetters }) {
  return new Promise((resolve, reject) => {
    commit(SET_ORDER, {
      ...state.order,
      isLoading: true
    })
    const requestBody = {
      isbns: state.books.map(b => b.isbn),
      language: rootGetters.getLanguage
    }
    axiosAPI.post('/shop/order', requestBody).then(() => {
      dispatch('CLEAR_BOOKS_IN_BASKET')
      commit(SET_ORDER, getInitialOrderState())
      resolve()
    }).catch((error) => {
      commit(SET_ORDER, {
        ...getInitialOrderState(),
        errorLoading: true
      })
      reject(error)
    })
  })
}

function getInitialBooksState () {
  return window.sessionStorage.getItem('shoppingBasketBooks')
    ? JSON.parse(window.sessionStorage.getItem('shoppingBasketBooks'))
    : []
}

function getInitialOrderState () {
  return {
    isLoading: false,
    errorLoading: false
  }
}
