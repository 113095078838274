<template>
  <div class="viewer-container viewer-container-pdf">
    <div class="viewer-holder">
      <div ref="pdfviewer_framecontainer" class="viewer-viewport-holder">
        <div v-if="pagenum > 1" @click="openPreviousPage" class="viewer-viewport-navigation viewer-viewport-navigation-left"></div>
        <div v-if="pagenum < appearance.numPages" @click="openNextPage" class="viewer-viewport-navigation viewer-viewport-navigation-right"></div>
        <iframe ref="pdfviewer_frame" id="pdfviewer" class="viewer-viewport" src="/lib/pdfjs/web/viewer.html"></iframe>
        <iframe ref="pdfviewer_printframe" id="pdfviewer-print" src="/lib/pdfjs/web/print.html"></iframe>
      </div>
      <div class="reader-bar reader-bar-bottom">
        <xposi-navigationbar></xposi-navigationbar>
      </div>
    </div>
  </div>
</template>

<script>
import axiosAPI from '@/axiosAPI'
import NavigationBar from '@/components/reader/NavigationBar.vue'
import { Events } from '@/events'
import { saveAs } from 'file-saver'

export default {
  name: 'pdf-viewer',
  data () {
    return {
      pagenum: 1,
      scale: 1.0,
      scaleUseDefault: true,
      scaleDefault: 1.0,
      pageWidth: 0,
      pageHeight: 0,
      resizeTimer: null,
      searchActive: false,
      searchQuery: null,
      searchMode: 'words',
      SCALE_INTERVAL: 0.1,
      SCALE_DEFAULTINTERVAL: 0.01,
      SCALE_MIN: 0.2,
      SCALE_MAX: 3.0
    }
  },
  computed: {
    toc () {
      return this.$store.getters['reader/getToc']
    },
    title () {
      return this.$store.getters['reader/getAppearanceTitle']
    },
    appearance () {
      return this.$store.getters['reader/getAppearance']
    },
    resultFiles () {
      return this.$store.getters['reader/getResultFiles']
    },
    currentTOCIndex () {
      return this.$store.getters['reader/getCurrentTOCIndex']
    }
  },
  methods: {
    keyNavigation (e) {
      switch (e.keyCode) {
        case 37: // left arrow
          this.openPreviousPage()
          break
        case 39: // right arrow
          this.openNextPage()
          break
      }
    },
    resizeFrame () {
      const frame = this.$refs.pdfviewer_frame
      const frameContainer = this.$refs.pdfviewer_framecontainer
      const pageEl = frame.contentWindow.document.body.querySelector('.canvasWrapper')
      const pageWidth = pageEl.style.width
      const pageHeight = pageEl.style.height

      frame.style.height = pageHeight
      frame.style.width = pageWidth
      frameContainer.style.width = pageWidth
    },
    openNextPage () {
      this.openPage(this.pagenum + 1)
        .then(() => {
          this.scrollPageTop()
        })
    },
    openPreviousPage () {
      this.openPage(this.pagenum - 1)
        .then(() => {
          this.scrollPageBottom()
        })
    },
    openPage (page = this.pagenum) {
      return new Promise((resolve, reject) => {
        const _this = this
        let filename = ''
        let pageNumber = null

        if (typeof page === 'number') {
          filename = page + '.pdf'
          pageNumber = page
        } else if (typeof page === 'string') {
          filename = page
          pageNumber = this.getPageNumFromFilename(page)
        } else {
          reject(new Error('No page number or filename given'))
        }

        const url = '/appearances/' + this.$store.getters['reader/getDocKey'] + '/' + filename

        axiosAPI.get(url, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf'
          },
          params: {
            licenseKey: this.$store.getters['reader/getLicense'].licenseKey
          }
        })
          .then(({ data }) => {
            const app = _this.getViewer()
            app.open(data)
              .then(() => {
                _this.pagenum = pageNumber
                _this.$store.commit('reader/storeCurrentPage', _this.pagenum)
                Events.$emit('readerNavigated')
                _this.setCurrentChapterIndex()
                setTimeout(() => {
                  resolve()
                }, 500)
              })
              .catch(error => {
                console.log(error)
                reject(new Error('An error occurred while opening the page'))
              })
          })
          .catch(error => {
            _this.pagenum = _this.$store.getters['reader/getCurrentPage']

            if (error.response) {
              const statuscode = error.response.status

              if (statuscode === 403) {
                Events.$emit('readerLicenseExpired')
              }

              if (statuscode === 404) {
                Events.$emit('modalOpen', {
                  type: 'error',
                  title: 'Could not get page',
                  body: 'Looks like the page you are trying to load doesn\'t exist',
                  actions: [{ label: 'Close', class: 'input-button-primary', action () { Events.$emit('modalClose') } }]
                })
              }
            }
            reject(new Error('An error occurred while fetching the page'))
          })
      })
    },
    getViewer () {
      const frame = this.$refs.pdfviewer_frame
      return frame.contentWindow.PDFViewerApplication
    },
    getTextLayer () {
      const contentWindow = this.$refs.pdfviewer_frame.contentWindow
      if (!contentWindow) return null
      return contentWindow.document.querySelector('.textLayer')
    },
    setCurrentChapterIndex () {
      let currentCheckingPageNum = -1
      let currentCheckingPageIndexes = []

      for (let i = 0; i < this.toc.flat.length; i++) {
        const nodePage = this.getPageNumFromFilename(this.toc.flat[i].link)

        if (this.pagenum < nodePage) break

        if (nodePage === currentCheckingPageNum) {
          currentCheckingPageIndexes.push(i)
        } else {
          currentCheckingPageIndexes = [i]
        }

        currentCheckingPageNum = nodePage
      }

      if (!currentCheckingPageIndexes.length) currentCheckingPageIndexes = [-1]

      this.$store.commit('reader/storeCurrentTOCIndex', currentCheckingPageIndexes)
    },
    getPageNumFromFilename (filename) {
      return parseInt(filename.replace('.pdf', ''))
    },
    zoomIn () {
      const rounding = parseInt(1 / this.SCALE_INTERVAL)
      const currentScale = Math.floor(this.scale * rounding) * this.SCALE_INTERVAL
      this.scale = Math.min(currentScale + this.SCALE_INTERVAL, this.SCALE_MAX)
      this.scaleUseDefault = false
      this.setZoom(this.scale)
      this.resizeFrame()
    },
    zoomOut () {
      const rounding = parseInt(1 / this.SCALE_INTERVAL)
      const currentScale = Math.ceil(this.scale * rounding) * this.SCALE_INTERVAL
      this.scale = Math.max(currentScale - this.SCALE_INTERVAL, this.SCALE_MIN)
      this.scaleUseDefault = false
      this.setZoom(this.scale)
      this.resizeFrame()
    },
    zoomDefault () {
      this.setZoomDefault()
      this.scale = this.scaleDefault
      this.scaleUseDefault = true
      this.setZoom(this.scale)
      this.resizeFrame()
    },
    setZoomDefault () {
      const container = this.$el
      const scaleMargin = parseInt(getComputedStyle(container.querySelector('.viewer-holder')).paddingTop, 10)
      const containerWidth = parseInt(container.offsetWidth, 10) - scaleMargin * 2
      const containerHeight = parseInt(container.offsetHeight, 10) - scaleMargin * 2
      const rounding = 1 / this.SCALE_DEFAULTINTERVAL

      let scale = this.scaleDefault

      if (containerWidth >= containerHeight) {
        scale = Math.floor((containerHeight / this.pageHeight) * rounding) / rounding
      } else {
        scale = Math.floor((containerWidth / this.pageWidth) * rounding) / rounding
      }

      scale = Math.min(Math.max(scale, this.SCALE_MIN), this.SCALE_MAX)

      this.scaleDefault = scale
    },
    setPageDimensions () {
      const frame = this.$refs.pdfviewer_frame
      const pageEl = frame.contentWindow.document.body.querySelector('.canvasWrapper')
      this.pageWidth = parseInt(pageEl.style.width, 10)
      this.pageHeight = parseInt(pageEl.style.height, 10)
    },
    setZoom (scale) {
      if (!scale) {
        if (this.scaleUseDefault) {
          scale = this.scaleDefault
        } else {
          scale = this.scale
        }
      }
      this.getViewer().pdfViewer._setScale(scale)
    },
    addReaderStyle () {
      const frameDocument = this.$refs.pdfviewer_frame.contentWindow.document
      const css = `
        .highlight { background-color: rgb(0, 80, 255) !important; } 
        .textLayer { opacity: 0.4; }
      `
      const styleEl = document.createElement('style')
      styleEl.type = 'text/css'
      styleEl.appendChild(document.createTextNode(css))
      frameDocument.head.appendChild(styleEl)
    },
    scrollToFirstHighlight () {
      const extraMargin = 20
      const frameDocument = this.$refs.pdfviewer_frame.contentWindow.document
      const firstHighlight = frameDocument.querySelector('.highlight')
      if (!firstHighlight) return

      const frameTop = this.$refs.pdfviewer_frame.offsetTop
      const highlightTop = firstHighlight.getBoundingClientRect().top
      const scrollContainer = document.querySelector('.viewer-container')

      scrollContainer.scrollTop = frameTop + highlightTop - extraMargin
    },
    print (printAmount) {
      const _this = this
      const url = '/appearances/' + this.$store.getters['reader/getDocKey'] + '/print'

      Events.$emit('modalOpen', {
        type: 'info',
        icon: 'server.png',
        title: _this.$t('Print'),
        body: `
          <div style="padding: 20px; text-align: center;">
            <img src="/img/anim_loading.gif" />
            <div>
              ${_this.$t('Preparing print') + '...'}
            </div>
          </div>
        `,
        requireAction: true
      })

      axiosAPI.get(url, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf'
        },
        params: {
          from: this.$store.getters['reader/getCurrentPage'],
          amount: printAmount,
          licenseKey: this.$store.getters['reader/getLicense'].licenseKey
        }
      }).then(({ data }) => {
        this.presentDownload(data)
      }).catch(error => {
        Events.$emit('modalClose')
        const response = error.response
        if (response) {
          const statuscode = response.status

          if (statuscode === 403) {
            Events.$emit('readerLicenseExpired')
          }

          if (statuscode === 404) {
            Events.$emit('modalOpen', {
              type: 'error',
              title: _this.$t('Could not get the page for printing'),
              body: _this.$t('Looks like the page you are trying to print doesn\'t exist'),
              actions: [{
                label: _this.$t('Close'),
                class: 'input-button-primary',
                action () { Events.$emit('modalClose') }
              }]
            })
          }
        } else {
          console.log(error)
        }
      })
    },
    scrollPageTop () {
      const container = this.$el
      container.scrollTop = 0
    },
    scrollPageBottom () {
      const container = this.$el
      container.scrollTop = container.scrollHeight
    },
    printingReady () {
      Events.$emit('modalClose')
    },
    initiatePage () {
      this.setZoom(1)
      this.setPageDimensions()
      this.setZoomDefault()
      this.setZoom()
      this.resizeFrame()
      setTimeout(() => {
        this.resizeFrame()
      }, 200)
    },
    navigateToFileEventHandler (filename) {
      this.openPage(filename)
        .then(() => {
          this.scrollPageTop()
        })
    },
    navigateToPageEventHandler (page) {
      this.openPage(page)
        .then(() => {
          this.scrollPageTop()
        })
    },
    navigateToNextChapterEventHandler () {
      let currentTopLevelNodeIndex = -1

      if (this.currentTOCIndex > -1) {
        currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].parents.top
        if (!currentTopLevelNodeIndex) currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].index.top
      }

      if (currentTopLevelNodeIndex < this.toc.top.length - 1) {
        this.openPage(this.getPageNumFromFilename(this.toc.top[currentTopLevelNodeIndex + 1].link))
          .then(() => {
            this.scrollPageTop()
          })
      }
    },
    navigateToPreviousChapterEventHandler () {
      if (this.currentTOCIndex === -1) return

      let currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].parents.top
      if (!currentTopLevelNodeIndex) currentTopLevelNodeIndex = this.toc.flat[this.currentTOCIndex].index.top

      if (currentTopLevelNodeIndex > 0) {
        this.openPage(this.getPageNumFromFilename(this.toc.top[currentTopLevelNodeIndex - 1].link))
          .then(() => {
            this.scrollPageTop()
          })
      }
    },
    searchStartEventHandler (searchOptions) {
      this.searchActive = true
      this.searchQuery = searchOptions.query
      this.searchMode = searchOptions.searchMode
      this.highlightPattern(this.searchQuery, this.searchMode).then(() => {
        this.scrollToFirstHighlight()
      })
    },
    searchStopEventHandler () {
      this.searchActive = false
      this.searchQuery = null
      this.removeHighlights()
    },
    resizeEventHandler (e) {
      if (this.scaleUseDefault) {
        this.zoomDefault()
      } else {
        this.setZoom()
        this.resizeFrame()
      }
    },
    keypressEventHandler (e) {
      this.keyNavigation(e)
    },
    textlayerRenderedEventHandler () {
      if (this.searchActive) {
        this.highlightPattern(this.searchQuery, this.searchMode).then(() => {
          this.scrollToFirstHighlight()
        })
      }
    },
    printEventHandler (amount) {
      this.print(amount)
    },
    presentDownload (data) {
      const dataBlob = new Blob([data], { type: 'application/pdf' })
      saveAs(dataBlob, `${this.title.replace(/ |\//g, '_')}.pdf`)
      Events.$emit('modalClose')
    },
    navigateToResultEventHandler (index) {
      this.openPage(this.resultFiles[index].path)
    },
    initReader () {
      const _this = this

      this.$refs.pdfviewer_frame.onload = function () {
        _this.openPage(1)
          .then(() => {
            _this.scrollPageTop()

            setTimeout(() => {
              document.getElementById('pdfviewer').style.visibility = 'visible'
            }, 200)
          })

        if (!_this.$refs.pdfviewer_frame.contentWindow) return
        _this.addReaderStyle()

        const frameDocument = _this.$refs.pdfviewer_frame.contentWindow.document
        frameDocument.addEventListener('textlayerrendered', _this.textlayerRenderedEventHandler)
      }

      this.$refs.pdfviewer_printframe.onload = function () {
        const printFrameDocument = _this.$refs.pdfviewer_printframe.contentWindow.document
        printFrameDocument.addEventListener('pagesloaded', _this.presentDownload)
      }
    },
    highlightPattern (query, searchMode) {
      return new Promise((resolve, reject) => {
        const PDFApp = this.getViewer()
        PDFApp.eventBus.dispatch('find', {
          query: query,
          highlightAll: true,
          phraseSearch: (searchMode === 'phrase'),
          caseSensitive: false
        })

        resolve()
      })
    },
    removeHighlights () {
      const PDFApp = this.getViewer()
      PDFApp.eventBus.dispatch('find', { query: '' })
    }
  },
  mounted () {
    const _this = this
    document.addEventListener('keypress', _this.keypressEventHandler)

    Events.$on('readerNavigateToFile', _this.navigateToFileEventHandler)
    Events.$on('readerNavigateToPage', _this.navigateToPageEventHandler)
    Events.$on('readerNavigationPreviousPage', _this.openPreviousPage)
    Events.$on('readerNavigationNextPage', _this.openNextPage)
    Events.$on('readerNavigateToNextChapter', _this.navigateToNextChapterEventHandler)
    Events.$on('readerNavigateToPreviousChapter', _this.navigateToPreviousChapterEventHandler)
    Events.$on('readerZoomIn', _this.zoomIn)
    Events.$on('readerZoomOut', _this.zoomOut)
    Events.$on('readerZoomDefault', _this.zoomDefault)
    Events.$on('readerPrint', _this.printEventHandler)
    Events.$on('readerSearchStart', _this.searchStartEventHandler)
    Events.$on('readerSearchStop', _this.searchStopEventHandler)
    Events.$on('readerNavigateToResult', _this.navigateToResultEventHandler)

    this.initReader()

    window.addEventListener('resize', _this.resizeEventHandler)
    window.addEventListener('viewerRenderReady', _this.initiatePage)
    window.addEventListener('viewerPrintingReady', _this.printingReady)
  },
  beforeUnmount () {
    const _this = this
    document.removeEventListener('keypress', _this.keypressEventHandler)

    Events.$off('readerNavigateToFile', _this.navigateToFileEventHandler)
    Events.$off('readerNavigateToPage', _this.navigateToPageEventHandler)
    Events.$off('readerNavigationPreviousPage', _this.openPreviousPage)
    Events.$off('readerNavigationNextPage', _this.openNextPage)
    Events.$off('readerNavigateToNextChapter', _this.navigateToNextChapterEventHandler)
    Events.$off('readerNavigateToPreviousChapter', _this.navigateToPreviousChapterEventHandler)
    Events.$off('readerZoomIn', _this.zoomIn)
    Events.$off('readerZoomOut', _this.zoomOut)
    Events.$off('readerZoomDefault', _this.zoomDefault)
    Events.$off('readerPrint', _this.printEventHandler)
    Events.$off('readerSearchStart', _this.searchStartEventHandler)
    Events.$off('readerSearchStop', _this.searchStopEventHandler)
    Events.$off('readerNavigateToResult', _this.navigateToResultEventHandler)

    window.removeEventListener('resize', _this.resizeEventHandler)
    window.removeEventListener('viewerPrintingReady', _this.printingReady)
    window.removeEventListener('viewerRenderReady', _this.initiatePage)
  },
  components: {
    'xposi-navigationbar': NavigationBar
  }
}
</script>

<style>

</style>
